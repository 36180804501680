import {
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
  HostListener,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/services/data.service';
import { Observable, Subscription, take, firstValueFrom } from 'rxjs';
import { Game } from 'src/app/interfaces/interfaces';
import { CDNDataService } from 'src/app/services/cdn.service';

export const CORRECT_FILL_COLOR = '#5CE50D';
export const INCORRECT_FILL_COLOR = '#E52A0D';

@Component({
  selector: 'app-svg-map',
  templateUrl: './svg-map.component.html',
  styleUrls: ['./svg-map.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SvgMapComponent implements OnInit, AfterViewChecked {
  @Input() public name?: string;
  @Input() public page?: string;
  @Input() public countryCode?: string;
  @Input() public admin: boolean = false;

  @ViewChild('map', { read: ElementRef, static: false }) map!: ElementRef;

  public svgIcon: any;
  loadedClickEvents: boolean = false;
  svgTarget!: any;
  initialSetup: boolean = false;

  stateSub!: Subscription;
  clientSub!: Subscription;

  state$: Observable<Game>;

  circleRadius: string = '20';

  constructor(
    private httpClient: HttpClient,
    private renderer: Renderer2,
    private data: DataService,
    private cdnDataService: CDNDataService
  ) {
    this.state$ = this.data.getState();
  }

  ngOnInit(): void {
    if (!this.countryCode) {
      this.svgIcon = '';
      return;
    }
    this.clientSub = this.httpClient
      .get(
        this.cdnDataService.getCountryShapeSvgUrl(
          this.countryCode.toLowerCase()
        ),
        {
          responseType: 'text',
        }
      )
      .pipe(take(1))
      .subscribe((value) => {
        this.svgIcon = value;
        this.bindEvents();
      });

    this.adjustCircleRadius();
    this.checkUIUpdates();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.adjustCircleRadius();
  }

  adjustCircleRadius(): void {
    const screenWidth = window.innerWidth;
    this.circleRadius = screenWidth > 500 ? '20' : '25';
  }

  ngAfterViewChecked() {
    if (!this.map?.nativeElement?.children[0]?.children) {
      return;
    }
    // Give circles initial classes and styles
    if (!this.initialSetup) {
      this.state$.pipe(take(1)).subscribe((val) => {
        this.initializeCircles(val);
      });
      this.initialSetup = true;
    }
  }

  checkUIUpdates() {
    this.stateSub = this.state$.subscribe((val) => {
      if (this.map?.nativeElement?.children[0]?.children) {
        this.updateCircles(val);
      }
    });
  }

  initializeCircles(val: Game) {
    const svgContainer = this.map.nativeElement.querySelector('svg');
    if (!svgContainer) return;

    const elements = svgContainer.querySelectorAll(':not(circle)');
    const circles = svgContainer.querySelectorAll('circle');

    // Dark mode handling
    if (document.documentElement.classList.contains('dark')) {
      elements.forEach((element: any) => {
        if (element.nodeName !== 'circle' && !element.id.includes('label')) {
          const fillColor = element.getAttribute('fill');
          const strokeColor = element.getAttribute('stroke');

          if (fillColor === 'black' || fillColor === '#000000') {
            this.renderer.setAttribute(element, 'fill', '#FFFFFF');
          } else if (fillColor?.startsWith('#')) {
            const invertedFill = this.invertColor(fillColor);
            this.renderer.setAttribute(element, 'fill', invertedFill);
          }

          if (strokeColor === 'black' || strokeColor === '#000000') {
            this.renderer.setAttribute(element, 'stroke', '#FFFFFF');
          } else if (strokeColor?.startsWith('#')) {
            const invertedStroke = this.invertColor(strokeColor);
            this.renderer.setAttribute(element, 'stroke', invertedStroke);
          }
        }
      });
    }

    // Set IDs, radius, and ellipse class for circles
    let incorrectCount = 0;
    let correctCount = 0;

    circles.forEach((circle: any) => {
      const fillColor = circle.getAttribute('fill');

      if (fillColor === INCORRECT_FILL_COLOR) {
        circle.setAttribute('id', `wrong-${incorrectCount}`);
        incorrectCount++;
      } else {
        const id = circle.id;
        if (!id.includes('right')) {
          circle.setAttribute(
            'id',
            `right-${id.length > 0 ? id : correctCount}`
          );
        }
        correctCount++;
      }

      // Set radius and class
      this.renderer.setAttribute(circle, 'r', this.circleRadius);
      this.renderer.addClass(circle, 'ellipse');
    });

    this.updateCircles(val);
  }

  updateCircles(val: Game) {
    const svgContainer = this.map.nativeElement.querySelector('svg');
    if (!svgContainer) return;

    const circles = svgContainer.querySelectorAll('circle');

    circles.forEach((circle: any) => {
      const id = circle.id;

      // Check for incorrect guesses
      const isCorrect = val.map.correctGuesses.includes(id);
      const isIncorrect = val.map.incorrectGuesses.includes(id);
      const endOfRound = val.map.roundOver || val.map.guessed;

      // Update classes based on game state
      if (isIncorrect) {
        this.renderer.addClass(circle, 'incorrect');
      } else if (isCorrect || (endOfRound && id.includes('right'))) {
        this.renderer.addClass(circle, 'correct');
      }

      // Add text labels for multiple capitals
      if (
        val.map.roundOver &&
        val.capitalCity.answers.length > 1 &&
        id.includes('right-')
      ) {
        const cityIndex = id.replace('right-', '');
        const svgDoc = this.map.nativeElement.ownerDocument;

        if (!svgDoc.getElementById(`label-${cityIndex}`)) {
          const textLabel = this.createTextLabel(svgDoc, circle, cityIndex);
          textLabel.setAttribute('id', `label-${cityIndex}`);
          svgContainer.appendChild(textLabel);
        }
      }
    });

    // Create rectangles around text elements
    requestAnimationFrame(() => {
      this.createRectanglesAroundTextElements(svgContainer);
    });
  }

  bindEvents() {
    this.svgTarget = document.getElementById('map-select-image');
    this.svgTarget.addEventListener('click', (e: any) => {
      if (e.target.tagName === 'circle') {
        this.data.mapGuess(e.target.id);
      }
    });
  }

  ngOnDestroy() {
    this.stateSub && this.stateSub.unsubscribe();
    this.clientSub && this.clientSub.unsubscribe();
    this.svgTarget && this.svgTarget.removeEventListener('click', () => {});
  }

  createTextLabel(
    svgDoc: Document,
    circle: SVGCircleElement,
    id: string
  ): SVGGElement {
    const groupElement = svgDoc.createElementNS(
      'http://www.w3.org/2000/svg',
      'g'
    );
    const textElement = svgDoc.createElementNS(
      'http://www.w3.org/2000/svg',
      'text'
    );

    const cx = parseFloat(circle.getAttribute('cx') || '0');
    const cy = parseFloat(circle.getAttribute('cy') || '0');

    const adjustedX = cx + Number(this.circleRadius) + 15;
    const adjustedY = cy + Number(this.circleRadius) / 2;

    textElement.setAttribute('x', adjustedX.toString());
    textElement.setAttribute('y', adjustedY.toString());
    textElement.setAttribute('text-anchor', 'start');
    textElement.setAttribute('font-size', '35');
    textElement.setAttribute('font-weight', '500');
    textElement.setAttribute('fill', '#0A4811');
    firstValueFrom(this.data.getCities()).then((cities) => {
      textElement.textContent =
        cities.find((c) => c.index === Number(id))?.name ?? '';
    });

    groupElement.appendChild(textElement);

    return groupElement;
  }

  createRectanglesAroundTextElements(svgContainer: HTMLElement | null): void {
    const textElements = svgContainer?.querySelectorAll('text');
    textElements?.forEach((textElement: SVGTextElement) => {
      const bbox = textElement.getBBox();

      const rectElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'rect'
      );
      rectElement.setAttribute('x', (bbox.x - 10).toString());
      rectElement.setAttribute('y', (bbox.y - 5).toString());
      rectElement.setAttribute('width', (bbox.width + 20).toString());
      rectElement.setAttribute('height', (bbox.height + 10).toString());
      rectElement.setAttribute('fill', '#9EEAA7');
      rectElement.setAttribute('rx', '4');

      textElement.parentNode?.insertBefore(rectElement, textElement);
    });
  }

  invertColor(hexColor: string) {
    hexColor = hexColor.replace('#', '');

    const r = parseInt(hexColor.slice(0, 2), 16);
    const g = parseInt(hexColor.slice(2, 4), 16);
    const b = parseInt(hexColor.slice(4, 6), 16);

    const invertedR = (255 - r).toString(16).padStart(2, '0');
    const invertedG = (255 - g).toString(16).padStart(2, '0');
    const invertedB = (255 - b).toString(16).padStart(2, '0');

    return `#${invertedR}${invertedG}${invertedB}`;
  }
}
