import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  isLoading = new BehaviorSubject<boolean>(true);

  constructor(private platform: PlatformService) { }

  supportsWebP() {
    if (!this.platform.isInBrowser()) return false;
    let elem = document.createElement('canvas');
    if (!!(elem.getContext && elem.getContext('2d'))) {
      // was able or not to get WebP representation
      return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
    }
    else {
      // very old browser like IE 8, canvas not supported
      return false;
    }
  }

  getState(): Observable<boolean> {
    return this.isLoading;
  }
  setLoading() {
    this.isLoading.next(true);
  }
  setLoaded() {
    this.isLoading.next(false);
  }
}
