import { Country } from '../interfaces/interfaces';

export const countries: Country[] = [
  {
    name: 'Afghanistan',
    code: 'AF',
    lat: 33,
    long: 65,
    game: [
      {
        number: 1,
        mainImage: {
          name: 'Buddhas of Bamiyan',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/TLKCx6mVIHo',
          wikiLink: 'https://en.wikipedia.org/wiki/Buddhas_of_Bamiyan',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/afghanistan-houses-homes-buildings-79493/',
          wikiLink: 'https://en.wikipedia.org/wiki/Afghanistan',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Kabul',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/72ccNLMJ-sU',
          wikiLink: 'https://en.wikipedia.org/wiki/Afghanistan',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Afghanistan',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/8A7M2u4G4d0',
          wikiLink: 'https://en.wikipedia.org/wiki/Afghanistan',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Qargha',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-body-of-water-surrounded-by-mountains-lSalVcf9-IU',
          wikiLink: 'https://en.wikipedia.org/wiki/Qargha',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Bamyan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/the-mountains-are-covered-with-snow-in-the-desert-TLKCx6mVIHo',
          wikiLink: 'https://en.wikipedia.org/wiki/Bamyan',
        },
      },
    ],
  },
  {
    name: 'Aland Islands',
    code: 'AX',
    lat: 60.116667,
    long: 19.9,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Kumlinge,_%C3%85land_Islands_-_panoramio_%289%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kumlinge',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Sea_landscape,_%C3%85land.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/%C3%85land',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Aland Islands',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/FabtG4enGZQ',
          wikiLink: 'https://en.wikipedia.org/wiki/%C3%85land',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Aland Islands',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/UVboVXvk3Yc',
          wikiLink: 'https://en.wikipedia.org/wiki/%C3%85land',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Aland Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-body-of-water-surrounded-by-trees-M-mupIfaVbU',
          wikiLink: 'https://en.wikipedia.org/wiki/%C3%85land',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Aland Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-red-building-with-a-flag-on-top-of-it-faVHb5ezd8g',
          wikiLink: 'https://en.wikipedia.org/wiki/%C3%85land',
        },
      },
    ],
  },
  {
    name: 'Albania',
    code: 'AL',
    lat: 41,
    long: 20,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/mUATQHolSMg',
          wikiLink: 'https://en.wikipedia.org/wiki/Rozafa_Castle',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cruise-cruise-ship-sea-water-3703999/',
          wikiLink: 'https://en.wikipedia.org/wiki/Albania',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Tirana',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/-TUYQ8vF0Ks',
          wikiLink: 'https://en.wikipedia.org/wiki/Tirana',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Albania',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/2lR4FexYPvA',
          wikiLink: 'https://en.wikipedia.org/wiki/Albania',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Shkodër',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/river-between-green-trees-and-mountains-under-blue-sky-and-white-clouds-during-daytime-65vi3vAzAAY',
          wikiLink: 'https://en.wikipedia.org/wiki/Shkod%C3%ABr',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Sarandë',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-next-to-a-body-of-water-JrnMqKyyoHw',
          wikiLink: 'https://en.wikipedia.org/wiki/Sarand%C3%AB',
        },
      },
    ],
  },
  {
    name: 'Algeria',
    code: 'DZ',
    lat: 28,
    long: 3,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/-sOS_rLimpQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Constantine,_Algeria',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/algeria-hoggar-assekrem-volcano-673777/',
          wikiLink: 'https://en.wikipedia.org/wiki/Algeria',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Algeria',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/dyuN7GgEyB8',
          wikiLink: 'https://simple.wikipedia.org/wiki/Algeria',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Constantine',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/sZcGzjl9moU',
          wikiLink: 'https://simple.wikipedia.org/wiki/Constantine,_Algeria',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Bordj Bou Arreridj',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-concrete-building-near-green-trees-during-daytime-QEupqcxTmY0',
          wikiLink: 'https://en.wikipedia.org/wiki/Bordj_Bou_Arr%C3%A9ridj',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Seraïdi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-couple-of-white-buildings-with-blue-windows-neecKxRZLs8',
          wikiLink: 'https://en.wikipedia.org/wiki/Sera%C3%AFdi',
        },
      },
    ],
  },
  {
    name: 'American Samoa',
    code: 'AS',
    lat: -14.33333333,
    long: -170,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:American_Samoa_tramway_remains.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/American_Samoa',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Vatia_from_the_National_Park_of_American_Samoa.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/National_Park_of_American_Samoa',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'American Samoa',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:American_Samoa#/media/File:American_Samoa_tramway_remains.jpg',
          wikiLink: 'https://simple.wikipedia.org/wiki/American_Samoa',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'American Samoa',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/up70mm/5214165316',
          wikiLink: 'https://simple.wikipedia.org/wiki/American_Samoa',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'American Samoa',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/roger_pringle/6131448556/',
          wikiLink: 'https://simple.wikipedia.org/wiki/American_Samoa',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'American Samoa',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/benmiller23/2296800057/',
          wikiLink: 'https://simple.wikipedia.org/wiki/American_Samoa',
        },
      },
    ],
  },
  {
    name: 'Andorra',
    code: 'AD',
    lat: 42.5,
    long: 1.5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/3gYKw3uAOwU',
          wikiLink: 'https://en.wikipedia.org/wiki/Andorra_la_Vella',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/andorra-pyrenees-mountains-3501173/',
          wikiLink: 'https://en.wikipedia.org/wiki/Pyrenees',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Andorra',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/P2LtiQlU2Vg',
          wikiLink: 'https://en.wikipedia.org/wiki/Andorra',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Andorra',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Xrcx2VINjP8',
          wikiLink: 'https://en.wikipedia.org/wiki/Andorra',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Andorra',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-mountains-covered-in-snow-under-a-cloudy-sky-TrJthE2ExsU',
          wikiLink: 'https://en.wikipedia.org/wiki/Andorra',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Andorra',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-winding-road-in-the-mountains-eFWINfwiZes',
          wikiLink: 'https://en.wikipedia.org/wiki/Andorra',
        },
      },
    ],
  },
  {
    name: 'Angola',
    code: 'AO',
    lat: -12.5,
    long: 18.5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Tri_u7qeqGw',
          wikiLink: 'https://en.wikipedia.org/wiki/Muxima',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/angola-landscape-sierra-da-leba-509598/',
          wikiLink: 'https://en.wikipedia.org/wiki/Angola',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Angola',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/TKsDclZ3i4o',
          wikiLink: 'https://en.wikipedia.org/wiki/Angola',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Huíla Province',
          photographer: '',
          imageLink:
            'https://www.pexels.com/photo/aerial-view-of-rocky-mountain-5745888/',
          wikiLink: 'https://en.wikipedia.org/wiki/Hu%C3%ADla_Province',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Luanda',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-high-rise-building-glhVDncQmhE',
          wikiLink: 'https://en.wikipedia.org/wiki/Luanda',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Benfica',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/group-of-people-on-shore-EkI95WwwMjo',
          wikiLink: 'https://en.wikipedia.org/wiki/Benfica,_Luanda',
        },
      },
    ],
  },
  {
    name: 'Anguilla',
    code: 'AI',
    lat: 18.25,
    long: -63.16666666,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/mmGdZmFvnrE',
          wikiLink: 'https://en.wikipedia.org/wiki/Anguilla',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bay-beach-jetty-boat-anguilla-7233153/',
          wikiLink: 'https://en.wikipedia.org/wiki/Anguilla',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Anguilla',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/qVlMhkyp9l8',
          wikiLink: 'https://en.wikipedia.org/wiki/Anguilla',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Anguilla',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/DoSGBUN4A9k',
          wikiLink: 'https://en.wikipedia.org/wiki/Anguilla',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Anguilla',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-swimming-pool-with-a-view-of-the-ocean-XuI5512ta3g',
          wikiLink: 'https://en.wikipedia.org/wiki/Anguilla',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Anguilla',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-couple-of-lawn-chairs-sitting-on-top-of-a-sandy-beach-rkl4Es1T9gI',
          wikiLink: 'https://en.wikipedia.org/wiki/Anguilla',
        },
      },
    ],
  },
  {
    name: 'Antarctica',
    code: 'AQ',
    lat: -75.250973,
    long: -0.071389,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/LysuRGQjMwo',
          wikiLink: 'https://en.wikipedia.org/wiki/Antarctica',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/iceberg-antarctica-polar-ice-sea-404966/',
          wikiLink: 'https://en.wikipedia.org/wiki/Antarctica',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Antarctica',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/NaQMJ-xNDWI',
          wikiLink: 'https://en.wikipedia.org/wiki/Antarctica',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Antarctica',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/3Xd5j9-drDA',
          wikiLink: 'https://en.wikipedia.org/wiki/Antarctica',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Anvers Island',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-on-green-grass-field-during-daytime-GJQUr0SgKi0',
          wikiLink: 'https://en.wikipedia.org/wiki/Anvers_Island',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Antarctica',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-very-tall-tower-sitting-on-top-of-a-snow-covered-slope-4kOWpHOxCrQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Antarctica',
        },
      },
    ],
  },
  {
    name: 'Antigua and Barbuda',
    code: 'AG',
    lat: 17.05,
    long: -61.8,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/9CJTrF-HnlU',
          wikiLink: 'https://en.wikipedia.org/wiki/Antigua_and_Barbuda',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/devil-s-bridge-sea-barbuda-antigua-7155984/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Devil%27s_Bridge,_Antigua_and_Barbuda',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Antigua',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/-4itEczXe3U',
          wikiLink: 'https://en.wikipedia.org/wiki/Antigua',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Antigua and Barbuda',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/9CJTrF-HnlU',
          wikiLink: 'https://en.wikipedia.org/wiki/Antigua_and_Barbuda',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Antigua',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-near-body-of-water-during-daytime-9CJTrF-HnlU',
          wikiLink: 'https://en.wikipedia.org/wiki/Antigua',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Antigua',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/body-of-water-near-mountain-and-houses--4itEczXe3U',
          wikiLink: 'https://en.wikipedia.org/wiki/Antigua',
        },
      },
    ],
  },
  {
    name: 'Argentina',
    code: 'AR',
    lat: -34,
    long: -64,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/HGMYZ4scFkY',
          wikiLink: 'https://en.wikipedia.org/wiki/La_Boca',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/landscape-truck-andes-dessert-74572/',
          wikiLink: 'https://en.wikipedia.org/wiki/Andes',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Córdoba',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/2hFmlbMFD14',
          wikiLink: 'https://en.wikipedia.org/wiki/C%C3%B3rdoba,_Argentina',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Río Negro Province',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/cUL8U0kAxrg',
          wikiLink: 'https://en.wikipedia.org/wiki/R%C3%ADo_Negro_Province',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Buenos Aires',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-under-cloudy-sky-during-daytime-z0A_IsZYXfM',
          wikiLink: 'https://en.wikipedia.org/wiki/Buenos_Aires',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'San Carlos de Bariloche',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/river-in-the-middle-of-mountain-under-sky-yLyDmpfivus',
          wikiLink: 'https://es.wikipedia.org/wiki/San_Carlos_de_Bariloche',
        },
      },
    ],
  },
  {
    name: 'Armenia',
    code: 'AM',
    lat: 40,
    long: 45,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/SmueverK-sg',
          wikiLink: 'https://en.wikipedia.org/wiki/Temple_of_Garni',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/armenia-yerevan-ararat-city-3721418/',
          wikiLink: 'https://en.wikipedia.org/wiki/Yerevan',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Syunik Province',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/h5TdClJhDMc',
          wikiLink: 'https://en.wikipedia.org/wiki/Syunik_Province',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Yerevan',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/AjnjJNgD15A',
          wikiLink: 'https://en.wikipedia.org/wiki/Yerevan',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Armenia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photo-of-a-village-during-daytime-VQ07UveM3-U',
          wikiLink: 'https://en.wikipedia.org/wiki/Armenia',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Lori Province',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-green-grass-field-during-daytime-ilBjvU0wxH4',
          wikiLink: 'https://en.wikipedia.org/wiki/Lori_Province',
        },
      },
    ],
  },
  {
    name: 'Aruba',
    code: 'AW',
    lat: 12.5,
    long: -69.96666666,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Ui99Hiv3Ezg',
          wikiLink: 'https://en.wikipedia.org/wiki/Palm_Beach,_Aruba',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/beach-aruba-sandy-beach-sea-26011/',
          wikiLink: 'https://en.wikipedia.org/wiki/Aruba',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Seroe Colorado',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/t_dfcMDZ4T8',
          wikiLink: 'https://en.wikipedia.org/wiki/Seroe_Colorado',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Aruba',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/rfJDRWkHmKs',
          wikiLink: 'https://en.wikipedia.org/wiki/Aruba',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Aruba',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-on-beach-during-daytime-1o7_YwVkms8',
          wikiLink: 'https://en.wikipedia.org/wiki/Aruba',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Aruba',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-birds-eye-view-of-a-beach-and-resort-D5WueT15KGw',
          wikiLink: 'https://en.wikipedia.org/wiki/Aruba',
        },
      },
    ],
  },
  {
    name: 'Australia',
    code: 'AU',
    lat: -27,
    long: 133,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/WEtXkeIlMoM',
          wikiLink: 'https://en.wikipedia.org/wiki/Uluru',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/australia-uluru-ayers-rock-mountain-630219/',
          wikiLink: 'https://en.wikipedia.org/wiki/Uluru',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Australia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-and-trees-under-blue-sky-during-daytime-EccMAZtkS7g',
          wikiLink: 'https://en.wikipedia.org/wiki/Australia',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Flinders Street railway station',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/o3TZx8_j7FE',
          wikiLink:
            'https://en.wikipedia.org/wiki/Flinders_Street_railway_station',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Sydney',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-concrete-buildings-and-bridge-beside-ocean-under-cloudy-sky-J61Sh5YrQho',
          wikiLink: 'https://en.wikipedia.org/wiki/Sydney',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Melbourne',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-bridge-across-city-buildings--gmtAa0Q5MI',
          wikiLink: 'https://en.wikipedia.org/wiki/Melbourne',
        },
      },
    ],
  },
  {
    name: 'Austria',
    code: 'AT',
    lat: 47.33333333,
    long: 13.33333333,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/3wFRlwS91yk',
          wikiLink: 'https://en.wikipedia.org/wiki/Hallstatt',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/mountains-alps-trees-1244132/',
          wikiLink: 'https://en.wikipedia.org/wiki/Alps',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Austria',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/ilBSVoU1mGs',
          wikiLink: 'https://en.wikipedia.org/wiki/Austria',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Austria',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/yWkeQZ_-IdU',
          wikiLink: 'https://en.wikipedia.org/wiki/Austria',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Austria',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-body-of-water-8jp2a2hc5xs',
          wikiLink: 'https://en.wikipedia.org/wiki/Austria',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Hallstatt',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/photography-of-house-beside-body-of-water-tV06QVJXVxU',
          wikiLink: 'https://en.wikipedia.org/wiki/Hallstatt',
        },
      },
    ],
  },
  {
    name: 'Azerbaijan',
    code: 'AZ',
    lat: 40.5,
    long: 47.5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/wX_9ZhIErpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Flame_Towers',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/winter-azerbaijan-horse-landscape-2048629/',
          wikiLink: 'https://en.wikipedia.org/wiki/Azerbaijan',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Khizi District',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/D-Zz4om1dqQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Khizi_District',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Baku',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/AOWi0OXBUQo',
          wikiLink: 'https://en.wikipedia.org/wiki/Baku',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Baku',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-large-body-of-water-and-a-ferris-wheel-ka5GnsV9CTk',
          wikiLink: 'https://en.wikipedia.org/wiki/Baku',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Azerbaijan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-scenic-view-of-a-forested-area-with-mountains-in-the-distance-kk0Xu9ft6PE',
          wikiLink: 'https://en.wikipedia.org/wiki/Azerbaijan',
        },
      },
    ],
  },
  {
    name: 'Bahamas',
    code: 'BS',
    lat: 24.25,
    long: -76,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/xa-fv2RQCPY',
          wikiLink: 'https://en.wikipedia.org/wiki/Pig_Beach',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bahamas-lighthouse-caribbean-sea-783799/',
          wikiLink: 'https://en.wikipedia.org/wiki/The_Bahamas',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Exuma',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/hpZh7Pvs9mk',
          wikiLink: 'https://en.wikipedia.org/wiki/Exuma',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Bahamas',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/A6bX1G3zuMA',
          wikiLink: 'https://en.wikipedia.org/wiki/The_Bahamas',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Bahamas',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-lighthouse-on-brown-rock-formation-on-sea-during-daytime-T8mbim7bbOg',
          wikiLink: 'https://en.wikipedia.org/wiki/The_Bahamas',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Berry Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-standing-on-white-and-blue-rock-formation-near-body-of-water-during-daytime-cRQYPtbLLew',
          wikiLink: 'https://en.wikipedia.org/wiki/Berry_Islands',
        },
      },
    ],
  },
  {
    name: 'Bahrain',
    code: 'BH',
    lat: 26,
    long: 50.55,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/S7FEiLILEuM',
          wikiLink: 'https://en.wikipedia.org/wiki/Bahrain_World_Trade_Center',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sunset-sundown-bahrain-600769/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bahrain',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Bahrain',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/rkU1mK53RBc',
          wikiLink: 'https://en.wikipedia.org/wiki/Bahrain',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Bahrain',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/eNzpsGEJr7g',
          wikiLink: 'https://en.wikipedia.org/wiki/Bahrain',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Bahrain',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/person-riding-on-white-boat-on-sea-during-daytime-NSp34_xvNrA',
          wikiLink: 'https://en.wikipedia.org/wiki/Bahrain',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Bahrain',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-in-blue-shirt-sitting-on-brown-wooden-dock-during-daytime-UiLznQavBkA',
          wikiLink: 'https://en.wikipedia.org/wiki/Bahrain',
        },
      },
    ],
  },
  {
    name: 'Bangladesh',
    code: 'BD',
    lat: 24,
    long: 90,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/DWoOJ2C2uns',
          wikiLink: 'https://en.wikipedia.org/wiki/Sonargaon',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bangladesh-lake-boat-green-boat-3543466/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bangladesh',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Sirajganj District',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/AMqrW1Sxx3g',
          wikiLink: 'https://en.wikipedia.org/wiki/Sirajganj_District',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Chittagong District',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/gc8IPK97-hE',
          wikiLink: 'https://en.wikipedia.org/wiki/Chittagong_District',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Dhaka',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-busy-city-street-with-lots-of-traffic-8yHHqi3d0pM',
          wikiLink: 'https://en.wikipedia.org/wiki/Dhaka',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Manpura Island',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-field-with-a-body-of-water-in-the-background-swAnrMQlGx8',
          wikiLink: 'https://en.wikipedia.org/wiki/Manpura_Island',
        },
      },
    ],
  },
  {
    name: 'Barbados',
    code: 'BB',
    lat: 13.16666666,
    long: -59.53333333,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/-76qup1BmIs',
          wikiLink: 'https://en.wikipedia.org/wiki/Barbados',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/clearwater-villa-beach-barbados-1549544/',
          wikiLink: 'https://en.wikipedia.org/wiki/Barbados',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Bridgetown',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/qugWVn01mF4',
          wikiLink: 'https://en.wikipedia.org/wiki/Bridgetown',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Barbados',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/20_OGkT-gl8',
          wikiLink: 'https://en.wikipedia.org/wiki/Barbados',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Barbados',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-person-standing-in-an-alley-between-two-buildings-BZJSzvLsxuA',
          wikiLink: 'https://en.wikipedia.org/wiki/Barbados',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Barbados',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-couple-of-horses-standing-in-a-body-of-water-rV0nFGJQM2E',
          wikiLink: 'https://en.wikipedia.org/wiki/Barbados',
        },
      },
    ],
  },
  {
    name: 'Belarus',
    code: 'BY',
    lat: 53,
    long: 28,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/NxFNWUMxnGM',
          wikiLink: 'https://en.wikipedia.org/wiki/Minsk',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/vilino-belarus-landscape-scenic-107571/',
          wikiLink: 'https://en.wikipedia.org/wiki/Belarus',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Belarus',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/GfTJ0ElQ-3U',
          wikiLink: 'https://en.wikipedia.org/wiki/Belarus',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Belarus',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/jxNWI-_tbf0',
          wikiLink: 'https://en.wikipedia.org/wiki/Belarus',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Minsk',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-car-parked-in-front-of-a-building-P8IgjgxMNj4',
          wikiLink: 'https://en.wikipedia.org/wiki/Minsk',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Belarus',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-train-station-during-daytime-MRMnkiJE7nA',
          wikiLink: 'https://en.wikipedia.org/wiki/Belarus',
        },
      },
    ],
  },
  {
    name: 'Belgium',
    code: 'BE',
    lat: 50.83333333,
    long: 4,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/_BBlUZhRzjg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bruges',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/brugge-bruges-belgium-architecture-5278796/',
          wikiLink: 'https://en.wikipedia.org/wiki/Belgium',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Bruges',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/H6BtRELIN8U',
          wikiLink: 'https://en.wikipedia.org/wiki/Bruges',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Leuven',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/ZPpC98Vus3k',
          wikiLink: 'https://en.wikipedia.org/wiki/Leuven',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Brussels',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-and-black-vehicles-parked-beside-road-p0oC-D6rbWs',
          wikiLink: 'https://en.wikipedia.org/wiki/Brussels',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Bruges',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-yellow-concrete-houses-at-daytime-GMpcFjGbnMQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Bruges',
        },
      },
    ],
  },
  {
    name: 'Belize',
    code: 'BZ',
    lat: 17.25,
    long: -88.75,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/erKKEmRLuV0',
          wikiLink: 'https://en.wikipedia.org/wiki/Ambergris_Caye',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/water-creek-landscape-flow-hdr-7726729/',
          wikiLink: 'https://en.wikipedia.org/wiki/Belize',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Belize',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/qVNSANBjYdI',
          wikiLink: 'https://en.wikipedia.org/wiki/Belize',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Belize',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/RJEfkvKv2pE',
          wikiLink: 'https://en.wikipedia.org/wiki/Belize',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Progreso',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-and-green-grass-field-xcjLPRGgZ1A',
          wikiLink: 'https://en.wikipedia.org/wiki/Progreso,_Belize',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Orange Walk Town',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-sitting-on-bench-under-green-tree-during-daytime-9Kxu9Odz4yA',
          wikiLink: 'https://en.wikipedia.org/wiki/Orange_Walk_Town',
        },
      },
    ],
  },
  {
    name: 'Benin',
    code: 'BJ',
    lat: 9.5,
    long: 2.25,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/benign-africa-child-black-2223164/',
          wikiLink: 'https://en.wikipedia.org/wiki/Benin',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/benin-lake-africa-water-boat-2222345/',
          wikiLink: 'https://en.wikipedia.org/wiki/Benin',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Benin',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/eIkHhNyAqqQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Benin',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Benin',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/d3btU_HGuAU',
          wikiLink: 'https://en.wikipedia.org/wiki/Benin',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Ganvie',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/map-WLblwP3mfWg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ganvie',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Bopa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-bridge-on-sunrise-tqkzjhnI9i8',
          wikiLink: 'https://en.wikipedia.org/wiki/Bopa',
        },
      },
    ],
  },
  {
    name: 'Bermuda',
    code: 'BM',
    lat: 32.33333333,
    long: -64.75,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/0a17lg30MtY',
          wikiLink: '',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bermuda-pink-sand-beach-ocean-4096704/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bermuda',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Bermuda',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Ule2K_7IlV4',
          wikiLink: 'https://en.wikipedia.org/wiki/Bermuda',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Bermuda',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/eS8CVXBA3jU',
          wikiLink: 'https://en.wikipedia.org/wiki/Bermuda',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Bermuda',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/houses-near-sea-during-daytime-COsskg4ilTQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Bermuda',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Bermuda',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-blue-sky-over-the-beach-apdbXDriykI',
          wikiLink: 'https://en.wikipedia.org/wiki/Bermuda',
        },
      },
    ],
  },
  {
    name: 'Bhutan',
    code: 'BT',
    lat: 27.5,
    long: 90.5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/ZdwVvRdel8A',
          wikiLink: 'https://en.wikipedia.org/wiki/Paro_Taktsang',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/temple-monastery-cliff-cliffside-171377/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bhutan',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Bhutan',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/TitqIqZPcIY',
          wikiLink: 'https://en.wikipedia.org/wiki/Bhutan',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Bhutan',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/JoIx9FWaX38',
          wikiLink: 'https://en.wikipedia.org/wiki/Bhutan',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Thimphu',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/houses-on-green-grass-field-under-white-clouds-during-daytime-AnH_qAkPl6s',
          wikiLink: 'https://en.wikipedia.org/wiki/Thimphu',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Bhutan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-green-grass-field-during-daytime-BWud63Wxs5Y',
          wikiLink: 'https://en.wikipedia.org/wiki/Bhutan',
        },
      },
    ],
  },
  {
    name: 'Bolivia',
    code: 'BO',
    lat: -17,
    long: -65,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Y_Z-ltvMjdg',
          wikiLink: 'https://en.wikipedia.org/wiki/Salar_de_Uyuni',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/desert-landscape-sand-mountain-4388204/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bolivia',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Bolivia',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/8Fs2u-4SKrs',
          wikiLink: 'https://en.wikipedia.org/wiki/Bolivia',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Bolivia',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/NGjDM-XBc-c',
          wikiLink: 'https://en.wikipedia.org/wiki/Bolivia',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Bolivia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-mountain-range-from-a-distance-frymIuncA34',
          wikiLink: 'https://en.wikipedia.org/wiki/Bolivia',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Bolivia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-rock-on-beach-pDgHGX0lxVs',
          wikiLink: 'https://en.wikipedia.org/wiki/Bolivia',
        },
      },
    ],
  },
  {
    name: 'Bosnia and Herzegovina',
    code: 'BA',
    lat: 44,
    long: 18,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/GZjH4cVGgCs',
          wikiLink: 'https://en.wikipedia.org/wiki/Stari_Most',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mostar-bridge-travel-bosnia-1155672/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bosnia_and_Herzegovina',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Bosnia and Herzegovina',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/yvlJyrU4Fq4',
          wikiLink: 'https://en.wikipedia.org/wiki/Bosnia_and_Herzegovina',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Bosnia and Herzegovina',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/b9pHmKun4YY',
          wikiLink: 'https://en.wikipedia.org/wiki/Bosnia_and_Herzegovina',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Bosnia and Herzegovina',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-under-blue-sky-during-daytime-agZWht6xj-A',
          wikiLink: 'https://en.wikipedia.org/wiki/Bosnia_and_Herzegovina',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Bosnia and Herzegovina',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/houses-near-river-and-trees-during-daytime-Bmf8Wi1VOh4',
          wikiLink: 'https://en.wikipedia.org/wiki/Bosnia_and_Herzegovina',
        },
      },
    ],
  },
  {
    name: 'Botswana',
    code: 'BW',
    lat: -22,
    long: 24,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/vcN7_lk91rM',
          wikiLink: 'https://en.wikipedia.org/wiki/Botswana',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/botswana-baobab-baobab-tree-694485/',
          wikiLink: 'https://en.wikipedia.org/wiki/Botswana',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Botswana',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/vcN7_lk91rM',
          wikiLink: 'https://en.wikipedia.org/wiki/Botswana',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Botswana',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/0K_ZvuPzexI',
          wikiLink: 'https://en.wikipedia.org/wiki/Botswana',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Okavango Delta',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-river-running-through-a-lush-green-field-jk_2Ak2z3JY',
          wikiLink: 'https://en.wikipedia.org/wiki/Okavango_Delta',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Botswana',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-flock-of-birds-standing-on-the-shore-of-a-lake-fG9ay5fcIYI',
          wikiLink: 'https://en.wikipedia.org/wiki/Botswana',
        },
      },
    ],
  },
  {
    name: 'Bouvet Islands',
    code: 'BV',
    lat: -54.423199,
    long: 3.413194,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Bouvet_island.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bouvet_Island',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Bouvet_Island_west_coast_glacier.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bouvet_Island',
        },
      },
    ],
  },
  {
    name: 'Brazil',
    code: 'BR',
    lat: -10,
    long: -55,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/WjvrLSTkRck',
          wikiLink: 'https://en.wikipedia.org/wiki/Rio_de_Janeiro',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/rio-de-janeiro-brazil-travel-rio-4566312/',
          wikiLink: 'https://en.wikipedia.org/wiki/Rio_de_Janeiro',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Brazil',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/QaWSsUSJMbo',
          wikiLink: 'https://en.wikipedia.org/wiki/Brazil',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Brazil',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/V7jzq-Zgrvo',
          wikiLink: 'https://en.wikipedia.org/wiki/Brazil',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Brazil',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/birds-eye-photography-of-city-near-trees-JesYw3Z-vHk',
          wikiLink: 'https://en.wikipedia.org/wiki/Brazil',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Rio de Janeiro',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-with-many-buildings-and-a-hill-in-the-background-rMJquL0_Qhk',
          wikiLink: 'https://en.wikipedia.org/wiki/Rio_de_Janeiro',
        },
      },
    ],
  },
  {
    name: 'British Indian Ocean Territory',
    code: 'IO',
    lat: -6,
    long: 71.5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Chagossians_visiting_the_British_Indian_Ocean_Territory_as_tourists.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/British_Indian_Ocean_Territory',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:The_O_Club_on_Diego_Garcia.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Naval_Support_Facility_Diego_Garcia',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'British Indian Ocean Territory',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Scientist_Nick_Graham_does_a_fish_count,_British_Indian_Ocean_Territory_(7029059225).jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/British_Indian_Ocean_Territory',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'British Indian Ocean Territory',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:US_Navy_100930-N-9402T-226_Sailors_engage_in_a_live-fire_training_exercise_at_the_British_Indian_Ocean_Territory_firing_range.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/British_Indian_Ocean_Territory',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'British Indian Ocean Territory',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/thom_astro/51312819042/',
          wikiLink:
            'https://en.wikipedia.org/wiki/British_Indian_Ocean_Territory',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'British Indian Ocean Territory',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/mattierocks/451469393/',
          wikiLink:
            'https://en.wikipedia.org/wiki/British_Indian_Ocean_Territory',
        },
      },
    ],
  },
  {
    name: 'British Virgin Islands',
    code: 'VG',
    lat: 18.431383,
    long: -64.62305,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bay-british-virgin-islands-yacht-434934/',
          wikiLink: 'https://en.wikipedia.org/wiki/British_Virgin_Islands',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/road-town-tortola-2633026/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tortola',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'British Virgin Islands',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/N4-ghgyy9-M',
          wikiLink: 'https://en.wikipedia.org/wiki/British_Virgin_Islands',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Tortola',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/BKFtc2QfiaY',
          wikiLink: 'https://en.wikipedia.org/wiki/Tortola',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Tortola',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-standing-on-a-pier-next-to-a-body-of-water-BKFtc2QfiaY',
          wikiLink: 'https://en.wikipedia.org/wiki/Tortola',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'British Virgin Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-beach-with-trees-and-houses-Q8Ox8kojpZ0',
          wikiLink: 'https://en.wikipedia.org/wiki/British_Virgin_Islands',
        },
      },
    ],
  },
  {
    name: 'Brunei',
    code: 'BN',
    lat: 4.5,
    long: 114.6666667,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/ship-junk-mosque-saifuddin-brunei-523587/',
          wikiLink: 'https://en.wikipedia.org/wiki/Brunei',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/brunei-empire-hotel-overseas-travel-2946137/',
          wikiLink: 'https://en.wikipedia.org/wiki/Brunei',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Brunei',
          photographer: '',
          imageLink:
            'https://www.pexels.com/photo/the-famous-yayasan-shopping-mall-in-brunei-6613502/',
          wikiLink: 'https://en.wikipedia.org/wiki/Brunei',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Brunei',
          photographer: '',
          imageLink:
            'https://www.pexels.com/photo/facade-of-a-building-under-the-blue-sky-12587133/',
          wikiLink: 'https://en.wikipedia.org/wiki/Brunei',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Brunei',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-with-a-lot-of-windows-on-top-of-it-_hsVmtN4obQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Brunei',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Brunei',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-street-with-palm-trees-and-buildings-in-the-background-zUvPhMmUIAc',
          wikiLink: 'https://en.wikipedia.org/wiki/Brunei',
        },
      },
    ],
  },
  {
    name: 'Bulgaria',
    code: 'BG',
    lat: 43,
    long: 25,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/UPoSvgmFKmo',
          wikiLink:
            'https://en.wikipedia.org/wiki/Alexander_Nevsky_Cathedral,_Sofia',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/scenic-mountain-landscape-blue-2014114/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bulgaria',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Kardzhali Reservoir',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/j1OVl4P_BQQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Kardzhali_Reservoir',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Buzludzha monument',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/gF8aHM445P4',
          wikiLink: 'https://en.wikipedia.org/wiki/Buzludzha_monument',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Sofia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-blue-building-with-a-tree-in-front-of-it-SmmdNM3V8O0',
          wikiLink: 'https://en.wikipedia.org/wiki/Sofia',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Bulgaria',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-waterfall-in-the-middle-of-a-canyon-E2pdnRw0qaI',
          wikiLink: 'https://en.wikipedia.org/wiki/Bulgaria',
        },
      },
    ],
  },
  {
    name: 'Burkina Faso',
    code: 'BF',
    lat: 13,
    long: -2,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/africa-afrique-burkina-faso-pottery-170206/',
          wikiLink: 'https://en.wikipedia.org/wiki/Burkina_Faso',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/africa-burkina-faso-homes-205218/',
          wikiLink: 'https://en.wikipedia.org/wiki/Burkina_Faso',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Burkina Faso',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/8wuOLdN77A4',
          wikiLink: 'https://en.wikipedia.org/wiki/Burkina_Faso',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Burkina Faso',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/africa-afrique-burkina-faso-pottery-170206/',
          wikiLink: 'https://en.wikipedia.org/wiki/Burkina_Faso',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Burkina Faso',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/130288136@N06/32477596225/',
          wikiLink: 'https://en.wikipedia.org/wiki/Burkina_Faso',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Burkina Faso',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/leapingaroundtheworld/2176554472/',
          wikiLink: 'https://en.wikipedia.org/wiki/Burkina_Faso',
        },
      },
    ],
  },
  {
    name: 'Burundi',
    code: 'BI',
    lat: -3.5,
    long: 30,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/wilderness-africa-nature-scenic-2434470/',
          wikiLink: 'https://en.wikipedia.org/wiki/Burundi',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/burundi-african-gitega-6878378/',
          wikiLink: 'https://en.wikipedia.org/wiki/Gitega',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Cankuzo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-grassy-field-with-trees-and-mountains-in-the-background-ne5VdPhGRu0',
          wikiLink: 'https://en.wikipedia.org/wiki/Cankuzo_Province',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Gitega',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-red-and-white-house-with-a-balcony-YlzfGexgBX0',
          wikiLink: 'https://en.wikipedia.org/wiki/Gitega',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Burundi',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/counterculturecoffee/7487178892/',
          wikiLink: 'https://en.wikipedia.org/wiki/Burundi',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Muramvya',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-lush-green-hillside-covered-in-lots-of-trees-UA_RKy_75yM',
          wikiLink: 'https://en.wikipedia.org/wiki/Muramvya',
        },
      },
    ],
  },
  {
    name: 'Cambodia',
    code: 'KH',
    lat: 13,
    long: 105,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/OvEr7BwXxxg',
          wikiLink: 'https://en.wikipedia.org/wiki/Angkor_Wat',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/travel-tourist-cambodia-island-6268605/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cambodia',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Angkor Wat',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/9zXMb-E8pI0',
          wikiLink: 'https://en.wikipedia.org/wiki/Angkor_Wat',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Cambodia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-plants-on-brown-soil-under-white-sky-during-daytime-_A58yesiMCM',
          wikiLink: 'https://en.wikipedia.org/wiki/Cambodia',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Kratié Province',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/birds-eyeview-photo-of-urban-houses-GaQzbTd4jO8',
          wikiLink: 'https://en.wikipedia.org/wiki/Krati%C3%A9_province',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Phnom Penh',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-riding-motorcycles-down-a-street-QTwVrBpJ7ew',
          wikiLink: 'https://en.wikipedia.org/wiki/Phnom_Penh',
        },
      },
    ],
  },
  {
    name: 'Cameroon',
    code: 'CM',
    lat: 6,
    long: 12,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/bZnciM7XIYw',
          wikiLink: 'https://en.wikipedia.org/wiki/Douala',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/travel-nature-landscape-skirt-3269736/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cameroon',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Yaoundé',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/EllfOCOSDWA',
          wikiLink: 'https://en.wikipedia.org/wiki/Yaound%C3%A9',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Cameroon',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/TdwJW7L5DXg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cameroon',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Cameroon',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-small-town-from-a-hill-wqDNF4KcHuI',
          wikiLink: 'https://en.wikipedia.org/wiki/Cameroon',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Cameroon',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-dirt-road-with-trees-on-either-side-of-it-HVytxpsuIxg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cameroon',
        },
      },
    ],
  },
  {
    name: 'Canada',
    code: 'CA',
    lat: 60,
    long: -95,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/uouH1bGxeeU',
          wikiLink: 'https://en.wikipedia.org/wiki/Ontario',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/lighthouse-ocean-sea-coast-travel-1872998/',
          wikiLink: 'https://en.wikipedia.org/wiki/Canada',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Lake Louise',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/iJTXWlMmoOg',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Louise,_Alberta',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Canada',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/MQlVnTc4OBg',
          wikiLink: 'https://en.wikipedia.org/wiki/Canada',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Moraine Lake',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/scenery-of-mountain-oMneOBYhJxY',
          wikiLink: 'https://en.wikipedia.org/wiki/Moraine_Lake',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Canada',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-island-with-bridge-Yc9h5SJdEzI',
          wikiLink: 'https://en.wikipedia.org/wiki/Canada',
        },
      },
    ],
  },
  {
    name: 'Cape Verde',
    code: 'CV',
    lat: 16,
    long: -24,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/-1EKogAcUNc',
          wikiLink: 'https://en.wikipedia.org/wiki/Sal,_Cape_Verde',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/fisherman-sea-atlantic-island-678467/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cape_Verde',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Cape Verde',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/lRowikzz4cw',
          wikiLink: 'https://en.wikipedia.org/wiki/Cape_Verde',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'São Vicente',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/7Zl9mnvMGxI',
          wikiLink:
            'https://en.wikipedia.org/wiki/S%C3%A3o_Vicente,_Cape_Verde',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Cape Verde',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-standing-on-the-side-of-a-road-4zpXZBxOPkk',
          wikiLink: 'https://en.wikipedia.org/wiki/Cape_Verde',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Cape Verde',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-with-mountains-in-the-background-RucY9lINMsY',
          wikiLink: 'https://en.wikipedia.org/wiki/Cape_Verde',
        },
      },
    ],
  },
  {
    name: 'Cayman Islands',
    code: 'KY',
    lat: 19.5,
    long: -80.5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/iTTiIKcV3-M',
          wikiLink: 'https://en.wikipedia.org/wiki/Cayman_Islands',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/grand-cayman-cayman-islands-2072631/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cayman_Islands',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Cayman Islands',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/N3WTjwUesdc',
          wikiLink: 'https://en.wikipedia.org/wiki/Cayman_Islands',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Cayman Islands',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cayman-cayman-island-island-52328/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cayman_Islands',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Cayman Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-blue-boats-on-sea-during-daytime-BYBaMLHHsJc',
          wikiLink: 'https://en.wikipedia.org/wiki/Cayman_Islands',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Cayman Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-palm-tree-in-front-of-a-row-of-white-buildings-2RL1CSvoVCo',
          wikiLink: 'https://en.wikipedia.org/wiki/Cayman_Islands',
        },
      },
    ],
  },
  {
    name: 'Central African Republic',
    code: 'CF',
    lat: 7,
    long: 21,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Central_African_Republic_1995.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Central_African_Republic',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/usarmyafrica/11382638006',
          wikiLink: 'https://en.wikipedia.org/wiki/Central_African_Republic',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Central African Republic',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Stoneworkers_in_the_Central_African_Republic_8.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Central_African_Republic',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Central African Republic',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Central_African_Republic_1995.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Central_African_Republic',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Central African Republic',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/gpforeducation/21630498240/',
          wikiLink: 'https://en.wikipedia.org/wiki/Central_African_Republic',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Central African Republic',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/gpforeducation/21792402066/',
          wikiLink: 'https://en.wikipedia.org/wiki/Central_African_Republic',
        },
      },
    ],
  },
  {
    name: 'Chad',
    code: 'TD',
    lat: 15,
    long: 19,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/caravan-desert-sahara-sand-camel-4483792/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sahara',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/road-tree-africa-path-chad-696570/',
          wikiLink: 'https://en.wikipedia.org/wiki/Chad',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Chad',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/isa-boutriau/8598444136/in/album-72157633005229113/',
          wikiLink: 'https://en.wikipedia.org/wiki/Chad',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Chad',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/isa-boutriau/8562596717/in/album-72157633005229113/',
          wikiLink: 'https://en.wikipedia.org/wiki/Chad',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Chad',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/abstract-wallpaper-WDSeM4shYTA',
          wikiLink: 'https://en.wikipedia.org/wiki/Chad',
        },
      },
      {
        number: 6,
        mainImage: {
          name: "N'Djamena",
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/ismouz/4951716013/in/photolist-8xyQE2-8xC3vy-2ob1VYm-UUs5f-2oaW5cn-UUsjy-USRWz-UUzM3-USSNi-2ob123F-4QgzYk-2oc6xzp-21UYMRb-ivQ4Rr-2ocav1R-8xzA1v-8xCCuy-8xzAn8-8xzBnn-8xzBKr-6ax6pa-USSmv-UUtMy-aAhizp-aAhi9V-vJkM8-2dnqUnA-ab6wVs-USTzv-88sG8P-rQ5yYR-88wdEo-88wdSw-88sXx4-88vUZ1-2fy5JVV-88wdZ1-DNtHV-DNtBc-DNtTH-DNtUL-2n1F8mE-DNty4-DNu24-DNtDJ-66NVAQ-2hMZjvd-2n1FxXR-DNoKw-2oaYFY2',
          wikiLink: 'https://en.wikipedia.org/wiki/N%27Djamena',
        },
      },
    ],
  },
  {
    name: 'Chile',
    code: 'CL',
    lat: -30,
    long: -71,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/H6KJ2D0LphU',
          wikiLink: 'https://en.wikipedia.org/wiki/Valpara%C3%ADso',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/chile-mountains-lake-landscape-7430434/',
          wikiLink: 'https://en.wikipedia.org/wiki/Chile',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Chile',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/GU02I9D60dQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Chile',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Easter Island',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/anvAqsSbtms',
          wikiLink: 'https://en.wikipedia.org/wiki/Easter_Island',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Las Condes',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photo-of-city-Y9Wp5keJsn8',
          wikiLink: 'https://en.wikipedia.org/wiki/Las_Condess',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'San Pedro de Atacama',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-grass-on-brown-soil-during-day-time-612hZnDoDE4',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Pedro_de_Atacama',
        },
      },
    ],
  },
  {
    name: 'China',
    code: 'CN',
    lat: 35,
    long: 105,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Pnc2Uxb7PG0',
          wikiLink: 'https://en.wikipedia.org/wiki/Nanjing',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/yellow-mountains-mountain-ranges-532857/',
          wikiLink: 'https://en.wikipedia.org/wiki/Huangshan',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'China',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/zboO0K1WfY4',
          wikiLink: 'https://en.wikipedia.org/wiki/China',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Zhangjiajie',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/gii7lF4y0WY',
          wikiLink:
            'https://en.wikipedia.org/wiki/Zhangjiajie_National_Forest_Park',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Fenghuang County',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-boat-on-body-of-water-near-buildings-Y-HwuSKt6gM',
          wikiLink: 'https://en.wikipedia.org/wiki/Fenghuang_County',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Namtso',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/mountain-near-on-body-of-water-photography-8BH_M-53CyA',
          wikiLink: 'https://en.wikipedia.org/wiki/Namtso',
        },
      },
    ],
  },
  {
    name: 'Christmas Island',
    code: 'CX',
    lat: -10.5,
    long: 105.6666667,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/gm5iCSYrLpQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Christmas_Island_red_crab',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Flying_Fish_Cove_at_Christmas_Island.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Flying_Fish_Cove',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Christmas Island',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/kristianbell/10672861884',
          wikiLink: 'https://en.wikipedia.org/wiki/Christmas_Island',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Christmas Island',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Christmas_Island#/media/File:The_first_dam_ever_built_on_Christmas_Island.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Christmas_Island',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Christmas Island',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/crabs-near-seashore-gm5iCSYrLpQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Christmas_Island',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Christmas Island',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/geraintdraheim/19008368439/',
          wikiLink: 'https://en.wikipedia.org/wiki/Christmas_Island',
        },
      },
    ],
  },
  {
    name: 'Cocos (Keeling) Islands',
    code: 'CC',
    lat: -12.5,
    long: 96.83333333,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Cocos_%28Keeling%29_Islands_2017_%2843%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cocos_(Keeling)_Islands',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Cocos_%28Keeling%29_Islands_%2832206670676%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cocos_(Keeling)_Islands',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Cocos (Keeling) Islands',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Cocos_(Keeling)_Islands#/media/File:Lagoon_Beach_(25152057091).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cocos_(Keeling)_Islands',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Cocos (Keeling) Islands',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/24718365955',
          wikiLink: 'https://en.wikipedia.org/wiki/Cocos_(Keeling)_Islands',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Cocos (Keeling) Islands',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/gobblywink/8165988151/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cocos_(Keeling)_Islands',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Cocos (Keeling) Islands',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/nevfarquah/1320290020/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cocos_(Keeling)_Islands',
        },
      },
    ],
  },
  {
    name: 'Colombia',
    code: 'CO',
    lat: 4,
    long: -72,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/J0suKy48jfk',
          wikiLink: 'https://en.wikipedia.org/wiki/Cartagena,_Colombia',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/city-architecture-urban-bogota-4457801/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cundinamarca_Department',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Colombia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/smokey-mountain-with-coconut-palm-trees-XV4XUU7gWlk',
          wikiLink: 'https://en.wikipedia.org/wiki/Colombia',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Guatapé',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-and-body-of-water-during-daytime-PRJWxwDmtsE',
          wikiLink: 'https://en.wikipedia.org/wiki/Guatap%C3%A9',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Salento',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-street-beside-white-structure-ioLq-zmtITA',
          wikiLink: 'https://en.wikipedia.org/wiki/Salento,_Quind%C3%ADo',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Guatapé',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-body-of-water-surrounded-by-forest-1l6ntLyiUbI',
          wikiLink: 'https://en.wikipedia.org/wiki/Guatap%C3%A9',
        },
      },
    ],
  },
  {
    name: 'Comoros',
    code: 'KM',
    lat: -12.16666666,
    long: 44.25,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Anjouan_-_Islands_of_Comoros.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Anjouan',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/dkeats/5975110268',
          wikiLink: 'https://en.wikipedia.org/wiki/Comoros',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Comoros',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/water4islands/29724351291/in/photostream/',
          wikiLink: 'https://en.wikipedia.org/wiki/Comoros',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Ngazidja',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/23371486@N04/2501495107/in/photostream/',
          wikiLink: 'https://en.wikipedia.org/wiki/Grande_Comore',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Comoros',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/ipostcross/49138640558/',
          wikiLink:
            'https://www.google.com/url?sa=t&source=web&rct=j&opi=89978449&url=https://en.wikipedia.org/wiki/Comoros&ved=2ahUKEwjaosXXt-iHAxXl-QIHHZBlIQgQFnoECEYQAQ&usg=AOvVaw0Ybj0GIlqJBqE5thIeeNs_',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Comoros',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/p_blonde/396139853/in/photolist-B1jzt-ARtUS-p4D9sJ-8e9efi-ARiBD-aahFDp-8YGvSN-B1nAg-L626p-B1J7u-B1nAa-L626X-8wwdov-B1J7w-w3EJmG-aDMob2-9st8qg-trEZDF-xKPPwc-ARiBG-4Ns6eS-hUhLKA-2mgSTr6-hUhLDw-AuhLQ6-4P8781-4GxpM5-L626T-hUhCW9-4P43cn-hUiuoc-rUQXbB-hUiyiM-hUhU1A-hUhaix-hUhNvN-hUit9i-hUhTG1-aDRgy1-hUhDZ1-21EJ2Ho-DgQ4Dk-4P2jo8-hUhrhM-mvqJVm-hUhMHL-hUhF2S-hUhPYh-2o7y4D2-4GxubY',
          wikiLink: 'https://en.wikipedia.org/wiki/Comoros',
        },
      },
    ],
  },
  {
    name: 'Cook Islands',
    code: 'CK',
    lat: -21.23333333,
    long: -159.7666667,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://www.tripadvisor.com.au/Tourism-g294328-Cook_Islands-Vacations.html',
          wikiLink: 'https://en.wikipedia.org/wiki/Rarotonga',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/palms-tropical-beach-tropical-sand-3242342/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cook_Islands',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Cook Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/person-on-boat-at-golden-hour-mz3-0G5VhrQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Cook_Islands',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Cook Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-on-beach-during-daytime-PkgzxrkurRw',
          wikiLink: 'https://en.wikipedia.org/wiki/Cook_Islands',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Cook Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-palm-tree-with-a-half-moon-in-the-sky-ckc9ld5cOSU',
          wikiLink: 'https://en.wikipedia.org/wiki/Cook_Islands',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Avarua',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/nullnfull/15857333430/in/photolist-ihXHYU-qanxrZ-iaCugT-i8A2WV-26Hqpyg-24aaPhf-2gTd9ZS-qafYLw-qrCSMP-qrKfYw-qrCQq4-qanjye-6yysxC-q9Qncw-q9Rfh9-q9RgsL-pupKGW-puPQ8S-qpx211-qrD9bp-qpwXud-q9Rpp7-qrkSGd-puq7DL-qp87vC-5RCqF4-6464fg-fyGecb-6HaHX9-2gSKQpm-6WqAof-GUxZke-2gSLpqc-rPTxVW-2pnLsft-2gSKueB-2gSKwBh-25oM7FG-2gSKRke-2oT35Gu-HNw7kM-qredAe-q9XEFr-q9Z85z-puPswW-6ef12D-4LJn7L-228uryW-vK2kN',
          wikiLink: 'https://en.wikipedia.org/wiki/Avarua',
        },
      },
    ],
  },
  {
    name: 'Costa Rica',
    code: 'CR',
    lat: 10,
    long: -84,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/-TvZ6VB915M',
          wikiLink: 'https://en.wikipedia.org/wiki/Sloth',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/volcano-eruption-costa-rica-arenal-718277/',
          wikiLink: 'https://en.wikipedia.org/wiki/Nuevo_Arenal',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Costa Rica',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-beside-blue-sea-xGhaXZtQb1s',
          wikiLink: 'https://en.wikipedia.org/wiki/Costa_Rica',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Costa Rica',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/person-walking-on-hanging-bridge-surrounded-by-green-trees-during-daytime-RrAlOoykABQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Costa_Rica',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Costa Rica',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/fifthfairway/2206624617/in/photolist-4mZwC2-7kjW2-2kP9ao3-feQapv-ff5t1J-feQiQX-ff5y8Q-feQ9UX-ff5sPu-feQbBH-ff5veq-ff5x77-feQbP8-feQb5v-feQdyg-feQcNe-feQak2-ff5xFU-ff5x33-feQaPr-ff5C3f-feQfsX-ebmoC3-ff5v7f-ff5vXh-feQdEX-7kj6i-feQiqP-ff5Bmy-feQhKK-feQk4P-ff5By5-23LqbRx-7iK8u-7rzNX-7tE2k-7sFB7-7kkhY-7sE7c-7rxt2-7tvhw-7sEH8-dLBUYT-7iGDa-7u1MW-7sEve-7tHCM-7kk7D-7tYfm-7rypf',
          wikiLink: 'https://en.wikipedia.org/wiki/Costa_Rica',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Costa Rica',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-under-blue-sky-0cPWvLRhadE',
          wikiLink: 'https://en.wikipedia.org/wiki/Costa_Rica',
        },
      },
    ],
  },
  {
    name: 'Croatia',
    code: 'HR',
    lat: 45.16666666,
    long: 15.5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/ohoBiu7_KNc',
          wikiLink: 'https://en.wikipedia.org/wiki/Dubrovnik',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/dubrovnik-croatia-kings-landing-512798/',
          wikiLink: 'https://en.wikipedia.org/wiki/Dubrovnik',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Dubrovnik',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/village-and-cliffs-in-aerial-view-photography-ohoBiu7_KNc',
          wikiLink: 'https://en.wikipedia.org/wiki/Dubrovnik',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Hvar',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/concrete-house-beside-body-of-water-WS8UKXSHJaE',
          wikiLink: 'https://en.wikipedia.org/wiki/Hvar',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Croatia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-boats-on-pier-and-city-ybUdMTw7-0c',
          wikiLink: 'https://en.wikipedia.org/wiki/Croatia',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Zagreb',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-blue-trolley-with-a-red-top-on-a-city-street-eR60eyWRous',
          wikiLink: 'https://en.wikipedia.org/wiki/Zagreb',
        },
      },
    ],
  },
  {
    name: 'Cuba',
    code: 'CU',
    lat: 21.5,
    long: -80,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/aZjw7xI3QAA',
          wikiLink: 'https://en.wikipedia.org/wiki/Vi%C3%B1ales',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cuba-antique-car-automobile-vehicle-1211907/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cuba',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Cuba',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-walking-on-walkway-while-holding-his-bicycle-PubtV8UJxB8',
          wikiLink: 'https://en.wikipedia.org/wiki/Cuba',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Havana',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/red-and-black-sedan-traveling-on-road-during-daytime-2rKtspM-FNo',
          wikiLink: 'https://en.wikipedia.org/wiki/Havana',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Cuba',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/pedrosz/33051350992/in/photolist-SmCPab-s8viDV-27dc9XU-rbwhfE-qDXSjV-rQVY6y-s8naVb-913g3R-rbGjqF-913nDk-rQTGqC-RrARkd-LcZq46-913qSH-2beRf4y-KVnCVw-pHmYLt-28A1r4P-qnzsQE-913dLx-qnyLmw-pHmUJR-pH8H1h-rQVa8o-rbuudw-qnztpW-s8noSJ-9USbDH-4AYq5C-qnHhUt-4AYnQu-21MN97g-qE4BXY-bPUDrn-rMadeG-21MN8tx-vqmHk-29SLu7v-rMhdpr-4AYr4N-916iVu-rQVK87-916uoC-ewWTAV-8WvRo2-Rky9pi-d4hteu-916iqu-916myG-KkpoFs',
          wikiLink: 'https://en.wikipedia.org/wiki/Cuba',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Havana',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/66001184@N00/49766850723/in/photolist-2iPJ7Sp-2j1TbWv-SPaaW5-St7i8o-SZmBss-2iQbDwU-RLr2Vm-T5obHx-St6yJ5-SP9TTU-RLsFyW-SZnmMo-SRBE92-SRBDcn-RP1Jnp-SRBExt-2nZpBMm-SZnnyo-SZnmyN-SPaXts-SZnj97-SRBCZD-SZnkLq-SZnna7-SZnk93-SZnkqW-Tp27fD-SRDV3M-RP1HVx-RP1Hve-RLrKLf-SRCHka-St6zAW-2piubRR-RP1H2D-St7kuh-SP9Ucj-SZmH9C-RP1GSk-St7gf5-SP9UEJ-RP1H7D-St7jnC-RLrL9Q-RLrJny-RLsYQu-SPa8ZE-UkE5KQ-SP9S6L-RLrLFG',
          wikiLink: 'https://en.wikipedia.org/wiki/Havana',
        },
      },
    ],
  },
  {
    name: 'Curaçao',
    code: 'CW',
    lat: 12.116667,
    long: -68.933333,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/cKbKFQa32Q4',
          wikiLink: 'https://en.wikipedia.org/wiki/Willemstad',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Handelskade,_Willemstad,_Cura%C3%A7ao_%284383516303%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Willemstad',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Curaçao',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/two-person-lying-on-chaise-lounge-under-sunset-xQF1ZLPqXio',
          wikiLink: 'https://en.wikipedia.org/wiki/Cura%C3%A7ao',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Curaçao',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-rock-formation-near-body-of-water-during-daytime-I3-3_ZLKtTU',
          wikiLink: 'https://en.wikipedia.org/wiki/Cura%C3%A7ao',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Curaçao',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-small-boat-floating-on-top-of-a-body-of-water-cnu_Cd1ACZk',
          wikiLink: 'https://en.wikipedia.org/wiki/Cura%C3%A7ao',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Curaçao',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-white-boat-on-sea-during-daytime-ceA-EdYxQec',
          wikiLink: 'https://en.wikipedia.org/wiki/Cura%C3%A7ao',
        },
      },
    ],
  },
  {
    name: 'Cyprus',
    code: 'CY',
    lat: 35,
    long: 33,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/i51lWwDYHzQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Larnaca',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cyprus-coast-cliff-cavo-greko-3184019/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cape_Greco',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Cyprus',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-statue-of-a-man-and-a-woman-in-the-water-ewz1UASAhHA',
          wikiLink: 'https://en.wikipedia.org/wiki/Cyprus',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Cyprus',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-concrete-pathway-between-walls-with-plants-JPSTXKbqUHw',
          wikiLink: 'https://en.wikipedia.org/wiki/Cyprus',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Cyprus',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-birds-eye-view-of-a-sandy-beach-and-lagoon-Me8Lp4Fn87U',
          wikiLink: 'https://en.wikipedia.org/wiki/Cyprus',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Larnaca',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-on-beach-during-daytime-PDoWMD5cy9I',
          wikiLink: 'https://en.wikipedia.org/wiki/Larnaca',
        },
      },
    ],
  },
  {
    name: 'Czechia',
    code: 'CZ',
    lat: 49.75,
    long: 15.5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/1ak3Z7ZmtQA',
          wikiLink: 'https://en.wikipedia.org/wiki/Charles_Bridge',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bridges-prague-czechia-water-city-3337124/',
          wikiLink: 'https://en.wikipedia.org/wiki/Prague',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Český Krumlov',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-buildings-during-daytime-1eShNpNUB1U',
          wikiLink: 'https://en.wikipedia.org/wiki/%C4%8Cesk%C3%BD_Krumlov',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Prague',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-on-road-during-night-time-Ay2lw6vKZyU',
          wikiLink: 'https://en.wikipedia.org/wiki/Prague',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Prague',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-couple-of-people-that-are-walking-down-a-street-UdowKikI1LY',
          wikiLink: 'https://en.wikipedia.org/wiki/Prague',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Prague',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-white-concrete-building-during-daytime--5bKUmWpuSY',
          wikiLink: 'https://en.wikipedia.org/wiki/Prague',
        },
      },
    ],
  },
  {
    name: 'Democratic Republic of Congo',
    code: 'CD',
    lat: 0,
    long: 25,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/river-trading-4215417/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Democratic_Republic_of_the_Congo',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixnio.com/travel/classrooms-at-the-lusakela-agriculture-station-democratic-republic-of-congo-africa',
          wikiLink:
            'https://en.wikipedia.org/wiki/Democratic_Republic_of_the_Congo',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Democratic Republic of Congo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-on-road-under-blue-sky-and-white-clouds-during-daytime-StJWD4ci8wY',
          wikiLink:
            'https://en.wikipedia.org/wiki/Democratic_Republic_of_the_Congo',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Democratic Republic of Congo',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/55055975@N07/10346895643',
          wikiLink:
            'https://en.wikipedia.org/wiki/Democratic_Republic_of_the_Congo',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Democratic Republic of Congo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-boat-filled-with-lots-of-logs-ukiYtB4MX8c',
          wikiLink:
            'https://en.wikipedia.org/wiki/Democratic_Republic_of_the_Congo',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Democratic Republic of Congo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-bus-parked-in-front-of-a-building-dJ8uDfnaZFc',
          wikiLink:
            'https://en.wikipedia.org/wiki/Democratic_Republic_of_the_Congo',
        },
      },
    ],
  },
  {
    name: 'Denmark',
    code: 'DK',
    lat: 56,
    long: 10,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/imLsDPLnr7Y',
          wikiLink: 'https://en.wikipedia.org/wiki/L%C3%B8kken,_Denmark',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/lighthouse-landmark-coast-tower-5606162/',
          wikiLink: 'https://en.wikipedia.org/wiki/Skagen_Lighthouse',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Løkken',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/top-view-of-mountains-with-houses-imLsDPLnr7Y',
          wikiLink: 'https://en.wikipedia.org/wiki/L%C3%B8kken,_Denmark',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Denmark',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-near-sea-under-white-sky-during-daytime-R2fzDVNkJ6I',
          wikiLink: 'https://en.wikipedia.org/wiki/Denmark',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Nyhavn',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/two-gray-and-black-boats-near-dock-3_ZGrsirryY',
          wikiLink: 'https://en.wikipedia.org/wiki/Nyhavn',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Denmark',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-city-with-lots-of-buildings-5sYjHfS1AJE',
          wikiLink: 'https://en.wikipedia.org/wiki/Denmark',
        },
      },
    ],
  },
  {
    name: 'Djibouti',
    code: 'DJ',
    lat: 11.5,
    long: 43,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/vmfKwrqkOkA',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Abbe',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/djibouti-africa-ras-bir-beach-sea-143451/',
          wikiLink: 'https://en.wikipedia.org/wiki/Djibouti',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Lac Abbe',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/herd-of-goat-near-mountains-during-day-vmfKwrqkOkA',
          wikiLink: 'https://en.wikipedia.org/wiki/Djibouti',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Djibouti',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-rock-wallpaper-QGEqzZ7VWqs',
          wikiLink: 'https://en.wikipedia.org/wiki/Djibouti',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Djibouti',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-lone-tree-in-the-middle-of-a-rocky-area-4nU4ztBhXmM',
          wikiLink: 'https://en.wikipedia.org/wiki/Djibouti',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Djibouti',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-rocky-landscape-with-a-cloudy-sky-Vph5Trg5XW8',
          wikiLink: 'https://en.wikipedia.org/wiki/Djibouti',
        },
      },
    ],
  },
  {
    name: 'Dominica',
    code: 'DM',
    lat: 15.41666666,
    long: -61.33333333,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/xQmCJOklax4',
          wikiLink: 'https://en.wikipedia.org/wiki/Roseau',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/dominica-roseau-caribbean-holiday-4645317/',
          wikiLink: 'https://en.wikipedia.org/wiki/Dominica',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Grand Bay',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-old-building-with-arches-and-a-clock-on-the-wall-WSBMYT9tXJQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Grand_Bay,_Dominica',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Rosalie Bay Resort',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/ocean-waves-near-forest-p_NYMvnIo4w',
          wikiLink: 'https://en.wikipedia.org/wiki/Rosalie,_Dominica',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Dominica',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-building-with-trees-in-front-of-it-3yJEoZai94o',
          wikiLink: 'https://en.wikipedia.org/wiki/Dominica',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Dominica',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/waterfalls-and-grass-mountain-during-day-vu5Lhk35MRg',
          wikiLink: 'https://en.wikipedia.org/wiki/Dominica',
        },
      },
    ],
  },
  {
    name: 'Dominican Republic',
    code: 'DO',
    lat: 19,
    long: -70.66666666,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/r-YpH_-pd34',
          wikiLink:
            'https://en.wikipedia.org/wiki/Ciudad_Colonial_(Santo_Domingo)',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/dominican-republic-caribbean-sea-321214/',
          wikiLink: 'https://en.wikipedia.org/wiki/Dominican_Republic',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Zona Colonial',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-concrete-buildings-under-white-clouds-during-daytime-r-YpH_-pd34',
          wikiLink:
            'https://en.wikipedia.org/wiki/Ciudad_Colonial_(Santo_Domingo)',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Santo Domingo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-white-fruit-on-black-metal-cart-4l1hEB6netE',
          wikiLink: 'https://en.wikipedia.org/wiki/Santo_Domingo',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Dominican Republic',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-lush-green-hillside-with-a-house-on-top-of-it-dGr89KhNelg',
          wikiLink: 'https://en.wikipedia.org/wiki/Dominican_Republic',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Dominican Republic',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-white-beach-A49CnofKUTo',
          wikiLink: 'https://en.wikipedia.org/wiki/Dominican_Republic',
        },
      },
    ],
  },
  {
    name: 'Ecuador',
    code: 'EC',
    lat: -2,
    long: -77.5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Gz-ZBb6-mrE',
          wikiLink: 'https://en.wikipedia.org/wiki/Marine_iguana',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/lake-wetland-mountains-landscape-6791971/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ecuador',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Cotopaxi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-black-mountain-under-blue-sky-during-daytime--mXlubcOD58',
          wikiLink: 'https://en.wikipedia.org/wiki/Cotopaxi',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Ecuador',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/two-people-walking-up-a-hill-with-a-scarf-F3BBLFml5Hw',
          wikiLink: 'https://en.wikipedia.org/wiki/Ecuador',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Ecuador',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/train-on-railway-between-green-mountains-during-daytime-qP2bR5Ky3JE',
          wikiLink: 'https://en.wikipedia.org/wiki/Ecuador',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Quito',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/53888266@N02/6971569230/in/photolist-bC47Du-bQXMRV-bC459G-bQXLog-bQXPeR-2iYFn5s-2mQwtS3-2iYCBTZ-bC45xo-bQXLyF-bC46RJ-bQXKjP-bC46g7-bQXKs4-bQXKWz-2iYCBLz-5bJ4gT-T857z9-2iYCye9-kdz4BK-2mQAGUB-T8591L-2DZLyU-2mQE1Dm-2mPZEaD-2mQ744Z-2mR3cpR-2mQfP8H-2ksvjjB-2ife5Fa-HryUD4-HrxSxH-212Y4se-22oh6Yi-Hrzyo2-212XXCZ-Hrzqoz-CJLPV8-Hrzzs6-212Y5V4-Hrzt6P-21i21ms-EfwF5d-21i1tU5-HrxZGc-HrzxJB-21i2Y37-21i2Xvf-21i1ukf-HrzsDM',
          wikiLink: 'https://en.wikipedia.org/wiki/Quito',
        },
      },
    ],
  },
  {
    name: 'Egypt',
    code: 'EG',
    lat: 27,
    long: 30,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/pwMbtwA9LRc',
          wikiLink:
            'https://en.wikipedia.org/wiki/Mosque-Madrasa_of_Sultan_Hassan',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/felucca-nile-sunset-flow-sailboat-7237715/',
          wikiLink: 'https://en.wikipedia.org/wiki/Nile',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Cairo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/photo-of-beige-temple-pwMbtwA9LRc',
          wikiLink: 'https://en.wikipedia.org/wiki/Cairo',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Egypt',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-sailboat-on-water-under-blue-cloudy-sky-during-daytime-photo-Si1MFhSLNWY',
          wikiLink: 'https://en.wikipedia.org/wiki/Egypt',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Egypt',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/five-persons-riding-camels-walking-on-sand-beside-pyramid-of-egypt-boyXZfqpwpU',
          wikiLink: 'https://en.wikipedia.org/wiki/Egypt',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Cairo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-pyramids-of-egypt-rxv2qwYPe6s',
          wikiLink: 'https://en.wikipedia.org/wiki/Cairo',
        },
      },
    ],
  },
  {
    name: 'El Salvador',
    code: 'SV',
    lat: 13.83333333,
    long: -88.91666666,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/gLysUycgcF4',
          wikiLink: 'https://en.wikipedia.org/wiki/Plaza_Gerardo_Barrios',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/el-salvador-cerro-el-pimp-moutains-1820097/',
          wikiLink: 'https://en.wikipedia.org/wiki/El_Salvador',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Los Planes de Renderos',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-trees-near-body-of-water-bK-SRSgPWz8',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Salvador',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'La Paz Department',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-near-body-of-water-under-blue-sky-during-daytime-YT4pUNOa5wI',
          wikiLink:
            'https://en.wikipedia.org/wiki/La_Paz_Department_(El_Salvador)',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'El Salvador',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-mountain-range-with-trees-and-mountains-in-the-background-OJa2mbeN4p8',
          wikiLink: 'https://en.wikipedia.org/wiki/El_Salvador',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'San Salvador',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-pedestrian-lane-during-daytime-gLysUycgcF4',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Salvador',
        },
      },
    ],
  },
  {
    name: 'Equatorial Guinea',
    code: 'GQ',
    lat: 2,
    long: 10,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/equatorial_guinea/4641618625',
          wikiLink: 'https://en.wikipedia.org/wiki/Equatorial_Guinea',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/equatorial_guinea/5238701955',
          wikiLink: 'https://en.wikipedia.org/wiki/Malabo',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Bioko Island',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/rjroberts/26168766041',
          wikiLink: 'https://en.wikipedia.org/wiki/Equatorial_Guinea',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'National University of Equatorial Guinea',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/equatorial_guinea/4727255945',
          wikiLink:
            'https://en.wikipedia.org/wiki/National_University_of_Equatorial_Guinea',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Malabo',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/rbcav2003/530802424/',
          wikiLink: 'https://en.wikipedia.org/wiki/Malabo',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Equatorial Guinea',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/emekab/16012940600/',
          wikiLink: 'https://en.wikipedia.org/wiki/Equatorial_Guinea',
        },
      },
    ],
  },
  {
    name: 'Eritrea',
    code: 'ER',
    lat: 15,
    long: 39,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/eritrea-landscape-tents-huts-105081/',
          wikiLink: 'https://en.wikipedia.org/wiki/Eritrea',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/eritrea-mountains-valley-landscape-114822/',
          wikiLink: 'https://en.wikipedia.org/wiki/Eritrea',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Asmara',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/silhouette-of-man-during-sunset-t85pKpB5Zf4',
          wikiLink: 'https://en.wikipedia.org/wiki/Asmara',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Glas',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/danielzolli/28671702110',
          wikiLink: 'https://en.wikipedia.org/wiki/Eritrea',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Eritrea',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/slackadventure/2494677970/',
          wikiLink: 'https://en.wikipedia.org/wiki/Eritrea',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Eritrea',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/slackadventure/2494674744/',
          wikiLink: 'https://en.wikipedia.org/wiki/Eritrea',
        },
      },
    ],
  },
  {
    name: 'Estonia',
    code: 'EE',
    lat: 59,
    long: 26,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/2OSEWkQHiGI',
          wikiLink: 'https://en.wikipedia.org/wiki/Tallinn_Old_Town',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/estonia-tallinn-roofs-architecture-912315/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tallinn',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Kaberneeme',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-near-body-of-water-during-daytime-xgNDOT9F2l8',
          wikiLink: 'https://en.wikipedia.org/wiki/Kaberneeme',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Tallinn',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-street-near-brown-concrete-building-during-nighttime-Xy5yA42GHvQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Tallinn',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Tallinn Old Town',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-narrow-cobblestone-street-in-an-old-european-city-twzONVjsWbw',
          wikiLink: 'https://en.wikipedia.org/wiki/Tallinn_Old_Town',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Estonia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/purple-flower-field-near-road-during-daytime-JDAS7shUy-Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Tallinn',
        },
      },
    ],
  },
  {
    name: 'Eswatini',
    code: 'SZ',
    lat: -26.5,
    long: 31.5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/swaziland-africa-village-swazi-2035749/',
          wikiLink: 'https://en.wikipedia.org/wiki/Eswatini',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/File:Central_Bank_of_Eswatini.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Central_Bank_of_Eswatini',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Mbabane',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-with-a-large-building-_-qgwT7a2WM',
          wikiLink: 'https://en.wikipedia.org/wiki/Mbabane',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Mlilwane Wildlife Sanctuary',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-greenhouses-in-a-field-p3xW6mqxhzs',
          wikiLink: 'https://en.wikipedia.org/wiki/Mlilwane_Wildlife_Sanctuary',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Eswatini',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-lone-tree-in-a-field-with-mountains-in-the-background-tjzLXXCpxdw',
          wikiLink: 'https://en.wikipedia.org/wiki/Eswatini',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Eswatini',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-dirt-road-with-a-hill-in-the-background-7UElMY_jVKE',
          wikiLink: 'https://en.wikipedia.org/wiki/Eswatini',
        },
      },
    ],
  },
  {
    name: 'Ethiopia',
    code: 'ET',
    lat: 8,
    long: 38,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/TgaK_57wRWU',
          wikiLink: 'https://en.wikipedia.org/wiki/Danakil_Desert',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/tricycle-road-hills-wet-road-5700034/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ethiopia',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Ethiopia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/two-people-standing-on-top-of-hill-E0gtyLcD4NE',
          wikiLink: 'https://en.wikipedia.org/wiki/Ethiopia',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Lalibela',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/person-in-white-dress-in-front-of-pink-building-6SgfEwkA02Y',
          wikiLink: 'https://en.wikipedia.org/wiki/Lalibela',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Ethiopia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-herd-of-cattle-walking-down-a-dirt-road-F07E5ak-Im8',
          wikiLink: 'https://en.wikipedia.org/wiki/Ethiopia',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Ethiopia',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/a-city-at-night-hbRzMp8jl6c',
          wikiLink: 'https://en.wikipedia.org/wiki/Ethiopia',
        },
      },
    ],
  },
  {
    name: 'Falkland Islands',
    code: 'FK',
    lat: -51.75,
    long: -59,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/KMLTYWDi0GA',
          wikiLink: 'https://en.wikipedia.org/wiki/Rockhopper_penguin',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cruise-falkland-islands-voyage-4709457/',
          wikiLink: 'https://en.wikipedia.org/wiki/Falkland_Islands',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Falkland Islands',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/ocean-waves-UMC5sfWci78',
          wikiLink: 'https://en.wikipedia.org/wiki/Falkland_Islands',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Falkland Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-on-beach-during-daytime-zNmv3qkEtcE',
          wikiLink: 'https://en.wikipedia.org/wiki/Falkland_Islands',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Falkland Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-grassy-field-with-houses-and-a-body-of-water-in-the-distance-BWq-vP2D_FY',
          wikiLink: 'https://en.wikipedia.org/wiki/Falkland_Islands',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Falkland Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-the-ocean-from-a-rocky-cliff-vmJwkT_YJ4w',
          wikiLink: 'https://en.wikipedia.org/wiki/Falkland_Islands',
        },
      },
    ],
  },
  {
    name: 'Faroe Islands',
    code: 'FO',
    lat: 62,
    long: -7,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/NrP9I1utqug',
          wikiLink: 'https://en.wikipedia.org/wiki/G%C3%A1sadalur',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sea-ocean-rocks-faroe-islands-7233669/',
          wikiLink: 'https://en.wikipedia.org/wiki/Faroe_Islands',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Mykines',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/lighthouse-near-body-of-water-Iay5RmQZo8Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Mykines,_Faroe_Islands',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Faroe Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-house-near-green-mountain-during-daytime-nHUWlHLMLZ8',
          wikiLink: 'https://en.wikipedia.org/wiki/Faroe_Islands',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Faroe Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/calm-body-of-water-and-mountain-1UdiEckjJmM',
          wikiLink: 'https://en.wikipedia.org/wiki/Faroe_Islands',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Gjogv',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/top-view-photography-of-village-UH0-PfiCIaE',
          wikiLink: 'https://en.wikipedia.org/wiki/Gj%C3%B3gv',
        },
      },
    ],
  },
  {
    name: 'Fiji',
    code: 'FJ',
    lat: -18,
    long: 175,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Gg2VVz2ycAc',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuata',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/fiji-south-seas-vacations-island-3839354/',
          wikiLink: 'https://en.wikipedia.org/wiki/Fiji',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Kuata',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-blue-boat-on-ocean-Gg2VVz2ycAc',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuata',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Fiji',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-houses-and-trees-MJ7yhD3kz7o',
          wikiLink: 'https://en.wikipedia.org/wiki/Fiji',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Fiji',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-house-on-a-rocky-island-hoq8NaDGS64',
          wikiLink: 'https://en.wikipedia.org/wiki/Fiji',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Fiji',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/birds-eye-view-of-island-b8RQN7Wtu34',
          wikiLink: 'https://en.wikipedia.org/wiki/Fiji',
        },
      },
    ],
  },
  {
    name: 'Finland',
    code: 'FI',
    lat: 64,
    long: 26,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/I9SWvZ9sO2U',
          wikiLink: 'https://en.wikipedia.org/wiki/Aleksanterinkatu',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/lake-forest-nature-landscape-water-5045059/',
          wikiLink: 'https://en.wikipedia.org/wiki/Finland',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Nuuksio',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/red-and-white-canoe-on-lake-near-green-trees-under-white-clouds-during-daytime-DyDR8oOzuNA',
          wikiLink: 'https://en.wikipedia.org/wiki/Nuuksio',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Jyväskylä',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/sunset-painting-1nBvotYiZQI',
          wikiLink: 'https://en.wikipedia.org/wiki/Jyv%C3%A4skyl%C3%A4',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Lapland',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/houses-near-tree-and-mountain-covered-by-snow-MRX-unYU1dc',
          wikiLink: 'https://en.wikipedia.org/wiki/Lapland_(Finland)',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Finland',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-and-yellow-city-tram-I9SWvZ9sO2U',
          wikiLink: 'https://en.wikipedia.org/wiki/Finland',
        },
      },
    ],
  },
  {
    name: 'France',
    code: 'FR',
    lat: 46,
    long: 2,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/ZtdNFSpugQE',
          wikiLink: 'https://en.wikipedia.org/wiki/%C3%89tretat',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/vineyard-field-rural-burgundy-6399505/',
          wikiLink: 'https://en.wikipedia.org/wiki/Burgundy',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Étretat',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/body-of-water-near-mountain-ZtdNFSpugQE',
          wikiLink: 'https://en.wikipedia.org/wiki/%C3%89tretat',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Bonifacio',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/houses-on-top-of-mountain-SYBLkjltGRo',
          wikiLink: 'https://fr.wikipedia.org/wiki/Bonifacio',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Paris',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-bridge-timelapse-photography-mEfIhOTH27w',
          wikiLink: 'https://en.wikipedia.org/wiki/Paris',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Chamonix',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/snowy-mountain-JVtcrWcbj1c',
          wikiLink: 'https://en.wikipedia.org/wiki/Chamonix',
        },
      },
    ],
  },
  {
    name: 'French Guiana',
    code: 'GF',
    lat: 4,
    long: -53,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sea-shore-orla-cayenne-2060138/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cayenne',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/french-guiana-rainforest-forest-1207208/',
          wikiLink: 'https://en.wikipedia.org/wiki/French_Guiana',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Cayenne',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/44584468@N05/6864847932',
          wikiLink: 'https://en.wikipedia.org/wiki/French_Guiana',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'French Guiana',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/nasawebbtelescope/51584920253',
          wikiLink: 'https://en.wikipedia.org/wiki/French_Guiana',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'French Guiana',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/michaelfowler/31278785236/',
          wikiLink: 'https://en.wikipedia.org/wiki/French_Guiana',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'French Guiana',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/amanderson/16582857709/',
          wikiLink: 'https://en.wikipedia.org/wiki/French_Guiana',
        },
      },
    ],
  },
  {
    name: 'French Polynesia',
    code: 'PF',
    lat: -15,
    long: -140,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/ru_MKgg0sws',
          wikiLink: 'https://en.wikipedia.org/wiki/Mo%27orea',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/moorea-french-polynesia-tropical-680033/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tahiti',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Bora-Bora',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-houses-on-water-near-green-mountain-under-blue-sky-during-daytime-tF0izq7URoo',
          wikiLink: 'https://en.wikipedia.org/wiki/Bora_Bora',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Tahiti',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/yellow-bus-diRXmdG0wkc',
          wikiLink: 'https://en.wikipedia.org/wiki/Tahiti',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Moorea',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-tropical-island-with-palm-trees-5SOTm1RlCmg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mo%CA%BBorea',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Bora-Bora',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-metal-railing-and-huts-near-body-of-water-oDcbtrD3Rrc',
          wikiLink: 'https://en.wikipedia.org/wiki/Bora_Bora',
        },
      },
    ],
  },
  {
    name: 'Gabon',
    code: 'GA',
    lat: -1,
    long: 11.75,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/75Q_rXHFLXs',
          wikiLink: 'https://en.wikipedia.org/wiki/Gabon',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/africa-gabon-people-travels-379378/',
          wikiLink: 'https://en.wikipedia.org/wiki/Gabon',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Lastoursville',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-statue-near-green-trees-under-blue-sky-during-daytime-Bma9U3Vx1lw',
          wikiLink: 'https://en.wikipedia.org/wiki/Lastoursville',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Lope',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-fence-near-green-mountain-under-blue-sky-during-daytime-75Q_rXHFLXs',
          wikiLink: 'https://en.wikipedia.org/wiki/Ogoou%C3%A9-Ivindo_Province',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Gabon',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-lake-surrounded-by-trees-hiPUsUsq8BM',
          wikiLink: 'https://en.wikipedia.org/wiki/Gabon',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Gabon',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/rock-formation-on-seashore-NEGV5U_UZio',
          wikiLink: 'https://en.wikipedia.org/wiki/Gabon',
        },
      },
    ],
  },
  {
    name: 'Gambia',
    code: 'GM',
    lat: 13.46666666,
    long: -16.56666666,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/GygPFmXGD1o',
          wikiLink: 'https://en.wikipedia.org/wiki/Gambia_River',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/river-scene-gambia-fishing-village-2667905/',
          wikiLink: 'https://en.wikipedia.org/wiki/The_Gambia',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Gambia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-blue-boat-on-water-near-houses-during-daytime-uOgB_xCBT4M',
          wikiLink: 'https://en.wikipedia.org/wiki/The_Gambia',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Gambia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photography-of-mountain-GygPFmXGD1o',
          wikiLink: 'https://en.wikipedia.org/wiki/The_Gambia',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Gambia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/beach-near-houses-ELHzK-UFabY',
          wikiLink: 'https://en.wikipedia.org/wiki/The_Gambia',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Kanilai',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-standing-around-a-herd-of-animals-6vIOlt3ijoA',
          wikiLink: 'https://en.wikipedia.org/wiki/Kanilai',
        },
      },
    ],
  },
  {
    name: 'Georgia',
    code: 'GE',
    lat: 42,
    long: 43.5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/5iQWgow3_S0',
          wikiLink: 'https://en.wikipedia.org/wiki/Gergeti_Trinity_Church',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/caucasus-mountains-georgia-country-3110386/',
          wikiLink: 'https://en.wikipedia.org/wiki/Georgia_(country)',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Georgia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/person-paragliding-on-mountain-cliff-during-daytime-Edx0NpJ29fQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Georgia_(country)',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Tbilisi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-building-under-cloudy-sky-during-daytime-fp-f0-reO7U',
          wikiLink: 'https://en.wikipedia.org/wiki/Tbilisi',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Tbilisi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photo-of-houses-b-eGDk5_gPo',
          wikiLink: 'https://en.wikipedia.org/wiki/Tbilisi',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Ushguli',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-houses-beside-rocky-mountains-during-daytime-DOhXyRS3_n0',
          wikiLink: 'https://en.wikipedia.org/wiki/Ushguli',
        },
      },
    ],
  },
  {
    name: 'Germany',
    code: 'DE',
    lat: 51,
    long: 9,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/QICQFkZp0Aw',
          wikiLink: 'https://en.wikipedia.org/wiki/Neuschwanstein_Castle',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bastei-bridge-rock-formation-3014467/',
          wikiLink: 'https://en.wikipedia.org/wiki/Elbe_Sandstone_Mountains',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Rothenburg ob der Tauber',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-pink-petaled-flowers-on-metal-fence-near-concrete-houses-and-tower-at-daytime-g_gwdpsCVAY',
          wikiLink: 'https://en.wikipedia.org/wiki/Rothenburg_ob_der_Tauber',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Kochel',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-on-mountain-under-white-clouds-during-daytime-XOVv1zbD-1U',
          wikiLink: 'https://en.wikipedia.org/wiki/Kochel',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Germany',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-highway-with-a-lot-of-traffic-rmeGDP2mAis',
          wikiLink: 'https://en.wikipedia.org/wiki/Germany',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Hamburg',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/train-running-on-rail-near-buildings-during-daytime-AWyj7_t8pj0',
          wikiLink: 'https://en.wikipedia.org/wiki/Hamburg',
        },
      },
    ],
  },
  {
    name: 'Ghana',
    code: 'GH',
    lat: 8,
    long: -2,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/a0BGODzhmqg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ada_Foah',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/ghana-west-africa-africa-village-1927853/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ghana',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Ada',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/red-boat-on-body-of-water-near-green-palm-trees-during-daytime-zCAh6tOWFt8',
          wikiLink: 'https://en.wikipedia.org/wiki/Ghana',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Ghana',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-standing-on-boulder-QqMy7OBBJjE',
          wikiLink: 'https://en.wikipedia.org/wiki/Ghana',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Aburi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-house-with-a-dirt-path-in-front-of-it-AcAnos3euXM',
          wikiLink: 'https://en.wikipedia.org/wiki/Aburi#',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Volta',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-chair-on-seashore-during-daytime-9g_rafKQKpY',
          wikiLink: 'https://en.wikipedia.org/wiki/Volta_Region',
        },
      },
    ],
  },
  {
    name: 'Gibraltar',
    code: 'GI',
    lat: 36.13333333,
    long: -5.35,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/dVoNU7j3o_0',
          wikiLink: 'https://en.wikipedia.org/wiki/Ibrahim-al-Ibrahim_Mosque',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/gibraltar-mediterranean-sea-horn-50863/',
          wikiLink: 'https://en.wikipedia.org/wiki/Gibraltar',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'The Rock',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/moutanin-and-city-scenery-Fz3vBE8XoHc',
          wikiLink: 'https://en.wikipedia.org/wiki/Rock_of_Gibraltar',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Gibraltar',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/pink-flowers-across-white-and-red-lighthouse-during-daytime-T5ePvSRL5Hg',
          wikiLink: 'https://en.wikipedia.org/wiki/Gibraltar',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Gibraltar',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-city-skyline-by-the-sea-during-daytime-lJkO8GGrIro',
          wikiLink: 'https://en.wikipedia.org/wiki/Gibraltar',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Gibraltar',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cliff-beside-seashore-RjJiCLfMzLU',
          wikiLink: 'https://en.wikipedia.org/wiki/Gibraltar',
        },
      },
    ],
  },
  {
    name: 'Greece',
    code: 'GR',
    lat: 39,
    long: 22,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/x-HCoTDGgZw',
          wikiLink: 'https://en.wikipedia.org/wiki/Parga',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/santorini-greece-buildings-houses-416135/',
          wikiLink: 'https://en.wikipedia.org/wiki/Santorini',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Santorini',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/santorini-greece-_vA2q0-NroU',
          wikiLink: 'https://en.wikipedia.org/wiki/Santorini',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Zakynthos',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/rocky-and-grass-mountain-island-during-day-bFdC-dVtFT8',
          wikiLink: 'https://en.wikipedia.org/wiki/Zakynthos',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Rhodes',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-buildings-on-mountain-by-the-sea-during-daytime-uInLh1mVAWg',
          wikiLink: 'https://en.wikipedia.org/wiki/Rhodes',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Oia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/buildings-near-ocean-dM_e5u-SM0U',
          wikiLink: 'https://en.wikipedia.org/wiki/Oia,_Greece',
        },
      },
    ],
  },
  {
    name: 'Greenland',
    code: 'GL',
    lat: 72,
    long: -40,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/EM_G_WFK9z4',
          wikiLink: 'https://en.wikipedia.org/wiki/Aasiaat',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mountains-snow-glacier-sea-arctic-5559241/',
          wikiLink: 'https://en.wikipedia.org/wiki/Greenland',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Aasiaat',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/view-photography-of-assorted-color-houses-near-pond-during-daytime-EM_G_WFK9z4',
          wikiLink: 'https://en.wikipedia.org/wiki/Aasiaat',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Ittoqqortoormiit',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-blue-wooden-houses-v7DzvSEcNks',
          wikiLink: 'https://en.wikipedia.org/wiki/Ittoqqortoormiit',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Paamiut',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photo-of-houses--Yfj5PLaFk8',
          wikiLink: 'https://en.wikipedia.org/wiki/Paamiut',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Greenland',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-tent-pitched-up-on-top-of-a-mountain-9wgSViip524',
          wikiLink: 'https://en.wikipedia.org/wiki/Greenland',
        },
      },
    ],
  },
  {
    name: 'Grenada',
    code: 'GD',
    lat: 12.11666666,
    long: -61.66666666,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/c9yXt2dL1JI',
          wikiLink: 'https://en.wikipedia.org/wiki/St._George%27s,_Grenada',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/caribbean-island-tropical-harbor-1934661/',
          wikiLink: 'https://en.wikipedia.org/wiki/Grenada',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Saint George',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/body-of-water-near-city-buildings-under-blue-and-white-sunny-cloudy-sky-during-daytime-0pBWK1yGweU',
          wikiLink: 'https://en.wikipedia.org/wiki/St._George%27s,_Grenada',
        },
      },
      {
        number: 4,
        mainImage: {
          name: "St George's",
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-cars-parked-in-front-of-a-building-Q5ax4B2CgAM',
          wikiLink: 'https://en.wikipedia.org/wiki/St._George%27s,_Grenada',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Grenada',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-small-town-by-the-ocean-NntB-p1ov8k',
          wikiLink: 'https://en.wikipedia.org/wiki/Grenada',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Grenada',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-next-to-a-body-of-water-lHXfXXvh0lw',
          wikiLink: 'https://en.wikipedia.org/wiki/Grenada',
        },
      },
    ],
  },
  {
    name: 'Guadeloupe',
    code: 'GP',
    lat: 16.25,
    long: -61.583333,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/y32dchsQ-Gk',
          wikiLink: 'https://en.wikipedia.org/wiki/Terre-de-Haut_Island',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/caribbean-sea-sand-island-holiday-1656604/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guadeloupe',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Guadeloupe',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-island-with-coconut-trees-under-gray-skies-im43wgQ_81g',
          wikiLink: 'https://en.wikipedia.org/wiki/Guadeloupe',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Guadeloupe',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/boats-near-island-during-day-3NCI3Ld2uXc',
          wikiLink: 'https://en.wikipedia.org/wiki/Guadeloupe',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Chameau ',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-green-trees-and-body-of-water-during-daytime-y32dchsQ-Gk',
          wikiLink: 'https://fr.wikipedia.org/wiki/Chameau_(Guadeloupe)',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Guadeloupe',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/galvanize-iron-house-WW9DVUmBbIA',
          wikiLink: 'https://en.wikipedia.org/wiki/Guadeloupe',
        },
      },
    ],
  },
  {
    name: 'Guam',
    code: 'GU',
    lat: 13.46666666,
    long: 144.7833333,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/guam-landscape-scenic-bay-harbor-107281/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guam',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/guam-sea-ocean-water-rocks-rocky-102374/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guam',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Guam',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-rock-formation-on-body-of-water-under-blue-sky-during-daytime-Y6Pl62E6lrU',
          wikiLink: 'https://en.wikipedia.org/wiki/Guam',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Guam',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-in-black-shirt-sitting-on-white-sand-near-palm-tree-during-daytime-bjKoa34Iv_I',
          wikiLink: 'https://en.wikipedia.org/wiki/Guam',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Guam',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-traffic-light-sitting-on-the-side-of-a-road-GGnr9_IQx9k',
          wikiLink: 'https://en.wikipedia.org/wiki/Guam',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Guam',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-body-of-water-surrounded-by-lush-green-hills-ZM34LGY-LXQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Guam',
        },
      },
    ],
  },
  {
    name: 'Guatemala',
    code: 'GT',
    lat: 15.5,
    long: -90.25,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/antigua-guatemala-sunrise-guatemala-2652478/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guatemala',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/N4gc_5rZ9dM',
          wikiLink: 'https://en.wikipedia.org/wiki/Tikal_Temple_I',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Guatemala',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-mountains-under-white-clouds-and-blue-sky-during-daytime-_T7cYIPPu9w',
          wikiLink: 'https://en.wikipedia.org/wiki/Guatemala',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Guatemala',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-building-near-mountain--97MyV1TOJk',
          wikiLink: 'https://en.wikipedia.org/wiki/Guatemala',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Quetzaltenango',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-sitting-on-the-side-of-a-road-aOSLrM6doeU',
          wikiLink: 'https://en.wikipedia.org/wiki/Quetzaltenangoo',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'San Juan La Laguna',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-narrow-street-with-umbrellas-hanging-from-the-ceiling-xqvkvj37EcY',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Juan_La_Laguna',
        },
      },
    ],
  },
  {
    name: 'Guernsey',
    code: 'GG',
    lat: 49.46666666,
    long: -2.58333333,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/N5ZHXIdy-io',
          wikiLink: 'https://en.wikipedia.org/wiki/Guernsey',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/guernsey-landscape-dusk-2722964/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guernsey',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Guernsey',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-concrete-buildings-near-body-of-water-during-daytime-N5ZHXIdy-io',
          wikiLink: 'https://en.wikipedia.org/wiki/Guernsey',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'La Forêt',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/watch-tower-on-island-under-blue-skies-xy9Nc9DX2gw',
          wikiLink: 'https://en.wikipedia.org/wiki/Forest,_Guernsey',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Guernsey',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-walking-on-a-street-between-buildings-_T0omPc-qzQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Guernsey',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Guernsey',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/white-boats-xH2EwrttERY',
          wikiLink: 'https://en.wikipedia.org/wiki/Guernsey',
        },
      },
    ],
  },
  {
    name: 'Guinea',
    code: 'GN',
    lat: 11,
    long: -10,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Guinea#/media/File:Fouta_Djallon_(14582291826).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Guinea',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/woods-fog-guinea-mist-africa-2357567/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guinea',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Kindia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-green-grass-field-FjE-FyxDmlw',
          wikiLink: 'https://en.wikipedia.org/wiki/Kindia',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Conakry',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-concrete-building-near-body-of-water-during-daytime-5Hy1HNPJjcU',
          wikiLink: 'https://en.wikipedia.org/wiki/Conakry',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Kaloum',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-trees-and-beach-wVoZjM8ZYPs',
          wikiLink: 'https://en.wikipedia.org/wiki/Kaloum',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Guinea',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/ovong/48799992051/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guinea',
        },
      },
    ],
  },
  {
    name: 'Guinea-Bissau',
    code: 'GW',
    lat: 12,
    long: -15,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/village-africa-guinea-bissau-431731/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guinea-Bissau',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/boat-island-sea-costa-ocean-water-434919/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guinea-Bissau',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Bissau',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-riding-motorcycle-on-road-during-daytime-h781CadBmuQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Guinea-Bissau',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Bissau',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/jbdodane/9102589195',
          wikiLink: 'https://en.wikipedia.org/wiki/Guinea-Bissau',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Guinea-Bissau',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/up70mm/53849161706/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guinea-Bissau',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Bafatá',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/brettnbutter/33200073206/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bafat%C3%A1',
        },
      },
    ],
  },
  {
    name: 'Guyana',
    code: 'GY',
    lat: 5,
    long: -59,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/pZyD9-XReQQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Georgetown,_Guyana',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/guyana-sky-clouds-lake-stream-80836/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guyana',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Guyana',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-concrete-bridge-over-river-during-daytime-kvOJU3tGexs',
          wikiLink: 'https://en.wikipedia.org/wiki/Guyana',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Guyana',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-concrete-house-ez9RfX6DLUk',
          wikiLink: 'https://en.wikipedia.org/wiki/Guyana',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Guyana',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-near-white-concrete-building-during-daytime-pZyD9-XReQQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Guyana',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Guyana',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-grass-field-1TnaUrrcm88',
          wikiLink: 'https://en.wikipedia.org/wiki/Guyana',
        },
      },
    ],
  },
  {
    name: 'Haiti',
    code: 'HT',
    lat: 19,
    long: -72.41666666,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/HlseIzr_6fI',
          wikiLink: 'https://en.wikipedia.org/wiki/Cap-Ha%C3%AFtien',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/haiti-mountains-landscape-steep-79646/',
          wikiLink: 'https://en.wikipedia.org/wiki/Haiti',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Port-au-Prince',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-concrete-houses-near-green-trees-during-daytime-P8ZZ0aofrXI',
          wikiLink: 'https://en.wikipedia.org/wiki/Port-au-Prince',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Jacmel Bay',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-under-white-clouds-during-daytime-1kCf62phlgU',
          wikiLink: 'https://en.wikipedia.org/wiki/Jacmel',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Haiti',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-rocky-beach-with-trees-and-a-body-of-water-KOLEBgHxzcI',
          wikiLink: 'https://en.wikipedia.org/wiki/Haiti',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Montrouis',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-near-body-of-water-under-cloudy-sky-during-daytime-apS0gdKWG7E',
          wikiLink: 'https://en.wikipedia.org/wiki/Montrouis',
        },
      },
    ],
  },
  {
    name: 'Heard Island and McDonald Islands',
    code: 'HM',
    lat: -53.08181,
    long: 73.504158,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Heard_Island_%28cropped%29.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Heard_Island_and_McDonald_Islands',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Heard_Island_by_Sentinel-2_Cloudless.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Heard_Island_and_McDonald_Islands',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Heard Island and McDonald Islands',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Heard_Island_and_McDonald_Islands#/media/File:Heard_Island_(cropped).jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Heard_Island_and_McDonald_Islands',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Heard Island and McDonald Islands',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Heard_Island_and_McDonald_Islands#/media/File:Heard_Island.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Heard_Island_and_McDonald_Islands',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Heard Island and McDonald Islands',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/29261553@N08/32257973995/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Heard_Island_and_McDonald_Islands',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Heard Island and McDonald Islands',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/peche-pays-basque/4374498324/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Heard_Island_and_McDonald_Islands',
        },
      },
    ],
  },
  {
    name: 'Honduras',
    code: 'HN',
    lat: 15,
    long: -86.5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/WFFmZ0qIUYU',
          wikiLink:
            'https://en.wikipedia.org/wiki/St._Peter_the_Apostle_Cathedral,_San_Pedro_Sula',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/roatan-honduras-port-architecture-1358175/',
          wikiLink: 'https://en.wikipedia.org/wiki/Honduras',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Santa Cruz de Yojoa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-mountains-and-river-under-white-clouds-during-daytime-IbNsWIgGyIY',
          wikiLink: 'https://en.wikipedia.org/wiki/Santa_Cruz_de_Yojoa',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Honduras',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/group-of-people-at-market-h2kR-y1xUek',
          wikiLink: 'https://en.wikipedia.org/wiki/Honduras',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Utila',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-small-island-in-the-middle-of-the-ocean-yCwiR0wxxoU',
          wikiLink: 'https://en.wikipedia.org/wiki/Utila',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Utila',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-row-of-three-wheelers-parked-next-to-each-other-on-a-pier-d_X-Usz596Y',
          wikiLink: 'https://en.wikipedia.org/wiki/Utila',
        },
      },
    ],
  },
  {
    name: 'Hong Kong',
    code: 'HK',
    lat: 22.267,
    long: 114.188,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/WMSvsWzhM0g',
          wikiLink: 'https://en.wikipedia.org/wiki/Hong_Kong',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/the-peak-hong-kong-scenic-romantic-243269/',
          wikiLink: 'https://en.wikipedia.org/wiki/Hong_Kong',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Hong Kong',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-during-day-time-kCABKZBt4Gk',
          wikiLink: 'https://en.wikipedia.org/wiki/Hong_Kong',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Hong Kong',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-side-of-road-DdQkVaQbSsg',
          wikiLink: 'https://en.wikipedia.org/wiki/Hong_Kong',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Hong Kong',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-near-water-fountain-33IMGbXx7-4',
          wikiLink: 'https://en.wikipedia.org/wiki/Hong_Kong',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Hong Kong',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-cityscape-qpWRtUL6NQ4',
          wikiLink: 'https://en.wikipedia.org/wiki/Hong_Kong',
        },
      },
    ],
  },
  {
    name: 'Hungary',
    code: 'HU',
    lat: 47,
    long: 20,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/qnUg1rdnUlk',
          wikiLink:
            'https://en.wikipedia.org/wiki/Hungarian_Parliament_Building',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/szechenyi-chain-bridge-1758196/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sz%C3%A9chenyi_Chain_Bridge',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Budapest',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-sidewalk-near-white-concrete-building-during-daytime-iVhIhU5SH6w',
          wikiLink: 'https://en.wikipedia.org/wiki/Budapest',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Budapest',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/yellow-and-white-train-on-rail-tracks-0K4Fo6xZz4Y',
          wikiLink: 'https://en.wikipedia.org/wiki/Budapest',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Budapest',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photo-of-bridge-with-lights-kDP_lvDzh2c',
          wikiLink: 'https://en.wikipedia.org/wiki/Budapest',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Budapest',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-yellow-and-white-train-traveling-down-train-tracks-3kx_20rKFQM',
          wikiLink: 'https://en.wikipedia.org/wiki/Budapest',
        },
      },
    ],
  },
  {
    name: 'Iceland',
    code: 'IS',
    lat: 65,
    long: -18,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/4ThcC1DtspE',
          wikiLink: 'https://unsplash.com/photos/4ThcC1DtspE',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/seljalandsfoss-waterfalls-iceland-1751463/',
          wikiLink: 'https://en.wikipedia.org/wiki/Seljalandsfoss',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Iceland',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/black-sand-near-body-of-water-under-the-cloudy-sky-during-daytime-MLKrf51NV8w',
          wikiLink: 'https://en.wikipedia.org/wiki/Iceland',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Iceland',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-standing-on-the-edge-of-a-cliff-f3uWi9G-lus',
          wikiLink: 'https://en.wikipedia.org/wiki/Iceland',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Gullfoss',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-water-falls-at-daytime-n1nDUvnffMc',
          wikiLink: 'https://en.wikipedia.org/wiki/Gullfoss',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Iceland',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/photography-of-five-assorted-color-horses-on-grass-field-in-front-of-mountain-diMBLU4FzDQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Iceland',
        },
      },
    ],
  },
  {
    name: 'India',
    code: 'IN',
    lat: 20,
    long: 77,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/nE2gf1scItI',
          wikiLink: 'https://en.wikipedia.org/wiki/Victoria_Memorial,_Kolkata',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/india-varinasia-ganges-boats-1309206/',
          wikiLink: 'https://en.wikipedia.org/wiki/India',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Kolkata',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-concrete-castle-near-body-of-water-nE2gf1scItI',
          wikiLink: 'https://en.wikipedia.org/wiki/Kolkata',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Alappuzha',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-riding-on-boat-during-daytime-MvkLKGtBcUA',
          wikiLink: 'https://en.wikipedia.org/wiki/Alappuzha',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Tamil Nadu',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-near-mountain-during-daytime-E5egsk4eUQ0',
          wikiLink: 'https://en.wikipedia.org/wiki/Tamil_Nadu',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Agra',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/group-of-people-in-temple-at-daytime-s34iTpkwnF0',
          wikiLink: 'https://en.wikipedia.org/wiki/Agra',
        },
      },
    ],
  },
  {
    name: 'Indonesia',
    code: 'ID',
    lat: -5,
    long: 120,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/8o4W9LZv6eo',
          wikiLink: 'https://en.wikipedia.org/wiki/Kebumen_Regency',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sea-mangrove-before-sunrise-mystery-6328687/',
          wikiLink: 'https://en.wikipedia.org/wiki/Halmahera',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Kebumen',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/three-person-riding-bikes-on-green-grass-field-8o4W9LZv6eo',
          wikiLink: 'https://en.wikipedia.org/wiki/Kebumen_Regency',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Padar Island',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/woman-standing-on-mountain-near-body-of-water-Sq8rpq2KB7U',
          wikiLink: 'https://en.wikipedia.org/wiki/Padar,_Indonesia',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Indonesia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photo-of-coconut-trees-EFuCATqfblI',
          wikiLink: 'https://en.wikipedia.org/wiki/Indonesia',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Padar Island',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/birds-eye-view-of-body-of-water-and-island-m7eb02LR9eA',
          wikiLink: 'https://en.wikipedia.org/wiki/Padar,_Indonesia',
        },
      },
    ],
  },
  {
    name: 'Iran',
    code: 'IR',
    lat: 32,
    long: 53,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/mbiddulph/31233739431/',
          wikiLink: 'https://en.wikipedia.org/wiki/Nasir-ol-Molk_Mosque',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mountain-summit-snow-winter-6734031/',
          wikiLink: 'https://en.wikipedia.org/wiki/Iran',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Tehran',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-buildings-during-daytime-rG41gpS2FKI',
          wikiLink: 'https://en.wikipedia.org/wiki/Tehran',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Abyaneh',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/woman-sitting-on-brown-concrete-chair-sjFWv9FnU2Y',
          wikiLink: 'https://en.wikipedia.org/wiki/Abyaneh',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Tehran',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/multicolored-lion-art-on-buildings-wall-at-daytime-TxBeyjstOSg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tehran',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Tehran',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-buildings-during-daytime-wn6jWSxWDLI',
          wikiLink: 'https://en.wikipedia.org/wiki/Tehran',
        },
      },
    ],
  },
  {
    name: 'Iraq',
    code: 'IQ',
    lat: 33,
    long: 44,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/8eJTLS3lIiw',
          wikiLink: 'https://en.wikipedia.org/wiki/Erbil',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/hillah-iraq-landscape-desert-80384/',
          wikiLink: 'https://en.wikipedia.org/wiki/Iraq',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Iraq',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-street-during-daytime-ysMnsNEf670',
          wikiLink: 'https://en.wikipedia.org/wiki/Iraq',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Iraq',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-green-trees-and-mountains-during-daytime-Cl0Zn8Jt2BE',
          wikiLink: 'https://en.wikipedia.org/wiki/Iraq',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Samawah',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-and-trees-near-river-during-daytime-sn00xBZqYaw',
          wikiLink: 'https://en.wikipedia.org/wiki/Samawah',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Karbala',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/the-sun-is-setting-over-a-city-with-tall-buildings-eHUkCuolVX4',
          wikiLink: 'https://en.wikipedia.org/wiki/Karbala',
        },
      },
    ],
  },
  {
    name: 'Ireland',
    code: 'IE',
    lat: 53,
    long: -8,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/FxMR4Dp9rl0',
          wikiLink: 'https://en.wikipedia.org/wiki/Glendalough_Upper_Lake',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/beach-lonely-hike-ireland-sea-5043569/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ireland',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Cliffs of Moher',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/cliff-near-sea-ci9HzWTIVas',
          wikiLink: 'https://en.wikipedia.org/wiki/Cliffs_of_Moher',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Dingle',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-red-lighthouse-near-green-grass-field-under-blue-sky-during-daytime-M87gaY34Ac4',
          wikiLink: 'https://en.wikipedia.org/wiki/Dingle',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Fintown',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-house-beside-fence-and-tree-during-daytime-BUkgxEF2q7c',
          wikiLink: 'https://en.wikipedia.org/wiki/Fintown',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Ireland',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photography-of-green-leafed-trees-8X19catOuNI',
          wikiLink: 'https://en.wikipedia.org/wiki/Ireland',
        },
      },
    ],
  },
  {
    name: 'Isle of Man',
    code: 'IM',
    lat: 54.25,
    long: -4.5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/UF-F4mSV8VA',
          wikiLink: 'https://en.wikipedia.org/wiki/Bradda_Head',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sunrise-snow-mountains-sunlight-5882167/',
          wikiLink: 'https://en.wikipedia.org/wiki/Isle_of_Man',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Isle of Man',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-and-brown-rock-formation-near-body-of-water-during-daytime-SFHB4DPU134',
          wikiLink: 'https://en.wikipedia.org/wiki/Isle_of_Man',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Douglas',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-light-house-sitting-on-top-of-a-lush-green-hillside-8FNdV_g7MXI',
          wikiLink: 'https://en.wikipedia.org/wiki/Douglas,_Isle_of_Man',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Peel',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/anchor-on-grass-shore-during-day-fXzcEptNAmQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Peel,_Isle_of_Man',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Isle of Man',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-bridge-during-daytime-_t-8ters_eg',
          wikiLink: 'https://en.wikipedia.org/wiki/Isle_of_Man',
        },
      },
    ],
  },
  {
    name: 'Israel',
    code: 'IL',
    lat: 31.47,
    long: 35.13,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/yBJuiaMHlsk',
          wikiLink: 'https://en.wikipedia.org/wiki/Tel_Aviv',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/israel-jerusalem-jewish-palestine-4426385/',
          wikiLink: 'https://en.wikipedia.org/wiki/Jerusalem',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Masada',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-rock-formation-during-sunset-Nh8bXxdP0DI',
          wikiLink: 'https://en.wikipedia.org/wiki/Masada',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Israel',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/yellow-petaled-flower-field-at-daytime-g_Abgn1slAM',
          wikiLink: 'https://en.wikipedia.org/wiki/Israel',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Israel',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-rock-formation-near-sea-at-daytime-tArR7ys2F3Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Israel',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Jerusalem',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-veiw-photography-of-dome-of-the-rock-jerusalem-QsU8GRwyS3k',
          wikiLink: 'https://en.wikipedia.org/wiki/Jerusalem',
        },
      },
    ],
  },
  {
    name: 'Italy',
    code: 'IT',
    lat: 42.83333333,
    long: 12.83333333,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/r9RW20TrQ0Y',
          wikiLink: 'https://en.wikipedia.org/wiki/Tuscany',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/village-sea-coast-coastline-279013/',
          wikiLink: 'https://en.wikipedia.org/wiki/Liguria',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Portofino',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-house-near-sea-during-daytime-EZT6qusWOBQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Portofino',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Italy',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/road-between-green-grass-field-near-mountains-under-blue-and-brown-sky-at-golden-hour-r9RW20TrQ0Y',
          wikiLink: 'https://en.wikipedia.org/wiki/Italy',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Venice',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/venice-grand-canal-italy-1naE8177_bI',
          wikiLink: 'https://en.wikipedia.org/wiki/Venice',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Tuscany',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/house-in-middle-of-grass-field-M0OIyN5u8ZM',
          wikiLink: 'https://en.wikipedia.org/wiki/Tuscany',
        },
      },
    ],
  },
  {
    name: 'Ivory Coast',
    code: 'CI',
    lat: 8,
    long: -5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/africa-ivory-coast-lakes-6289446/',
          wikiLink: 'https://en.wikipedia.org/wiki/Yamoussoukro',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/children-african-portrait-youth-5445501/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ivory_Coast',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Ivory Coast',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-van-at-road-during-daytime-NpDn3goZGRs',
          wikiLink: 'https://en.wikipedia.org/wiki/Ivory_Coast',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Ivory Coast',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-in-red-polo-shirt-holding-yellow-banana-bf7m8Et8ZW8',
          wikiLink: 'https://en.wikipedia.org/wiki/Ivory_Coast',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Ivory Coast',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/abqbiopark/49358662271/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ivory_Coast',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Ivory Coast',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/johnveerkamp/50233577818/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ivory_Coast',
        },
      },
    ],
  },
  {
    name: 'Jamaica',
    code: 'JM',
    lat: 18.25,
    long: -77.5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/wGyHvtLcW_U',
          wikiLink: 'https://en.wikipedia.org/wiki/Jamaica',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/jamaica-negril-beach-boat-2409533/',
          wikiLink: 'https://en.wikipedia.org/wiki/Jamaica',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Jamaica',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-blue-bus-near-palm-tree-under-blue-sky-during-daytime-wGyHvtLcW_U',
          wikiLink: 'https://en.wikipedia.org/wiki/Jamaica',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Jamaica',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-on-beach-during-sunset-K6ig8dtGqPU',
          wikiLink: 'https://en.wikipedia.org/wiki/Jamaica',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Negril',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-in-black-shirt-riding-bicycle-near-palm-tree-during-daytime-gksePWvWcWc',
          wikiLink: 'https://en.wikipedia.org/wiki/Negril',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Jamaica',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/boats-docked-beside-trees-on-river-jIk3I_e16uw',
          wikiLink: 'https://en.wikipedia.org/wiki/Jamaica',
        },
      },
    ],
  },
  {
    name: 'Japan',
    code: 'JP',
    lat: 36,
    long: 138,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/8sOZJ8JF0S8',
          wikiLink: 'https://en.wikipedia.org/wiki/Meguro_River',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/rice-terraces-sunset-mountains-2389023/',
          wikiLink: 'https://en.wikipedia.org/wiki/Japan',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Tokyo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-road-near-well-lit-buildings-layMbSJ3YOE',
          wikiLink: 'https://en.wikipedia.org/wiki/Tokyo',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Tokyo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/boats-on-body-of-water-824OwkP7sgk',
          wikiLink: 'https://en.wikipedia.org/wiki/Tokyo',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Japan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/canal-between-cherry-blossom-trees-8sOZJ8JF0S8',
          wikiLink: 'https://en.wikipedia.org/wiki/Japan',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Kyoto',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/pagoda-surrounded-by-trees-E_eWwM29wfU',
          wikiLink: 'https://en.wikipedia.org/wiki/Kyoto',
        },
      },
    ],
  },
  {
    name: 'Jersey',
    code: 'JE',
    lat: 49.25,
    long: -2.16666666,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/9gWighoa5kA',
          wikiLink: 'https://en.wikipedia.org/wiki/Mont_Orgueil',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/gorey-jersey-harbour-castle-island-1035943/',
          wikiLink: 'https://en.wikipedia.org/wiki/Jersey',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Jersey',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/brown-building-9gWighoa5kA',
          wikiLink: 'https://en.wikipedia.org/wiki/Jersey',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Jersey',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-dock-on-blue-sea-under-blue-sky-during-daytime-S0MBtiMoi9I',
          wikiLink: 'https://en.wikipedia.org/wiki/Jersey',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Jersey',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/body-of-water-during-sunset-FuqVWStF2Qw',
          wikiLink: 'https://en.wikipedia.org/wiki/Jersey',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Jersey',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-standing-on-top-of-a-sandy-beach-b2_xvOxjDdo',
          wikiLink: 'https://en.wikipedia.org/wiki/Jersey',
        },
      },
    ],
  },
  {
    name: 'Jordan',
    code: 'JO',
    lat: 31,
    long: 36,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/eoZTnFtusDs',
          wikiLink: 'https://en.wikipedia.org/wiki/Petra',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/petra-ad-deir-world-heritage-6294051/',
          wikiLink: 'https://en.wikipedia.org/wiki/Jordan',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Petra',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-white-concrete-building-on-brown-rocky-mountain-during-daytime-5_Bu25SV6X8',
          wikiLink: 'https://en.wikipedia.org/wiki/Petra',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Jordan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-concrete-stadium-during-daytime-zrUAX7Qbg-g',
          wikiLink: 'https://en.wikipedia.org/wiki/Jordan',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Jordan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photography-of-mountains-and-sky--EJEaytR9fw',
          wikiLink: 'https://en.wikipedia.org/wiki/Jordan',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Jordan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-empty-road-surrounded-by-trees-on-a-hillside-jFwKauZD4sw',
          wikiLink: 'https://en.wikipedia.org/wiki/Jordan',
        },
      },
    ],
  },
  {
    name: 'Kazakhstan',
    code: 'KZ',
    lat: 48,
    long: 68,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/YtYdNGabzFo',
          wikiLink: 'https://en.wikipedia.org/wiki/Almaty_Region',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/canyon-mountains-river-kazakhstan-1740973/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kazakhstan',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Almaty Lake',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/lake-surrounded-with-mountains-during-daytime-ToJNyWpDn9I',
          wikiLink: 'https://en.wikipedia.org/wiki/Big_Almaty_Lake',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Kazakhstan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/person-riding-horse-dL-NYHFuwJI',
          wikiLink: 'https://en.wikipedia.org/wiki/Kazakhstan',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Burabay',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-rock-with-trees-and-mountains-in-the-background-zS08OeVWgoE',
          wikiLink: 'https://en.wikipedia.org/wiki/Burabay',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Almaty',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-mountain-range-with-houses-on-it-Dxwf7I2MPKU',
          wikiLink: 'https://en.wikipedia.org/wiki/Almaty',
        },
      },
    ],
  },
  {
    name: 'Kenya',
    code: 'KE',
    lat: 1,
    long: 38,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/T-LfvX-7IVg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tsavo_East_National_Park',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/safari-elephant-landscape-nature-4043090/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kenya',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Kenya',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-on-brown-grass-field-under-white-clouds-and-blue-sky-during-daytime-1jlJrr4XGkU',
          wikiLink: 'https://en.wikipedia.org/wiki/Kenya',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Kenya',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/two-hot-air-balloons-in-the-wild-89IBtfoz3Vw',
          wikiLink: 'https://en.wikipedia.org/wiki/Kenya',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Kenya',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-brown-field-during-daytime-iyg24BviAoM',
          wikiLink: 'https://en.wikipedia.org/wiki/Kenya',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Kenya',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-under-white-clouds-xDxiO7sldis',
          wikiLink: 'https://en.wikipedia.org/wiki/Kenya',
        },
      },
    ],
  },
  {
    name: 'Kerguelen Islands',
    code: 'TF',
    lat: -49.280366,
    long: 69.348557,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Volcan_du_Diable_%28Kerguelen_islands%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Volcan_du_Diable',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Kerguelen_CookGlacier.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Cook_Ice_Cap',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Kerguelen Islands',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/dominique_filippi/3534835906',
          wikiLink: 'https://en.wikipedia.org/wiki/Kerguelen_Islands',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Kerguelen Islands',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Kerguelen_Islands#/media/File:Kerguelen_CookGlacier.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Kerguelen_Islands',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Kerguelen Islands',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/seeschloss/5223155784/in/album-72157625358299925',
          wikiLink: 'https://en.wikipedia.org/wiki/Kerguelen_Islands',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Kerguelen Islands',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/seeschloss/5207019791/in/album-72157625358299925',
          wikiLink: 'https://en.wikipedia.org/wiki/Kerguelen_Islands',
        },
      },
    ],
  },
  {
    name: 'Kiribati',
    code: 'KI',
    lat: 1.41666666,
    long: 173,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/fanning-island-kiribati-beach-1663125/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tabuaeran',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:South_Tarawa_from_the_air.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/South_Tarawa',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Kiribati',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/ds355/957735286',
          wikiLink: 'https://en.wikipedia.org/wiki/Kiribati',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Kiribati',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/iloasiapacific/9181440338',
          wikiLink: 'https://en.wikipedia.org/wiki/Kiribati',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Kiribati',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/hillschmill/2955863933/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kiribati',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Butaritari',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/58644897@N08/6184297164/',
          wikiLink: 'https://en.wikipedia.org/wiki/Butaritarii',
        },
      },
    ],
  },
  {
    name: 'Kosovo',
    code: 'XK',
    lat: 42.666667,
    long: 21.166667,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/bwSYcD0okkM',
          wikiLink: 'https://en.wikipedia.org/wiki/Prizren',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/prizren-kosovo-city-view-town-3430989/',
          wikiLink: 'https://en.wikipedia.org/wiki/Prizren',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Kosovo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-green-mountain-under-blue-sky-during-daytime-9ut57M5CsUQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Kosovo',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Treska',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-clouds-over-green-mountain-bHDns51fR7I',
          wikiLink: 'https://en.wikipedia.org/wiki/Kosovo',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Kosovo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-grassy-hill-with-trees-on-it-j_tT1s0MCa4',
          wikiLink: 'https://en.wikipedia.org/wiki/Kosovo',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Prizren',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-white-building-with-a-black-bench-in-front-of-it-pW3yssrTMmk',
          wikiLink: 'https://en.wikipedia.org/wiki/Prizren',
        },
      },
    ],
  },
  {
    name: 'Kuwait',
    code: 'KW',
    lat: 29.5,
    long: 45.75,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/ICJkwztWGcI',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuwait_Towers',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/kuwait-ruins-sea-house-coast-1824090/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuwait',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Kuwait',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/high-rise-buildings-during-sunrise--XAphu7d5t0',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuwait',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Kuwait',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-street-during-daytime-EyV6qxS3kl0',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuwait',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Kuwait',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-building-through-a-fence-at-night-3EpdvGspiDM',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuwait',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Kuwait',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/rock-formations-133ajKnAgU8',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuwait',
        },
      },
    ],
  },
  {
    name: 'Kyrgyzstan',
    code: 'KG',
    lat: 41,
    long: 75,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/jTO7daIRh0U',
          wikiLink: 'https://en.wikipedia.org/wiki/Kyrgyzstan',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/kyrgyzstan-toktogul-dam-dam-naryn-4765706/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kyrgyzstan',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Kyrgyzstan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-house-on-green-grass-field-near-mountain-during-daytime-jTO7daIRh0U',
          wikiLink: 'https://en.wikipedia.org/wiki/Kyrgyzstan',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Kyrgyzstan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-white-mountains-under-white-clouds-during-daytime-4dZnL_Da9gk',
          wikiLink: 'https://en.wikipedia.org/wiki/Kyrgyzstan',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Bishkek',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-large-building-at-night-Tu0kZSXygUU',
          wikiLink:
            'https://en.wikipedia.org/wiki/Bishkek#:~:text=Bishkek%2C%20formerly%20known%20as%20Pishpek,of%201%2C074%2C075%2C%20as%20of%202021.',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Kegeti',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-mountains-under-blue-sky-during-daytime-hJbRHIzTvto',
          wikiLink: 'https://en.wikipedia.org/wiki/Kegeti',
        },
      },
    ],
  },
  {
    name: 'Laos',
    code: 'LA',
    lat: 18,
    long: 105,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/yJ2SHIpNb9M',
          wikiLink: 'https://en.wikipedia.org/wiki/Vang_Vieng',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/road-luang-prabang-street-city-4878453/',
          wikiLink: 'https://en.wikipedia.org/wiki/Laos',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Vientiane',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-white-concrete-building-iIhIidVC4UU',
          wikiLink: 'https://en.wikipedia.org/wiki/Vientiane',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Laos',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/two-boats-near-body-of-water-BMXTDz-wZT4',
          wikiLink: 'https://en.wikipedia.org/wiki/Vientiane',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Laos',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-mountain-range-with-a-valley-in-the-foreground-IPhX8UIAQn0',
          wikiLink: 'https://en.wikipedia.org/wiki/Laos',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Vientiane province',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-rice-field-during-daytime-g53FHDFECRQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Vientiane_province',
        },
      },
    ],
  },
  {
    name: 'Latvia',
    code: 'LV',
    lat: 57,
    long: 25,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/jFqUJacYIsw',
          wikiLink: 'https://en.wikipedia.org/wiki/Riga',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/latvia-riga-daugava-flow-3725546/',
          wikiLink: 'https://en.wikipedia.org/wiki/Latvia',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Latvia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-green-trees-during-daytime-DKL2nwMzT1E',
          wikiLink: 'https://en.wikipedia.org/wiki/Latvia',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Latvia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-buildings-during-daytime-3GVX2-dWttU',
          wikiLink: 'https://en.wikipedia.org/wiki/Latvia',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Latvia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-lake-surrounded-by-snow-covered-trees-and-a-forest-zxjtINsoA4A',
          wikiLink: 'https://en.wikipedia.org/wiki/Latvia',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Latvia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/grey-concrete-house-lot-uft-MemToy0',
          wikiLink: 'https://en.wikipedia.org/wiki/Latvia',
        },
      },
    ],
  },
  {
    name: 'Lebanon',
    code: 'LB',
    lat: 33.83333333,
    long: 35.83333333,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/KqEYj1VHA_o',
          wikiLink: 'https://en.wikipedia.org/wiki/Beirut',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/landscape-village-church-catholic-3663721/',
          wikiLink: 'https://en.wikipedia.org/wiki/Lebanon',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Lebanon',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-near-body-of-water-during-daytime-h8appowEHs8',
          wikiLink: 'https://en.wikipedia.org/wiki/Lebanon',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Lebanon',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-desk-mBcY97cXOTs',
          wikiLink: 'https://en.wikipedia.org/wiki/Lebanon',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Byblos Port',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/boats-beside-dock-Bc2EPt6bM5w',
          wikiLink: 'https://en.wikipedia.org/wiki/Byblos_Port',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Mansourieh',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-concrete-building-on-green-mountain-under-white-clouds-during-daytime-zLHmIfJs0Fk',
          wikiLink: 'https://en.wikipedia.org/wiki/Mansourieh,_Lebanon',
        },
      },
    ],
  },
  {
    name: 'Lesotho',
    code: 'LS',
    lat: -29.5,
    long: 28.5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/wg4Yd9iK0ho',
          wikiLink: 'https://en.wikipedia.org/wiki/Maloti_Mountains',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/lesotho-mountain-village-927577/',
          wikiLink: 'https://en.wikipedia.org/wiki/Lesotho',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Lesotho',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-holding-beige-horse-URroWgc_x2w',
          wikiLink: 'https://en.wikipedia.org/wiki/Lesotho',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Lesotho',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-mountains-near-lake-during-daytime-5wtBRWXCQp8',
          wikiLink: 'https://en.wikipedia.org/wiki/Lesotho',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Lesotho',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-walking-on-green-grass-field-BtCaXJ8BJjY',
          wikiLink: 'https://en.wikipedia.org/wiki/Lesotho',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Lesotho',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-mountains-near-body-of-water-under-white-clouds-during-daytime-6raP_K5VQXw',
          wikiLink: 'https://en.wikipedia.org/wiki/Lesotho',
        },
      },
    ],
  },
  {
    name: 'Liberia',
    code: 'LR',
    lat: 6.5,
    long: -9.5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Liberia,_Africa_-_panoramio_%28255%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Liberia',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/6831730706',
          wikiLink: 'https://en.wikipedia.org/wiki/Mesurado_River',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Liberia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/time-lapse-photography-of-thunderbolt-6ACcbfIycEg',
          wikiLink: 'https://en.wikipedia.org/wiki/Liberia',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Liberia',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/unmil/14064974957',
          wikiLink: 'https://en.wikipedia.org/wiki/Liberia',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Liberia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-tree-sitting-in-the-middle-of-a-park-wVxjXHTZi5Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Liberia',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Liberia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-grassy-hill-with-palm-trees-SjPb6diu0Y4',
          wikiLink: 'https://en.wikipedia.org/wiki/Liberia',
        },
      },
    ],
  },
  {
    name: 'Libya',
    code: 'LY',
    lat: 25,
    long: 17,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/ci3Eot3SyEQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Sabratha',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/oasis-libya-desert-palm-trees-67549/',
          wikiLink: 'https://en.wikipedia.org/wiki/Libya',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Tripoli',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-concrete-building-near-palm-trees-during-daytime-edecsA5q9xM',
          wikiLink: 'https://en.wikipedia.org/wiki/Tripoli,_Libya',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Murzuq‎',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/desert-sand-during-dawn-Ggvj8lq_Fs8',
          wikiLink: 'https://en.wikipedia.org/wiki/Murzuq_District',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Libya',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/black-car-on-desert-during-daytime-ttojS7IURi0',
          wikiLink: 'https://en.wikipedia.org/wiki/Libya',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Tripoli ',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-building-lit-up-at-night-with-a-clock-on-top-59atuKxvhXI',
          wikiLink: 'https://en.wikipedia.org/wiki/Tripoli,_Libya',
        },
      },
    ],
  },
  {
    name: 'Liechtenstein',
    code: 'LI',
    lat: 47.26666666,
    long: 9.53333333,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/4CKnbVZ4Tew',
          wikiLink: 'https://en.wikipedia.org/wiki/Vaduz_Castle',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/liechtenstein-city-architecture-176116/',
          wikiLink: 'https://en.wikipedia.org/wiki/Liechtenstein',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Vaduz',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-parked-near-building-during-daytime-5aJlZgteKzc',
          wikiLink: 'https://en.wikipedia.org/wiki/Vaduz',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Liechtenstein',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/four-brown-wooden-house-on-snow-covered-field-T9yKWV4xdUs',
          wikiLink: 'https://en.wikipedia.org/wiki/Liechtenstein',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Liechtenstein',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/photography-of-mountain-covered-with-snow-4WyiTpCB2SY',
          wikiLink: 'https://en.wikipedia.org/wiki/Liechtenstein',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Vaduz',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-concrete-building-during-daytime-r7xZNCuVMUs',
          wikiLink: 'https://en.wikipedia.org/wiki/Vaduz',
        },
      },
    ],
  },
  {
    name: 'Lithuania',
    code: 'LT',
    lat: 56,
    long: 24,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/NMV4RIqrP-A',
          wikiLink: 'https://en.wikipedia.org/wiki/Trakai',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/vilnius-lithuania-eastern-europe-1029634/',
          wikiLink: 'https://en.wikipedia.org/wiki/Lithuania',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Lithuania',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/view-of-dock-at-river-bank-xGltPaX1FeY',
          wikiLink: 'https://en.wikipedia.org/wiki/Lithuania',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Lithuania',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-hot-air-balloon-flying-over-a-large-body-of-water-NMV4RIqrP-A',
          wikiLink: 'https://en.wikipedia.org/wiki/Lithuania',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Lithuania',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/elenakurlaviciute/22711828872/in/photolist-AAY4sq-2nZkVDu-2mWJ8dY-2iVyC5e-2mNvU4g-ACayEi-zF8Je1-QPjvB4-zxuhBe-2nkFD1e-cMYRwU-7JhtKe-adPzAc-2c1iWLw-5vmzbX-5vrkK3-2n841cQ-2n8MvQ5-2iVyNmg-21zp71o-ew1ao2-2n8Muvg-4KJvtF-2ksHBAM-G2NdnT-fGRwYc-adPxk4-zF8NPd-2mPv3et-21UuKdr-Bs23WC-62Yshn-2hXqXGQ-9r4tPk-e2J9vN-2iWjsJj-PEdXn2-2n84q25-ehrcCo-Rc1XzF-ae1W1n-AAY73A-adVJMU-PmMVcZ-9r7cKo-K3MUZA-V5Upsk-ae4KX7-3ur6BG-bstdkG',
          wikiLink: 'https://en.wikipedia.org/wiki/Lithuania',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Vilnius',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-black-and-white-photo-of-a-building-S6FCH5FqBfE',
          wikiLink: 'https://en.wikipedia.org/wiki/Vilnius',
        },
      },
    ],
  },
  {
    name: 'Luxembourg',
    code: 'LU',
    lat: 49.75,
    long: 6.16666666,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/FPdyoYNdvaM',
          wikiLink: 'https://en.wikipedia.org/wiki/Luxembourg_City',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/luxembourg-luxembourg-city-landscape-1164663/',
          wikiLink: 'https://en.wikipedia.org/wiki/Luxembourg',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Luxembourg City',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-concrete-building-0mY01y72UKo',
          wikiLink: 'https://en.wikipedia.org/wiki/Luxembourg_City',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Luxembourg',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-near-river-under-cloudy-sky-during-daytime-8YYeRlD86uk',
          wikiLink: 'https://en.wikipedia.org/wiki/Luxembourg',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Luxembourg',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-palace-on-forest-X30mNjC_oOY',
          wikiLink: 'https://en.wikipedia.org/wiki/Luxembourg',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Luxembourg City',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/97088698@N06/52509906808/in/photolist-2o17ZSQ-2mKfaxn-2og5SF2-2hRocid-QQx45f-2k5Ry3w-XG4Rrd-vdpjtH-o4Bje5-2gZR5hW-2h9uA8W-2dpHqFP-YrUGrz-2gVPobD-2hn9KA5-2iGfQeW-2hsXMGE-2ogkEsP-RbWkWp-FnuVu5-PMkg3q-2hm69E1-2nPnQkY-2gXxhWa-2hMt6bs-UefiQU-nbP9Eq-2j5wcGU-2i8pzwc-2nnPZ8N-2q7qF7u-2dwEaNX-SbvTik-2o3DN6m-VVz4o1-2iKyDpA-2f5uQJu-PMk3Qu-pfBdv3-2hKzBEx-25Vbsq5-2e1dUew-2g3729U-2gYQ23r-AdBAs1-NKhskT-2og8qWn-RzPDYi-27C2d3S-25vfziq',
          wikiLink: 'https://en.wikipedia.org/wiki/Luxembourg_City',
        },
      },
    ],
  },
  {
    name: 'Macau',
    code: 'MO',
    lat: 22.198745,
    long: 113.543873,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/dZbJOsTl4PM',
          wikiLink: 'https://en.wikipedia.org/wiki/Casino_Lisboa_(Macau)',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/macao-macau-china-architecture-4769469/',
          wikiLink: 'https://en.wikipedia.org/wiki/Macau',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Macau',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-on-street-during-daytime-ziUS6LTwWDc',
          wikiLink: 'https://en.wikipedia.org/wiki/Macau',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Macau',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-parked-near-brown-concrete-building-during-daytime-TFS_MtsZHaE',
          wikiLink: 'https://en.wikipedia.org/wiki/Macau',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Macau',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-walking-down-a-street-next-to-tall-buildings-Kl46uyMmu3g',
          wikiLink: 'https://en.wikipedia.org/wiki/Macau',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Nossa Senhora de Fátima',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/146831310@N07/45516090314/in/photolist-e3rZUN-2cm6RTQ-2nPnSiL-2onBm4g-2mQ9jcf-2pMVUDo-NrpkYS-WDUuBC-2pVX5Am-2mDGQ9H-WPfTA9-2juZRSZ-r1WFfN-26Mvnwa-84YNZs-84VFt4-qLuqmm-r2m6wQ-81Vq4j-WRxtNU-2hVj6qR-2id59hJ-dzwjhb-25p2uUY-26Mvnsx-2mGWtAk-YGu2kf-2m5QpvL-2juRCv7-2ogA9QF-BZJJky-jQerkL-2px9oMr-2px8xAU-fDA5mR-H6T4Y6-2juZRTv-fDA5dt-k2JDDi-fDSDHd-qGQH6Y-2mpdncn-rFKRFw-rmpAQn-4LALmZ-2j1Af1x-eUktk8-cDTEQN/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Nossa_Senhora_de_F%C3%A1tima,_Macau',
        },
      },
    ],
  },
  {
    name: 'Madagascar',
    code: 'MG',
    lat: -20,
    long: 47,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/yPSbirjJWzs',
          wikiLink: 'https://en.wikipedia.org/wiki/Adansonia_grandidieri',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/baobab-madagascar-tree-nature-5030877/',
          wikiLink: 'https://en.wikipedia.org/wiki/Madagascar',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Madagascar',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/trees-near-pathway-during-daytime-yPSbirjJWzs',
          wikiLink: 'https://en.wikipedia.org/wiki/Madagascar',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Madagascar',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/green-field-VQdgOEC6Tgc',
          wikiLink: 'https://en.wikipedia.org/wiki/Madagascar',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Madagascar',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/luc/393874958/in/photolist-ANHiy-b25xic-b25wqv-p31x2f-ANQHx-2nYFPR2-b25yV6-b25zpV-b25Aac-ANQtB-ANQjg-b25ytB-b25AsT-b25yFk-ANM2e-b25yak-b25x2K-2nYJxb6-ANHer-AXKyHH-A27Swz-ANM4X-ANLFo-ANHvR-ANHxu-ANLXn-2nYFRQx-2nYM7Q8-2nYMasT-AHGqHP-Aajwr3-AVx4Ds-2nYLGWd-2nYJvyU-2nRgNNW-Ak7AGK-2nYG6f8-2nYKMnt-AYZXEM-AvKgtm-ANQNZ-Bjpxue-AmrYbp-B4XRcL-AKXYpH-B8fNd2-Atncuz-4Hiviy-AQhidC-ANLPK',
          wikiLink: 'https://ro.wikipedia.org/wiki/Madagascar',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Antananarivo',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/103417/49962754336/in/photolist-2j83bbU-2dv726c-RnjdHQ-2cEfJnt-Rdt8DN-NU2ntg-2cFiVeX-NWLSCk-qTsgmK-rxEsXd-2nyYm41-oWPWwy-4ZGM8b-rxFy21-oWAeuD-6STfzj-prKqCv-AYg2kZ-MDYXz8-AW6btG-A1ffru-oEmYL1-7mzrV6-63SUXG-fYVdz9-6STcRU-6SPciB-6STeY9-6SPcXK-iBDC5i-6SP9He-6SPaqc-6SPcuV-8kUdp-8kUdq-6STfn5-2hUTA1b-6SPaAr-6SPaYH-6SPcRZ-6SPbGP-6SPb4r-6SPcFX-8kUdk-6SP9Ba-6STekQ-K11Huk-oEntvJ-6SPbTp-4TYM1E',
          wikiLink: 'https://en.wikipedia.org/wiki/Antananarivo',
        },
      },
    ],
  },
  {
    name: 'Malawi',
    code: 'MW',
    lat: -13.5,
    long: 34,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/f8Vkuc6fXQA',
          wikiLink: 'https://en.wikipedia.org/wiki/Malawi',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/malawi-view-ocean-sea-beach-242567/',
          wikiLink: 'https://en.wikipedia.org/wiki/Malawi',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Malawi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-on-brown-sand-during-daytime-eFVlSmIyzpM',
          wikiLink: 'https://en.wikipedia.org/wiki/Malawi',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Malawi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-boat-on-sea-near-palm-trees-during-daytime-f8Vkuc6fXQA',
          wikiLink: 'https://en.wikipedia.org/wiki/Malawi',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Malawi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-brown-rock-formation-during-daytime-VvV29KYNPVY',
          wikiLink: 'https://en.wikipedia.org/wiki/Malawi',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Lilongwe',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/90672529@N00/20812636645/in/photolist-xH9cLR-xH8Pmk-wL76QL-xGhzSG-xGidwU-xqvxfd-xENDp7-wL7qHq-xqCk2n-xH8XUT-wL6Sp7-xH8amx-xquQxU-wL7KQy-xGhUky-xqC3Gi-xH8zjn-eWFuh-XfqzzA-XfqxZb-xqwcab-xH8Uxn-XicNY6-8yeWxX-2w8AD8-9v3jcc-7cf8XF-3nMCC6-8DnKGa-xqvcUj-2kGV94V-7XTbBa-7cpJCM-2JCJmt-5h8d92-9asi1-9asfG-9asn3-3nSaa3-8yi18q-7ctyuj-7ctA6h-wLf7Hk-7cpJKv-7ctyeW-9asnK-246ws24-9ashe-bSSsQM-674JAg',
          wikiLink: 'https://en.wikipedia.org/wiki/Lilongwe',
        },
      },
    ],
  },
  {
    name: 'Malaysia',
    code: 'MY',
    lat: 2.5,
    long: 112.5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/WaL-LPfgUBg',
          wikiLink: 'https://en.wikipedia.org/wiki/Batu_Caves',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/kuala-lumpur-petronas-twin-towers-1820944/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuala_Lumpur',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Kuala Lumpur',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/twin-tower-malaysia-6U-sSfBV-gM',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuala_Lumpur',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Malaysia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-on-road-between-high-rise-buildings-during-night-time-xuElil9fmfg',
          wikiLink: 'https://en.wikipedia.org/wiki/Malaysia',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Malaysia',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/135025625@N03/25000160451/in/photolist-2kGx6mF-2kqmmCR-E6bmHa-4rDrTu-4rDBAj-82cvQx-2kQng9c-2kLgYoK-2kMnxUr-2jKU4tS-4KbhZb-2kqgZuh-2jNdL37-2kLxs1J-2kbbbMX-2kLmchX-2jMfJmL-fK771x-2jNewLr-2m9yTxG-2m9ymT2-fKoDsQ-2kuERC3-fKoHcA-fK77Dg-fK7cDn-2kbruBr-2jW9Yn3-2jV2ASz-fKoNab-UtfBq6-fK7jAt-fKoN1w-2jW9Yfu-3KJYaq-2jMfJpm-2kNypAV-fK74NT-2kQYH4N-2koP7nQ-2kMav8n-DjUFLj-2fDgbYC-VGL1kH-fK7hwc-dC68kV-7Qv7fg-u4hcZd-2jNdLuK-2cgCXeh',
          wikiLink: 'https://en.wikipedia.org/wiki/Malaysia',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Kuala Lumpur',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/7976002@N08/535878894/in/photolist-Pmw8h-KoLStf-4vEFCQ-619wSE-59CrLY-2g5oU1n-5ZKtKW-2nAc3a4-28tbdzt-2oxUB9B-CK3Tf-JPbngr-RdKSQs-276qMmX-JPboYe-pUWdVR-3hwzP-5ZKtBb-3hwzQ-eGF1wz-4W5ri3-3hwzM-2nmLf8A-5eYXJ8-28t6mTc-25HchEj-LkdTM5-5f4k3C-bsqj5R-LQp4je-9VV9Ds-rbf14G-qTLw77-rbbHmD-5eYXQp-T1NZrK-2puwbsC-28t7Lnz-LkdG8J-3yF691-2kJ9ALV-4JXGr-WvF3fx-25H8uQE-qhLuLj-276vZPH-qewDY8-2oiD4U1-28tb6N2-pCyHF4',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuala_Lumpur',
        },
      },
    ],
  },
  {
    name: 'Maldives',
    code: 'MV',
    lat: 3.25,
    long: 73,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/xPsFXsbXJRg',
          wikiLink: 'https://en.wikipedia.org/wiki/Maldives',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/maldives-hut-shack-villa-beach-4703551/',
          wikiLink: 'https://en.wikipedia.org/wiki/Maldives',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Maldives',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-dock-beside-white-motor-boat-uWpggIb3iHs',
          wikiLink: 'https://en.wikipedia.org/wiki/Maldives',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Maldives',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-cottage-on-blue-body-of-water-during-daytime-k9Zeq6EH_bk',
          wikiLink: 'https://en.wikipedia.org/wiki/Maldives',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Maldives',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/47300487@N04/4338710090/in/photolist-7Bp2rG-7Bp2mo-7Bkdua-7Bp2Kh-7BkcXB-7Bp1Kb-7Bp2Hf-7Bp2AQ-7BkdFx-7Bp1Ub-7BkdTB-7Bp1SC-7BkdRR-7Bp2to-7Bp1Py-7Bp1uY-7BkdZT-2JAegu-rBJEuZ-2JvVxT-mwvNj9-76mmXF-cwwe65-7Bp2fq-7Bp29d-ijmeMP-ieQqu4-idupmS-iduKxv-ik6bao-aAmHo4-7Bp1jS-ieQcEY-idue1F-63DdYZ-iduF7r-cwwdZq-X6iGVo-cwwefj-iePVBh-ik5P5t-cwweaA-XChutm-kK9nwK-aAmHKg-iPzP8P-aDXLXK-47VEiY-iPBMFq-iiNfdL',
          wikiLink: 'https://en.wikipedia.org/wiki/Maldives',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Malé',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/riphthootha/5187421779/in/photolist-8UoTLx-2m2f7zg-axUnCT-5npdG1-9csuTm-5Cq5fB-cdk8V3-6zao7Z-2kyTBow-2kAbR99-b4T3xB-5AMaxK-68pF2p-2oRqAvW-Cp87nY-2kEMe3a-2nocRBc-8P8bVD-2oqKLgk-2mUoZXJ-2m7ZPnh-2cZNg4A-2oQThhV-2oB13db-2nPA5UD-ChykeP-2kycx1e-2kvzz9n-24PX7J5-2nLG3Fr-2fyAmRz-2j9NF4M-2ovsdbp-2p7xYnU-2phHD2X-YAs3gH-2nTD3W9-YRrgCH-29aBnJ-2nR3DvP-2iW9iWD-2ofZAZw-297yG15-2kZrv17-2j9eruN-2oL6rAG-D6rm4-2oRfefz-2pCXYBU-2m46qKQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Mal%C3%A9',
        },
      },
    ],
  },
  {
    name: 'Mali',
    code: 'ML',
    lat: 17,
    long: -4,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mali-boats-good-produce-town-80855/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mali',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Village_Telly_in_Mali.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mali',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Timbuktu',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-body-of-water-through-a-magnifying-glass-xvixfkRw94o',
          wikiLink: 'https://en.wikipedia.org/wiki/Timbuktu',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Mali',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/jmenj/8438034181',
          wikiLink: 'https://en.wikipedia.org/wiki/Mali',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Mali',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/gozilah/6731376281/in/photolist-bfQ4DK-9ss6h3-9ss6sY-9ss6r7-9ss6d3-qoieNf-9ss6ph-9sp71X-9sp73P-9ss6e3-72bkwG-9sp6VX-twoQu-59B5ct-9sp6UF-AcLEEc-9sp6S4-9sp6Pz-9sp6Zt-9ss6ob-72bjk5-kakPv-7vFc3j-7U3X1D-BDYjub-2ZRokb-wd1rYU-wd1sqL-37D72j-37D7Fo-37D7uo-VFHUF3-TXrDpA-9sp76H-4rFFzo-4HQ7np-4rBAUi-ddZN2k-8eCgRS-JucW1-zAy6L3-3gk9nN-5GVDn4-5p3eVx-4qZv3A-8adXMe-59B5cP-UXATMu-4r8QgX-5MQNUD',
          wikiLink: 'https://en.wikipedia.org/wiki/Mali',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Bamako',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/27126129@N00/159092714/in/photolist-f4oHQ-67m9jx-3JV5gq-a1ekR-f4gfs-67qnWA-3JV4DA-67qnCm-67qnaW-67m1EV-4rt3vm-5RCxPz-a1ekP-AQN1M-q8WgQ-c7VG9U-71AdQJ-dRw3Xw-6Ev5uc-9CJ6BJ-9e58iZ-B2h5o-7iWWEp-B2hfg-ErH9F-ErFV5-3JQpDZ-3JUJpo-9CF9AZ-3JQqdH-3JUHMy-5Aanek-3JUJgN-bBeGxP-8vrA8h-3JUHU3-3JQq4K-5Aan36-5AamWz-B2hbL-6EuTqH-98Un9-yKGkJ-Ad67-yKGkL-27QxB37-2hum8eC-2huoQEY-2hupPan-2hupMjy',
          wikiLink: 'https://en.wikipedia.org/wiki/Bamako',
        },
      },
    ],
  },
  {
    name: 'Malta',
    code: 'MT',
    lat: 35.83333333,
    long: 14.58333333,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/dWK5VEPIVwk',
          wikiLink:
            'https://en.wikipedia.org/wiki/Sanctuary_of_Our_Lady_of_Mellie%C4%A7a',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/popeye-village-malta-architecture-4158038/',
          wikiLink: 'https://en.wikipedia.org/wiki/Malta',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Marsaxlokk',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/blue-and-yellow-boat-on-water-near-brown-concrete-building-during-daytime-3F904UCiZGw',
          wikiLink: 'https://en.wikipedia.org/wiki/Marsaxlokk',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Malta',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/high-angle-view-of-body-of-water-Exf495AtWZI',
          wikiLink: 'https://en.wikipedia.org/wiki/Malta',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Malta',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/blue-ocean-water-surrounded-mountain-under-blue-and-white-cloudy-sky-RZC_fPZ5-AA',
          wikiLink: 'https://en.wikipedia.org/wiki/Malta',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Valletta',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/50141659@N00/52285795630/in/photolist-2nEjnr1-JaJ8wT-2opxkQ6-oJ3Qg6-ePVNSU-2maS65b-2jKvgbq-2aJv2Ej-2jKprSh-2opiQk7-JHVLCx-As98hr-oZ5za9-X5sn7Y-QYZST-2nX4xGa-8zB7Q8-3phgpu-9NZ22g-2jKLC3f-a2Znru-BY7Lvw-KSQTP7-2oXswp-25rqJhn-aDBz12-7WGh6T-wJMFQ-2nR46i2-q3bBze-69gS29-CpVioh-2ne86W8-Hiw6wG-2oXsva-8rVvkk-a7NLjr-edY5Sv-7ryDkk-7rCwDU-b3Vus-2a4YrAp-7rCKWN-8Daon1-7vY1i2-a7REHY-a7NMfM-a7NMJD-q3ugzE-etCEc8',
          wikiLink: 'https://en.wikipedia.org/wiki/Valletta',
        },
      },
    ],
  },
  {
    name: 'Marshall Islands',
    code: 'MH',
    lat: 9,
    long: 168,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/marshall-islands-usa-beach-coast-1064876/',
          wikiLink: 'https://en.wikipedia.org/wiki/Marshall_Islands',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Marshall_Islands_%2840325565%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Marshall_Islands',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Marshall Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/silhouette-of-palm-trees-during-sunset-OSzKOGIJ3bw',
          wikiLink: 'https://en.wikipedia.org/wiki/Marshall_Islands',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Marshall Islands',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/mrlins/302904968',
          wikiLink: 'https://en.wikipedia.org/wiki/Marshall_Islands',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Marshall Islands',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/102182523@N07/9811274503/in/photolist-4yFwn-WJXFMb-2mQyEiH-6LNUYC-2k6Meas-2jEA8M2-2jPTTYo-2jL9P1w-2jXa8FX-2jQer4b-2jQFamd-2nouVYv-2kmBTJJ-fWZm6e-2oskU6r-2ndej2y-2oQzAzz-JP1tu-2oEiacb-4yFsA-2kU7F91-2mGYqL5-MrA98N-2kA5WEz-2jJXE1v-2nvHr1Z-2kA2pqd-4yFvc-2kA2pMa-4yFsb-2oiTSua-5Sm8R6-7p9oDF-5TbhSH-2oXMVVh-2mVXJAr-5SmdJB-5Sm8Rk-5Sm8Re-2oQF447-RvmdC5-4yFo8-ZN6TF8-2nctsyr-2nBmuSN-4yFkd-5Sm8Ra-yoFFu-5UhVVb',
          wikiLink: 'https://en.wikipedia.org/wiki/Marshall_Islands',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Majuro',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/mccormickwest/4235966319/in/photolist-7sjrd4-7soqaJ-6Sa234-nUwr9Z-2mM6oib-d7jiKW-Y39yFN-2kA5V9d-2kA6zjh-2kA6zr1-fZYQ-2kA6A6T-2narHbZ-6HMb2z-7sopRL-nAjz-2kA5WEz-2kA5We4-aU1HNP-2kA5VQy-2kA2pqd-2kA2pMa-2mbbqB4-5jg9j7-3bdGM-2kA2q8v-6HMnbK-2kA6Aqv-STcBa-6HRpaL-6HRjWu-6HRv7u-6HMfWX-ntYh-6HRo53-6HMr8X-6HMcVg-68mYKu-nKZR8-6HRsaA-6HMbW2-6HMk8P-6HMq9k-6HMeX2-d7jiZj-23VgV8-2nFtdK1-nM3mEg-5WgDJc-9XqfY',
          wikiLink: 'https://en.wikipedia.org/wiki/Majuro',
        },
      },
    ],
  },
  {
    name: 'Martinique',
    code: 'MQ',
    lat: 14.666667,
    long: -61,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/caribbean-martinique-sea-beach-67886/',
          wikiLink: 'https://en.wikipedia.org/wiki/Martinique',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/nature-sky-blue-sky-clouds-2358189/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mount_Pel%C3%A9e',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Martinique',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-palm-trees-near-mountain-during-daytime-wSEv7Nanuxc',
          wikiLink: 'https://en.wikipedia.org/wiki/Martinique',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Martinique',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-and-green-mountain-under-white-clouds-during-daytime-E8uYKuyCuhU',
          wikiLink: 'https://en.wikipedia.org/wiki/Martinique',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Martinique',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/183070308@N06/51861677903/in/photolist-2n1QE1p-2nD8o8K-2n1QeWk-2krQLk4-2nk7Xaq-2nD8nRH-2ne2GwW-2nD8nDd-2nk7XyG-2nk6Qmr-2ndWwfz-2krV2tG-2mkJfUs-2n1TAfz-2krQL5z-2krV2af-2krUAwB-2ne43gm-2n1QE95-2hcxqFX-2fpWkcg-2gLPbuT-2fq2AjF-2eiZu4f-RDx4zB-2hd17vJ-2hcxsmL-2fpWkYM-hEntUT-2e23siZ-ei5BjN-6R3DJm-2eiZt1d-2e23rR6-RDx52i-2eiZupL-2hczb7R-bzxdHT-bzxdz6-bmCm2W-2ej5er9-2eiZtxf-TgGAMA-2hcxqhR-TgGA5d-2eiZstw-RDx1Q8-RDx1ir-bzxdmR-RDx1wc',
          wikiLink: 'https://en.wikipedia.org/wiki/Martinique',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Fort-de-France',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/wollis/46077219242/in/photolist-2ormSBz-2kZzj23-Gd5nSf-22YCJkg-2m2hdmp-vJfNXN-H8eU1z-9ojxd2-2dcFMYw-8YnEJW-2iTn552-bd87r2-2mwk5KA-Rmx5je-RGNu2s-2mLk3yd-2o6NS51-YhkmkD-Xe3CfN-ykgWW7-yZFLFs-ykhi1W-PWEPqY-yY2oo1-ymDfaC-2kAVrNv-EF8MXp-VM4QWS-s1iSPD-23TNDNR-CDUzcM-CeYVCY-2ip1ccj-rL3yzD-D8XtUz-DaHCnc-tMzmms-2ipfAXr-2jb6U1J-2kDFDt2-Wqmacf-qSz3YF-27jU1vE-2pympxZ-25WoTds-247Cvqk-X1vJs9-ryakNz-2isP7dZ-RqJtGd',
          wikiLink: 'https://en.wikipedia.org/wiki/Fort-de-France',
        },
      },
    ],
  },
  {
    name: 'Mauritania',
    code: 'MR',
    lat: 20,
    long: -12,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/0xFA04r_0ds',
          wikiLink: 'https://en.wikipedia.org/wiki/Mauritania',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/desert-camels-arab-mauritania-733728/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mauritania',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Mauritania',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/person-standing-at-the-edge-of-a-rock-mountain-facing-the-mountains-during-day-0xFA04r_0ds',
          wikiLink: 'https://en.wikipedia.org/wiki/Mauritania',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Mauritania',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/woman-sitting-inside-room-rYqm_OZaZrU',
          wikiLink: 'https://en.wikipedia.org/wiki/Mauritania',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Mauritania',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/127571429@N04/31809482255/in/photolist-Pem1tz-QhAA4z-QeSnVd-QpADjf-QhAzBc-Pem1ma-QpAFQN-QhAymM-QsTURe-Pem14M-QpACQE-QhAAHa-Pem1hH-QpAFBb-2njJoE9-QsTVbn-QpADxS-Pem1eX-Pem1wR-QpADru-QpAFtf-QhAAbP-Pem1xT-QpAE27-QhAyv4-Pem1DV-QpAGK3-PTYgkf-QpADUJ-QpAFNd-QpABRA-QhAyeH-QpADks-QpAC7A-Pem1zX-PbAXyL-QpABTj-QhAzvv-QpADyU-QpADFC-Pem1kt-QpADWC-QpADCb-QpACqG-QpADNm-QsTSk2-Pem17T-Pem1c2-PekZY6-QpADFN',
          wikiLink: 'https://en.wikipedia.org/wiki/Mauritania',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Nouakchott',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-herd-of-camel-standing-next-to-each-other-mvTggVGxfK4',
          wikiLink: 'https://en.wikipedia.org/wiki/Nouakchott',
        },
      },
    ],
  },
  {
    name: 'Mauritius',
    code: 'MU',
    lat: -20.28333333,
    long: 57.55,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/wVuRAom5v6U',
          wikiLink: 'https://en.wikipedia.org/wiki/Le_Morne_Brabant',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/boat-see-men-mauritus-coast-850510/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mauritius',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Mauritius',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-island-beside-body-of-water-ByAHlRiTQjo',
          wikiLink: 'https://en.wikipedia.org/wiki/Mauritius',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Mauritius',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/waterfalls-in-the-middle-of-green-trees-EBUBRr9i4bk',
          wikiLink: 'https://en.wikipedia.org/wiki/Mauritius',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Mauritius',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-island-beside-body-of-water-ByAHlRiTQjo',
          wikiLink: 'https://en.wikipedia.org/wiki/Mauritius',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Port Louis',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-person-sitting-on-a-bench-in-front-of-a-building-PVLgpVX2-qE',
          wikiLink: 'https://en.wikipedia.org/wiki/Port_Louis',
        },
      },
    ],
  },
  {
    name: 'Mayotte',
    code: 'YT',
    lat: -12.83333333,
    long: 45.16666666,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/tr%c3%a9vani-beach-mayotte-indian-ocean-604064/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tr%C3%A9vani',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/sada-mayotte-mosque-604056/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mayotte',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Dzaoudzi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/silhouette-of-mountain-near-body-of-water-during-sunset-qjyY80RtOEU',
          wikiLink: 'https://en.wikipedia.org/wiki/Dzaoudzi',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Mayotte',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-rock-formation-on-sea-shore-during-daytime-fAXuwGnZJFc',
          wikiLink: 'https://en.wikipedia.org/wiki/Mayotte',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Mayotte',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/42276499@N08/4226235492/in/photolist-b25Ccz-b25D6x-b25CFT-b25Dnx-b25Cni-7rsyzb-21nCjjz-2fpqgLD-5Piy7X-RBLMG8-2jmjpHL-4piJn9-RBLMAr-6GiHkD-avKJ6T-6GiBii-DeXHPq-21nCo6x-21nCj8c-DeXP3m-21nCkjR-7VHZQe-6GiCdc-4yfvoK-JL73qf-7qinp9-21nCkzv-DeXM39-7qetSM-21nCp8n-21nCoUX-21nCppz-21nCmz6-DeXKGy-21nCnLK-DeXNgb-c6kWwb-7qDGwW-5hwu7y-acW6VE-ZhpjBL-7rEMZZ-7yU37p-7yU39x-4HDMce-4HDM8B-g5B3H-2kWFjcs-5PkdYV-2nd4tzz',
          wikiLink: 'https://en.wikipedia.org/wiki/Mayotte',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Mamoudzou',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/130828646@N02/49752546348/in/photolist-2dHTDHx-SYz2sf-ghj3BM-2iNsNFf-2iNvMXJ-GP8tFF-2f7Jtck-2dHTUfP-SYzgTu-2f36dAW-24veQ1z-2e1PoYs-RmmNfF-2e1PkZG-SYzjQG-2dHTyp6-4DL37d-4DL3aW-GLXpbG-4DL3af-4DFLgp-4DFLhz-4DL36w-4DFLda-4DL3eQ-GERqpC-GLXohs-pXhJbj-Gp4sWm-5doGPT-7yU37p-7yU39x-4DL3dh-4DFLbr-Gp4tK5-8SXwDe-m7dNVD-FTCZSC-4DL31S-c3nuW5-pX9Pdx-4DFL7g-4DL365-4DFCPX-4DL34j-4DFL8x-4DKUQ5-4DL32S-4DFgqc-4DFL7K',
          wikiLink: 'https://en.wikipedia.org/wiki/Mamoudzou',
        },
      },
    ],
  },
  {
    name: 'Mexico',
    code: 'MX',
    lat: 23,
    long: -102,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/OYGN7McXKCM',
          wikiLink: 'https://en.wikipedia.org/wiki/Chichen_Itza',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/guanajuato-city-buildings-mountains-5838206/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guanajuato',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Valladolid',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/low-angle-photo-of-temple-QKrLdkoYDrc',
          wikiLink: 'https://en.wikipedia.org/wiki/Valladolid,_Yucat%C3%A1n',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'San Miguel de Allende',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-standing-on-corner-road-near-concrete-buildings-during-daytime-3snKY0XMKwg',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Miguel_de_Allende',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Mexico',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/mountain-peak-during-daytime-uILhNE1VcwA',
          wikiLink: 'https://en.wikipedia.org/wiki/Mexico',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Mexico City',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-from-the-top-of-a-hill-8A4E8bGLUqY',
          wikiLink: 'https://en.wikipedia.org/wiki/Mexico_City',
        },
      },
    ],
  },
  {
    name: 'Micronesia',
    code: 'FM',
    lat: 6.91666666,
    long: 158.25,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Islands_on_the_southern_barrier_reef_of_Pohnpei_%28Federated_States_of_Micronesia%29_-_Bikini.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Pohnpei',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Islands_on_the_southern_barrier_reef_of_Pohnpei_%28Federated_States_of_Micronesia%29_-_Penieu_2.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Pohnpei',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Micronesia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-and-mountains-g18MsEMgdNY',
          wikiLink: 'https://en.wikipedia.org/wiki/Micronesia',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Micronesia',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/sea-diver-eRGe-x7lI1Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Micronesia',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Micronesia',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/underwater-photo-9yynIWfRCpI',
          wikiLink: 'https://en.wikipedia.org/wiki/Micronesia',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Palikir',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/frankkehl/15152954985/in/photolist-DL2XoA-oNwAG1-2kqKY7Q-DFxBHc-4x7Zhz-2kqLsF9-CSpP2V-daQqbi-2kqLsFz-p61RAz-KFnQC4-2krAyVY-24A2UJp-a19t9j-7J6uTq-823aVJ-94h8C3-2kqLsDW-2kqLsus-a16BhK-a19tkw-2Q7oSd-9BrrPK-seMPys-2kqGbFH-oNvPGc-a16AZZ-a19sYm-a19t5U-Dwk89W-p3YPU5-a16B8k-oNwBV2-DMubNC-a19t1y-fUJXgX-KBsMdd-mWB84E-oNw51o-8BGndB-p5YNWh-a2S8r1-a16Bpv-9YKrM5-DnFNVU-9Vx9iP-9Vx8ti-a19sWw-LxbArT-9mNB1k',
          wikiLink: 'https://en.wikipedia.org/wiki/Palikir',
        },
      },
    ],
  },
  {
    name: 'Moldova',
    code: 'MD',
    lat: 47,
    long: 29,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/j0EXEOHgTlk',
          wikiLink: 'https://en.wikipedia.org/wiki/Orhei',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/moldova-landscape-scenic-stream-105172/',
          wikiLink: 'https://en.wikipedia.org/wiki/Moldova',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Moldova',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-near-body-of-water-during-daytime-j0EXEOHgTlk',
          wikiLink: 'https://en.wikipedia.org/wiki/Moldova',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Moldova',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/birds-eye-view-photography-of-asphalt-road-in-between-trees-8bs-Kz8ACdM',
          wikiLink: 'https://en.wikipedia.org/wiki/Moldova',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Chisinau',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-park-in-a-city-aRA508nQxH0',
          wikiLink: 'https://en.wikipedia.org/wiki/Chi%C8%99in%C4%83u',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Chisinau',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-with-many-windows-and-a-sky-background-Igm0ctfeaCU',
          wikiLink: 'https://en.wikipedia.org/wiki/Chi%C8%99in%C4%83u',
        },
      },
    ],
  },
  {
    name: 'Monaco',
    code: 'MC',
    lat: 43.73333333,
    long: 7.4,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/QvEULJG78U8',
          wikiLink: 'https://en.wikipedia.org/wiki/Fontvieille,_Monaco',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/monaco-city-bay-europe-france-410301/',
          wikiLink: 'https://en.wikipedia.org/wiki/Monaco',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Monaco',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-blue-boat-on-sea-during-daytime-1CkSNmbT7J0',
          wikiLink: 'https://en.wikipedia.org/wiki/Monaco',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Monaco',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/palm-trees-near-body-of-water-during-daytime-1DEdhNLCRvA',
          wikiLink: 'https://en.wikipedia.org/wiki/Monaco',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Monaco',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-docks-with-yachts-and-motorboats-during-daytime-GT4TGeuZZp0',
          wikiLink: 'https://en.wikipedia.org/wiki/Monaco',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Monaco City',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-near-white-concrete-building-during-daytime-SuOsl2q3kCA',
          wikiLink: 'https://en.wikipedia.org/wiki/Monaco_City',
        },
      },
    ],
  },
  {
    name: 'Mongolia',
    code: 'MN',
    lat: 46,
    long: 105,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/yhbanN00pb8',
          wikiLink: 'https://en.wikipedia.org/wiki/Yurt',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/desert-sand-dunes-mongolia-gobi-7630943/',
          wikiLink: 'https://en.wikipedia.org/wiki/Gobi_Desert',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Equestrian statue of Genghis Khan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/statue-hand-holding-gold-rod-8o9htwKDslc',
          wikiLink:
            'https://en.wikipedia.org/wiki/Equestrian_statue_of_Genghis_Khan',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Mongolia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-mountain-near-cliff-under-white-cloudy-sky-t3nn6PQv-CY',
          wikiLink: 'https://en.wikipedia.org/wiki/Mongolia',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Mongolia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-man-standing-on-top-of-a-rocky-mountain-OWDK5JeRlmU',
          wikiLink: 'https://en.wikipedia.org/wiki/Mongolia',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Ulaanbaatar',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-in-black-jacket-and-pants-standing-beside-man-statue-X2JmU9_1dL4',
          wikiLink: 'https://en.wikipedia.org/wiki/Ulaanbaatar',
        },
      },
    ],
  },
  {
    name: 'Montenegro',
    code: 'ME',
    lat: 42.5,
    long: 19.3,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/_nupO8tu6sg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bay_of_Kotor',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/perast-cove-kotor-montenegro-sea-4612526/',
          wikiLink: 'https://en.wikipedia.org/wiki/Montenegro',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Montenegro',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/animals-on-field-KYbDibssjXg',
          wikiLink: 'https://en.wikipedia.org/wiki/Montenegro',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Montenegro',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-building-near-body-of-water-during-daytime-CaMeI7mYvzo',
          wikiLink: 'https://en.wikipedia.org/wiki/Montenegro',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Montenegro',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-white-and-brown-cruise-ship-on-water-1AUe0hwdC3o',
          wikiLink: 'https://en.wikipedia.org/wiki/Montenegro',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Kotor',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/houses-near-body-of-water-during-daytime-b_e5K7B3MzQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Kotor',
        },
      },
    ],
  },
  {
    name: 'Montserrat',
    code: 'MS',
    lat: 16.75,
    long: -62.2,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/landscape-nature-moutains-panorama-3524568/',
          wikiLink: 'https://en.wikipedia.org/wiki/Montserrat',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/landscape-nature-park-water-7189086/',
          wikiLink: 'https://en.wikipedia.org/wiki/Montserrat',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Montserrat',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/5809290289',
          wikiLink: 'https://en.wikipedia.org/wiki/Montserrat',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Montserrat',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/rosco-photo/45066504401',
          wikiLink: 'https://en.wikipedia.org/wiki/Montserrat',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Montserrat',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-parked-on-the-side-of-a-road-in-front-of-a-mountain-c0bqpJHXlJI',
          wikiLink: 'https://en.wikipedia.org/wiki/Montserrat',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Plymouth',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/53069231925/in/photolist-Z6RTMC-2oRxFKk-2bFYi-Z6RU2W-2oRGS8C-9Rp2YU-2bFYr-Gf4LqH-FCwSqG-2bFYm-2eEnR-2eEo9-4pNzko-4pNzhh-2eEnL-4pJwCZ-Z6RUDh-9xL2Co-eVwjeQ-5U43im-9gWdiY-Z6RTrh-2bFYv-2bFYB-e48bp-2oRhMzH-9j7eVz-67onAK-2oRASVd-2n9umoG-2oQX2sL-s4R1y7-KLT7dN-2oRtP6R-2oRgJ5k-67ooaa-9j6AuT-9j5PyF-9j6ADR-9j9Jdo-9j5NWr-9sde8D-Pmvyvy-bTd54X-5GyaDi-5GyaCa-e8Epia-5GCszG',
          wikiLink: 'https://en.wikipedia.org/wiki/Plymouth,_Montserrat',
        },
      },
    ],
  },
  {
    name: 'Morocco',
    code: 'MA',
    lat: 32,
    long: -5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/NncAbldgViA',
          wikiLink: 'https://en.wikipedia.org/wiki/Chefchaouen',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/morocco-city-historic-village-clay-2349647/',
          wikiLink: 'https://en.wikipedia.org/wiki/Morocco',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Morocco',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-with-two-kids-riding-on-a-motorcycle-yPbHMWJTTOU',
          wikiLink: 'https://en.wikipedia.org/wiki/Morocco',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Chefchaouen',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-blue-alley-with-potted-plants-and-a-bench-CMmgfHQiYsc',
          wikiLink: 'https://en.wikipedia.org/wiki/Chefchaouen',
        },
      },
    ],
  },
  {
    name: 'Mozambique',
    code: 'MZ',
    lat: -18.25,
    long: 35,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/LbhNj3qARcM',
          wikiLink: 'https://en.wikipedia.org/wiki/Maputo',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/african-women-walking-along-road-2983081/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mozambique',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Mozambique',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/sailboat-on-sea-during-daytime-gO3uzl86USU',
          wikiLink: 'https://en.wikipedia.org/wiki/Mozambique',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Maputo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-white-building-sitting-on-the-side-of-a-road-Ak9PXE6QCPo',
          wikiLink: 'https://en.wikipedia.org/wiki/Maputo',
        },
      },
    ],
  },
  {
    name: 'Myanmar',
    code: 'MM',
    lat: 22,
    long: 98,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/a_-hYhh-R3Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Bagan',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/kuthodaw-pagoda-temple-city-pagoda-5632067/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuthodaw_Pagoda',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Myanmar',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-body-of-water-with-trees-and-buildings-in-the-background-6yOFqJM7zIs',
          wikiLink: 'https://en.wikipedia.org/wiki/Myanmar',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Naypyidaw',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/64193047@N08/12990354203/in/photolist-iigYRa-kMUXJc-2mgMA4R-229xNYi-226QK1U-E1GyMs-HcX2i6-214hP3N-HcX2JX-226QgRd-HcX3uz-E1GxQs-2grNk77-Cv2Nf8-214hN2j-226QiiS-HcX3BP-Cv2xnc-Cv2NRD-229xCUv-229xBHx-226Qhwm-226QDMC-226QGY7-226QGm5-226QC6b-E1Gycu-226QygN-214hW7s-226QAbu-229xKkX-HcX4m4-226QwLJ-E1GzxL-HcX1YZ-229xM6a-226Qxho-226QrKm-226QEw3-214hVcG-214hLc7-229xBbk-ZNeqfv-229xDxp-2ohF3ai-PeiGpe-EDhsWB-P6vXje-E1H16y-226QHTJ',
          wikiLink: 'https://en.wikipedia.org/wiki/Naypyidaw',
        },
      },
    ],
  },
  {
    name: 'Namibia',
    code: 'NA',
    lat: -22,
    long: 17,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/pWshkmAH4qA',
          wikiLink: 'https://en.wikipedia.org/wiki/Sandwich_Harbour',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sossusvlei-namib-namibia-sand-dune-5339728/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sossusvlei',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Namibia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-bench-near-window-mi9rbLeTj1A',
          wikiLink: 'https://en.wikipedia.org/wiki/Namibia',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Windhoek',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-man-riding-a-bike-down-a-street-next-to-a-wall-HqM70UrfhiQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Windhoek',
        },
      },
    ],
  },
  {
    name: 'Nauru',
    code: 'NR',
    lat: -0.53333333,
    long: 166.9166667,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/bjJU61GHALI',
          wikiLink: 'https://en.wikipedia.org/wiki/Nauru',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Living_on_a_Blue_Planet_-_Nauru.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Nauru',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Nauru',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/sea-water-and-rocks-by-the-beach-coast-during-daylight-bjJU61GHALI',
          wikiLink: 'https://en.wikipedia.org/wiki/Nauru',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Yaren',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/indynelson/50437637018/in/photolist-2jR5vrx-2jR15gm-2bqz6yy-MJUMkR-2pkBHwR',
          wikiLink: 'https://en.wikipedia.org/wiki/Yaren_District',
        },
      },
    ],
  },
  {
    name: 'Nepal',
    code: 'NP',
    lat: 28,
    long: 84,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/2bxAoXcfwwM',
          wikiLink: 'https://en.wikipedia.org/wiki/Pokhara',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mount-everest-mountains-trekking-6395759/',
          wikiLink: 'https://en.wikipedia.org/wiki/Nepal',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Nepal',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-car-on-road-near-green-mountains-during-daytime-_5-GEPZoza4',
          wikiLink: 'https://en.wikipedia.org/wiki/Nepal',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Kàtmandu',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-and-mountains-under-blue-sky-during-daytime-8__IZFB9AD8',
          wikiLink: 'https://en.wikipedia.org/wiki/Kathmandu',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Nepal',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/assorted-color-of-apparel-hanged-below-creek-across-glacier-mountain-dstd4DoLQ90',
          wikiLink: 'https://en.wikipedia.org/wiki/Nepal',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Kathmandu',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/woman-waking-away-front-the-hanging-decor-Y3u0xBXhGtY',
          wikiLink: 'https://en.wikipedia.org/wiki/Kathmandu',
        },
      },
    ],
  },
  {
    name: 'Netherlands',
    code: 'NL',
    lat: 52.5,
    long: 5.75,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/MaNZajl-IFY',
          wikiLink: 'https://en.wikipedia.org/wiki/Deventer',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/canal-buildings-city-114290/',
          wikiLink: 'https://en.wikipedia.org/wiki/Netherlands',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Amsterdam',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-bridge-over-a-body-of-water-with-buildings-in-the-background-b3l4P6PyCQg',
          wikiLink: 'https://en.wikipedia.org/wiki/Amsterdam',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Netherlands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-lake-surrounded-by-trees-in-a-park-xGa5-1BABr0',
          wikiLink: 'https://en.wikipedia.org/wiki/Netherlands',
        },
      },
    ],
  },
  {
    name: 'New Caledonia',
    code: 'NC',
    lat: -21.5,
    long: 165.5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/KDABcgfxFEM',
          wikiLink: 'https://en.wikipedia.org/wiki/New_Caledonia',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/new-caledonia-landscape-riverbed-101389/',
          wikiLink: 'https://en.wikipedia.org/wiki/New_Caledonia',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'New Caledonia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-body-of-water-with-a-building-in-the-background-g9TYsgqVZEI',
          wikiLink: 'https://en.wikipedia.org/wiki/New_Caledonia',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Nouméa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-the-ocean-from-a-hill-EyaW2XMu7qM',
          wikiLink: 'https://en.wikipedia.org/wiki/Noum%C3%A9a',
        },
      },
    ],
  },
  {
    name: 'New Zealand',
    code: 'NZ',
    lat: -41,
    long: 174,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/73F4pKoUkM0',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Tekapo',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/eagle-mountains-lake-reflection-1450672/',
          wikiLink: 'https://en.wikipedia.org/wiki/New_Zealand',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'New Zealand',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photography-of-house-between-flowers-v2lJbPVMqr8',
          wikiLink: 'https://en.wikipedia.org/wiki/New_Zealand',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'New Zealand',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/body-of-water-near-green-mountains-during-daytime-zWWq8J6igOo',
          wikiLink: 'https://en.wikipedia.org/wiki/New_Zealand',
        },
      },
    ],
  },
  {
    name: 'Nicaragua',
    code: 'NI',
    lat: 13,
    long: -85,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/7lXv17JzmpA',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Crist%C3%B3bal_Volcano',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/plantation-coffee-nicaragua-jungle-108987/',
          wikiLink: 'https://en.wikipedia.org/wiki/Nicaragua',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Nicaragua',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/pathway-of-houses-under-blue-clouds-during-daytime-Ye9MgFlD10E',
          wikiLink: 'https://en.wikipedia.org/wiki/Nicaragua',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Managua',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-bird-on-brown-tree-branch-during-daytime-jr8V04y_LTw',
          wikiLink: 'https://en.wikipedia.org/wiki/Managua',
        },
      },
    ],
  },
  {
    name: 'Niger',
    code: 'NE',
    lat: 16,
    long: 8,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/niger-evils-river-africa-436253/',
          wikiLink: 'https://en.wikipedia.org/wiki/Niger',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Niger,_Niamey,_Dunes_%283%29,_landscape.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Niamey',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Niger',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-dirt-road-in-the-middle-of-a-desert-4sRoy8CwJPw',
          wikiLink: 'https://en.wikipedia.org/wiki/Niger',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Niamey',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/350org/4058383234/in/photolist-7bCgWJ-mLbBW-6yJUhq-Y4PTgb-76jaet-2aDyu3W-5yz3g-7bRNe5-2SmzcV-4G2ZZT-nEzkMi-87tb9h-2kYHGiC-4FCL7t-68nDQe-68rRXC-68rRZS-68nDRT-joXvts-2SmzF4-8WPYHQ-4qZsyZ-pDwney-5CecZy-4qZmEP-9FgZgz-C3v6Sm-9N3AvX-XiXCFz-qfduFo-qfduGW-2mfiWVg-9NcFwm-dCfpgw-7jnWHa-7jrPvd-87tbVE-BNVgr-iQxS4C-7jnWWZ-7jnVWa-82jTEX-pkvvkG-pZW3Vy-6HvuJ4-6uq371-7jrPQU-7jnU3M-7jnXc8-Y8sbwx',
          wikiLink: 'https://en.wikipedia.org/wiki/Niamey',
        },
      },
    ],
  },
  {
    name: 'Nigeria',
    code: 'NG',
    lat: 10,
    long: 8,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/6UqJTfoXIq8',
          wikiLink: 'https://en.wikipedia.org/wiki/Lagos',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/vehicles-road-city-traffic-street-5871002/',
          wikiLink: 'https://en.wikipedia.org/wiki/Lagos',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Nigeria',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-tree-that-is-next-to-some-rocks-tSrmA33GnUQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Nigeria',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Lagos',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-standing-at-grey-concrete-road-tmz3Z1L1qaQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Lagos',
        },
      },
    ],
  },
  {
    name: 'Niue',
    code: 'NU',
    lat: -19.03333333,
    long: -169.8666667,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Niue_K%C3%BCstenlandschaft_2.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Niue',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Rockpools_and_cliff_at_Niue.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Niue',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Niue',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/nicktheworldtraveler/3763768900/in/photolist-6JAinf-6JA3bs-6JvVYg-6JvYap-2iYDbRw-h8vbn9-wcH5qJ-6Jyw9F-6JCLBS-6JB6WA-6JzPHY-6JyKtR-6JwFK6-KFSVJS-vxXcfw-6JzP1Q-6JCKxd-2iYDaHK-6JwJrD-6JzZFS-6JA5yh-6JAf47-6JAd4o-2iYDbf6-6JzSJN-6JA8NU-2iYyQzi-6JAHQE-vxY2XG-6JAKYW-wuiCsE-6JwfPr-wujXLR-vy6Dp8-6JB7YQ-6JAN7U-6JweFe-6JwgP6-6JCPeA-6JzTso-6Jw4PK-2h6BB4t-2iYDaKy-6JwcoT-2iYDc7m-2iYyQAA-2iYBAYc-2iYBARU-6JzUEG-2iYySe5',
          wikiLink: 'https://en.wikipedia.org/wiki/Niue',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Alofi',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/14197492@N07/22991592951/in/photolist-B2FVz2-MEUzpz-MEVwjK-3hHcho-LQTBgx-LQTatk-wv1nxk-MNfE7n-AnJZ9A-MNg4Ve-pS7vdo-LQT4ur-AJ67Aw-LQP45Y-pcRmVT-MEV5Gx-pcRpMK-LQT7FM-2jjcPUs-vVMTb1-MCf9QU-MNfACi-6JCAcG-6JCFAE-MCfbM9-Mz7Lmf-pcRocv-MEUBhn-q9v4Ei-MThLty-q7o9jQ-2jm9rRM-pBaeku-2jpmBPw-MF5twm-MThjnW-M6JZFG-2j5pGd5-q9y2Pu-qgAfCW-MX4wEy-2hqiuCo-M6NNfB-27NpEbE-qgzQv7-M6JXm1-pB9QMo-2jjcPXo-M6K5bd-MVSH5D',
          wikiLink: 'https://en.wikipedia.org/wiki/Alofi',
        },
      },
    ],
  },
  {
    name: 'Norfolk Island',
    code: 'NF',
    lat: -29.03333333,
    long: 167.95,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/coast-coastline-scenic-scenery-2446324/',
          wikiLink: 'https://en.wikipedia.org/wiki/Norfolk_Island',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/norfolk-island-islands-landscape-2446296/',
          wikiLink: 'https://en.wikipedia.org/wiki/Norfolk_Island',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Norfolk Island',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-small-white-bird-sitting-under-a-tree-D1T712fMFiM',
          wikiLink: 'https://en.wikipedia.org/wiki/Norfolk_Island',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Kingston',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/16938270@N00/53753733338/in/photolist-pSM4bP-2pU2WaQ-26UmQuk-2htGQ9H-2nZmoqC-2kLDp4E-JDVV7P-2oh7p1u-U9Vb6C-9NdigN-8z8Lwo-2kGBDtN-2pkqpLp-2osZvdd-TZ7RWb-2gipgp6-2h7bhPG-2h5EsY3-3z2QGq-2n55Mm2-AZsTUY-n86pEF-2kGBEMe-2kHnp7A-2kLDs9Y-24fdyu6-AYmkHq-ApZA3V-AnFvsP-UCTQYH-2kGBxbx-2kLrs3H-3yZzhE-2h68iW7-2kKKFq9-3DT8yE-2pxHf1h-2kGxTeU-2nzFHfF-AnFuoV-AZsXBN-2hqP6r4-V1wHsR-B2Cwdt',
          wikiLink: 'https://en.wikipedia.org/wiki/Kingston,_Norfolk_Island',
        },
      },
    ],
  },
  {
    name: 'North Korea',
    code: 'KP',
    lat: 40,
    long: 127,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/3dSv5LXts8A',
          wikiLink: 'https://en.wikipedia.org/wiki/Pyongyang',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/north-korea-soldiers-poor-force-2377251/',
          wikiLink: 'https://en.wikipedia.org/wiki/North_Korea',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'North Korea',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/roman-harak/5015266763/',
          wikiLink: 'https://en.wikipedia.org/wiki/North_Korea',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'North Korea',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-street-near-building-during-daytime-O4qX4vBSVAI',
          wikiLink: 'https://en.wikipedia.org/wiki/North_Korea',
        },
      },
    ],
  },
  {
    name: 'North Macedonia',
    code: 'MK',
    lat: 41.83333333,
    long: 22,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/4CzzaJnQh0c',
          wikiLink: 'https://en.wikipedia.org/wiki/Skopje',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/beach-boats-morning-landscape-5252448/',
          wikiLink: 'https://en.wikipedia.org/wiki/North_Macedonia',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'North Macedonia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-leafed-trees-eDzaur7RwFY',
          wikiLink: 'https://en.wikipedia.org/wiki/North_Macedonia',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Skopje',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-on-mountain-under-white-clouds-during-daytime-BRIrtLcf4YE',
          wikiLink: 'https://en.wikipedia.org/wiki/Skopje',
        },
      },
    ],
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MP',
    lat: 15.2,
    long: 145.75,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/saipan-island-beach-ocean-175425/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saipan',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/saipan-island-beach-ocean-175424/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saipan',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Northern Mariana Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/selective-focus-photography-of-kanji-script-lanterns-0PnQQ4hp_u8',
          wikiLink: 'https://en.wikipedia.org/wiki/Northern_Mariana_Islands',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Saipan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-rock-formation-on-sea-under-white-clouds-during-daytime-zCVGDMXRdeQ',
          wikiLink:
            'https://en.wikipedia.org/wiki/Saipan,_Northern_Mariana_Islands',
        },
      },
    ],
  },
  {
    name: 'Norway',
    code: 'NO',
    lat: 62,
    long: 10,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/khbjgGAerPU',
          wikiLink: 'https://en.wikipedia.org/wiki/Preikestolen',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mountains-sunset-clouds-estuary-5784152/',
          wikiLink: 'https://en.wikipedia.org/wiki/Norway',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Reine',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-houses-near-ocean-water-and-mountains-covered-with-green-leafed-tress-landscape-photography--yOZe-6UT1w',
          wikiLink: 'https://en.wikipedia.org/wiki/Reine',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Hovden',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-gray-house-near-mountain-under-cloud-LNrxP3CWEiQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Hovden,_Nordland',
        },
      },
    ],
  },
  {
    name: 'Oman',
    code: 'OM',
    lat: 21,
    long: 57,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/9R-jEko1PKc',
          wikiLink: 'https://en.wikipedia.org/wiki/Muttrah',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sultan-qaboos-grand-mosque-oman-5963726/',
          wikiLink: 'https://en.wikipedia.org/wiki/Oman',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Muscat',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-tower-near-body-of-water-during-daytime-RVA1h5Svl1U',
          wikiLink: 'https://en.wikipedia.org/wiki/Muscat',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Muscat',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-rock-formation-on-sea-under-blue-sky-during-daytime-ovfdH_3MudQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Muscat',
        },
      },
    ],
  },
  {
    name: 'Pakistan',
    code: 'PK',
    lat: 30,
    long: 70,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/KOwjW3N0Cto',
          wikiLink: 'https://en.wikipedia.org/wiki/Uch',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bridge-village-morning-khanpur-961208/',
          wikiLink: 'https://en.wikipedia.org/wiki/Pakistan',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Pakistan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-river-running-through-a-snowy-valley-KNtN9TLClVo',
          wikiLink: 'https://en.wikipedia.org/wiki/Pakistan',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Pakistan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-on-mountain-during-daytime-mE-Ls-jlb64',
          wikiLink: 'https://en.wikipedia.org/wiki/Pakistan',
        },
      },
    ],
  },
  {
    name: 'Palau',
    code: 'PW',
    lat: 7.5,
    long: 134.5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/0lCw_kqmb7w',
          wikiLink: 'https://en.wikipedia.org/wiki/Palau',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/island-water-resort-hotel-2407521/',
          wikiLink: 'https://en.wikipedia.org/wiki/Palau',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Palau',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/rocks-on-seashore-under-clear-blue-sky-at-daytime-V-DLKzBadVk',
          wikiLink: 'https://ro.wikipedia.org/wiki/Palau',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Ngerulmud',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/michael_from_berlin/53318574188/in/photolist-2pezCvS-2peGj57-2peGXsP-2peJLAH-2oJMnQE-2oJK16Q-2oJFWnj-2oJMnTk-2oJFWqL-2oJLUaM-2oJLTZb-2oJJZVe-2oJFWud-awCP6u-2iS9Krf-qq8P7z-2iS5nYK-2iS9KBR-2iS5nWW-2iS5o5M-DWvDJg-2iS5nTQ-a19tqY-a19trG-a16By2-6zEYHd-7TeGCb-6yfAbX-9YFn2Y-njEpxo-9Yt2tD-9YyMkn-2prgnwX-9YK3zU-6zEYgj-6zEYvG-9Yt2yK-6zEXTU-9YqvCn-9YK3Cy-9YBBff-6zEYAG-7XYefy-7Tbsft-9Yqv7r-9YtpXb-9YyMbr-6zARLB-26X319e-njz9yX',
          wikiLink: 'https://en.wikipedia.org/wiki/Ngerulmud',
        },
      },
    ],
  },
  {
    name: 'Palestine',
    code: 'PS',
    lat: 31.9,
    long: 35.2,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/jerusalem-israel-old-town-walls-1712855/',
          wikiLink: 'https://en.wikipedia.org/wiki/Jerusalem',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://pxhere.com/en/photo/1598181',
          wikiLink: 'https://en.wikipedia.org/wiki/Palestine_(region)',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Palestine',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-on-sea-near-red-and-white-lighthouse-under-white-clouds-during-daytime-l41hUeMwQ-8',
          wikiLink: 'https://en.wikipedia.org/wiki/State_of_Palestine',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Jerusalem',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-from-the-top-of-a-hill-lvyDnUkCmWw',
          wikiLink: 'https://en.wikipedia.org/wiki/Jerusalem',
        },
      },
    ],
  },
  {
    name: 'Panama',
    code: 'PA',
    lat: 9,
    long: -80,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/8oMiql-Q9nM',
          wikiLink: 'https://en.wikipedia.org/wiki/Casco_Viejo,_Panama',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/beach-san-blas-islands-panama-4388225/',
          wikiLink: 'https://en.wikipedia.org/wiki/Panama',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'San Blas Islands',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/canoe-on-beach-V-uYocR8k8k',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Blas_Islands',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Panama',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-floating-cottage-during-daytiem-FR3pMnh6xCA',
          wikiLink: 'https://en.wikipedia.org/wiki/Panama',
        },
      },
    ],
  },
  {
    name: 'Papua New Guinea',
    code: 'PG',
    lat: -6,
    long: 147,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/GOIR5V_OH-k',
          wikiLink: 'https://en.wikipedia.org/wiki/Papua_New_Guinea',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/rabaul-volcano-papua-new-guinea-4380826/',
          wikiLink: 'https://en.wikipedia.org/wiki/Papua_New_Guinea',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Papua New Guinea',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-on-island-surrounded-by-water-during-daytime-n1LrwXzsnuU',
          wikiLink: 'https://en.wikipedia.org/wiki/Papua_New_Guinea',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Papua New Guinea',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-person-riding-a-bike-down-the-middle-of-a-road-SrRfS_VQHeM',
          wikiLink: 'https://en.wikipedia.org/wiki/Papua_New_Guinea',
        },
      },
    ],
  },
  {
    name: 'Paraguay',
    code: 'PY',
    lat: -23,
    long: -58,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/5zqhGcQTMQU',
          wikiLink: 'https://en.wikipedia.org/wiki/Aregu%C3%A1',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/road-rain-automobile-woman-184623/',
          wikiLink: 'https://en.wikipedia.org/wiki/Paraguay',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Paraguay',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-boat-in-the-water-TYVAt_z5Zyw',
          wikiLink: 'https://en.wikipedia.org/wiki/Paraguay',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Paraguay',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-under-cloudy-sky-during-daytime-AZ7fAnvI3QY',
          wikiLink: 'https://en.wikipedia.org/wiki/Paraguay',
        },
      },
    ],
  },
  {
    name: 'Peru',
    code: 'PE',
    lat: -10,
    long: -76,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/hjanvZlqoB8',
          wikiLink: 'https://en.wikipedia.org/wiki/Vinicunca',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/peru-tourism-cuzco-landscape-5143632/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cusco',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Peru',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/body-of-water-with-green-mountains-during-daytime-hgxr9EbYZP0',
          wikiLink: 'https://en.wikipedia.org/wiki/Peru',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Machu Picchu',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-4-legged-farm-animal-standing-on-grass-ulUnRNuC_ok',
          wikiLink: 'https://en.wikipedia.org/wiki/Machu_Picchu',
        },
      },
    ],
  },
  {
    name: 'Philippines',
    code: 'PH',
    lat: 13,
    long: 122,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/n6BxzwOGTC4',
          wikiLink: 'https://en.wikipedia.org/wiki/Bontoc,_Mountain_Province',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/pacific-rainbow-ocean-clouds-4491547/',
          wikiLink: 'https://en.wikipedia.org/wiki/Philippines',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'El Nido',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/reynisdrangar-cliff-under-blue-sky-during-daytime-nuNkbkQQf9U',
          wikiLink: 'https://en.wikipedia.org/wiki/El_Nido,_Palawan',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Bohol',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-sandy-beach-with-palm-trees-in-the-background-lA-I1uW-xek',
          wikiLink: 'https://en.wikipedia.org/wiki/Bohol',
        },
      },
    ],
  },
  {
    name: 'Pitcairn Islands',
    code: 'PN',
    lat: -25.06666666,
    long: -130.1,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/doublenz/10531933983',
          wikiLink: 'https://en.wikipedia.org/wiki/Pitcairn_Islands',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Pitcairn_Island_In_The_Distance.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Pitcairn_Islands',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Pitcairn Islands',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/s_k_brown/48808216266/',
          wikiLink: 'https://en.wikipedia.org/wiki/Pitcairn_Islands',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Pitcairn Islands',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/peterfarr/27923976992',
          wikiLink: 'https://en.wikipedia.org/wiki/Pitcairn_Islands',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Pitcairn Islands',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/tonyprobst/5603232230/',
          wikiLink: 'https://en.wikipedia.org/wiki/Pitcairn_Islands',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Pitcairn Islands',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/up70mm/4867915775/',
          wikiLink: 'https://en.wikipedia.org/wiki/Pitcairn_Islands',
        },
      },
    ],
  },
  {
    name: 'Poland',
    code: 'PL',
    lat: 52,
    long: 20,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/i-p1zqFros8',
          wikiLink: 'https://en.wikipedia.org/wiki/Main_Square,_Krak%C3%B3w',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Gqc1jl2kd1U',
          wikiLink: 'https://en.wikipedia.org/wiki/Poland',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Kraków',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-near-beige-building-7rCdqEuQ1rg',
          wikiLink: 'https://en.wikipedia.org/wiki/Krak%C3%B3w',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Poland',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/black-horses-i-p1zqFros8',
          wikiLink: 'https://en.wikipedia.org/wiki/Poland',
        },
      },
    ],
  },
  {
    name: 'Portugal',
    code: 'PT',
    lat: 39.5,
    long: -8,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/ljhCEaHYWJ8',
          wikiLink: 'https://en.wikipedia.org/wiki/Trams_in_Lisbon',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/tram-train-road-building-monument-4379656/',
          wikiLink: 'https://en.wikipedia.org/wiki/Portugal',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Lisbon',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/train-passing-in-between-buildings-Bg8atM-RVuw',
          wikiLink: 'https://en.wikipedia.org/wiki/Lisbon',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Portugal',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-mountain-near-sea-cWdefpoj3PU',
          wikiLink: 'https://en.wikipedia.org/wiki/Portugal',
        },
      },
    ],
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
    lat: 18.25,
    long: -66.5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/48iZAbNgU8U',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Juan,_Puerto_Rico',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/graveyard-san-juan-puerto-rico-602357/',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Juan,_Puerto_Rico',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'San Juan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/city-near-body-of-water-during-daytime-48iZAbNgU8U',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Juan,_Puerto_Rico',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Arecibo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-and-trees-during-daytime-HaFV1cviJ_4',
          wikiLink: 'https://en.wikipedia.org/wiki/Arecibo,_Puerto_Rico',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Puerto Rico',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/blue-sea-under-white-clouds-at-daytime-UzlkIYRM8EE',
          wikiLink: 'https://en.wikipedia.org/wiki/Puerto_Rico',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Puerto Rico',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/red-bicycle-parked-beside-green-tree-during-daytime-Wi-dnjfkE_0',
          wikiLink: 'https://en.wikipedia.org/wiki/Puerto_Rico',
        },
      },
    ],
  },
  {
    name: 'Qatar',
    code: 'QA',
    lat: 25.5,
    long: 51.25,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Do6yoytec5E',
          wikiLink: 'https://en.wikipedia.org/wiki/Museum_of_Islamic_Art,_Doha',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/skyscrapers-building-architectural-3850732/',
          wikiLink: 'https://en.wikipedia.org/wiki/Doha',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Qatar',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/boats-in-the-water-1UF8ddEalwk',
          wikiLink: 'https://en.wikipedia.org/wiki/Qatar',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'The Pearl Island',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-tall-blue-building-with-palm-trees-in-front-of-it-huzHfOPHgXY',
          wikiLink: 'https://en.wikipedia.org/wiki/The_Pearl_Island',
        },
      },
    ],
  },
  {
    name: 'Republic of the Congo',
    code: 'CG',
    lat: -1,
    long: 15,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/File:Brazzaville_bird_eye_view.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Brazzaville',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/File:Pointe-Noire_downtown.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Pointe-Noire',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Republic of the Congo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-bus-parked-in-front-of-a-building-dJ8uDfnaZFc',
          wikiLink: 'https://en.wikipedia.org/wiki/Republic_of_the_Congo',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Republic of the Congo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-couple-of-boats-that-are-sitting-in-the-water-Bm_0FqhVPww',
          wikiLink: 'https://en.wikipedia.org/wiki/Republic_of_the_Congo',
        },
      },
    ],
  },
  {
    name: 'Reunion',
    code: 'RE',
    lat: -21.15,
    long: 55.5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/f9xoWEnLxlQ',
          wikiLink: 'https://en.wikipedia.org/wiki/R%C3%A9union',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/R%C3%A9union#/media/File:Ppalmistes2.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/R%C3%A9union',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Reunion',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-on-brown-rock-formation-near-body-of-water-during-daytime-ODHXC8W0RVg',
          wikiLink: 'https://en.wikipedia.org/wiki/R%C3%A9union',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Reunion',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-bridge-with-a-walkway-over-it-ooompEei8QE',
          wikiLink: 'https://en.wikipedia.org/wiki/R%C3%A9union',
        },
      },
    ],
  },
  {
    name: 'Romania',
    code: 'RO',
    lat: 46,
    long: 25,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/nKY59_d9FlA',
          wikiLink: 'https://en.wikipedia.org/wiki/Pele%C8%99_Castle',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/transilvania-romania-alpine-5632013/',
          wikiLink: 'https://en.wikipedia.org/wiki/Romania',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Romania',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-house-on-a-hill-8BDuTPoN_jA',
          wikiLink: 'https://en.wikipedia.org/wiki/Romania',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Romania',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-body-of-water-with-boats-and-trees-around-it-zLIMxP0SHZA',
          wikiLink: 'https://en.wikipedia.org/wiki/Romania',
        },
      },
    ],
  },
  {
    name: 'Russia',
    code: 'RU',
    lat: 60,
    long: 100,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/MEWRrCmEiGc',
          wikiLink: 'https://en.wikipedia.org/wiki/Moscow_State_University',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/caucasus-russia-elbrus-river-5302236/',
          wikiLink: 'https://en.wikipedia.org/wiki/Russia',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Izhevsk',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-with-a-playground-in-front-of-it-IZi2hSr_XKQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Izhevsk',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Russia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-of-forest-GWggAB8uAlA',
          wikiLink: 'https://en.wikipedia.org/wiki/Russia',
        },
      },
    ],
  },
  {
    name: 'Rwanda',
    code: 'RW',
    lat: -2,
    long: 30,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/riqHVFDBt-8',
          wikiLink: 'https://en.wikipedia.org/wiki/Rwanda',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/kigali-rwanda-africa-to-travel-5256022/',
          wikiLink: 'https://en.wikipedia.org/wiki/Rwanda',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Rwanda',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-goats-on-brown-grass-during-daytime-Edr2cBcMn-M',
          wikiLink: 'https://en.wikipedia.org/wiki/Rwanda',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Rwanda',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-woman-walking-through-a-lush-green-field-ZD6EipK6iOY',
          wikiLink: 'https://en.wikipedia.org/wiki/Rwanda',
        },
      },
    ],
  },
  {
    name: 'Saint Barthelemy',
    code: 'BL',
    lat: 17.896514, 
    long: -62.830696,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/shell-beach-saint-barth%c3%a9lemy-5140800/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Barth%C3%A9lemy',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Grotte_du_puits_Saint_Barth%C3%A9l%C3%A9my.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Barth%C3%A9lemy',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Saint Barthélemy',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-sandy-beach-with-a-hill-in-the-background-881zmv78Asw',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Barth%C3%A9lemy',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Saint Barthélemy',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-harbor-with-boats-in-the-water-zamjZ-wAjZk',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Barth%C3%A9lemy',
        },
      },
    ],
  },
  {
    name: 'Saint Helena',
    code: 'SH',
    lat: -24.143474,
    long: -10.030696,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:St-Helena-Jamestown.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Jamestown,_Saint_Helena',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:St_Helena,_view_south_over_%27Lot%27_toward_Sandy_Bay,_from_Sandy_Bay_Ridge,_1985-6._Peter_Neaum._-_panoramio.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Sandy_Bay,_Saint_Helena',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Saint Helena',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/hukalo/52757629502/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Helena',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Saint Helena',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/hukalo/52758574270/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Helena',
        },
      },
    ],
  },
  {
    name: 'Saint Kitts and Nevis',
    code: 'KN',
    lat: 17.33333333,
    long: -62.75,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cruise-ships-passengers-caribbean-6593595/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Kitts_and_Nevis',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/120420083@N05/13924571953',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Kitts_and_Nevis',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Saint Kitts and Nevis',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-body-of-water-with-a-mountain-in-the-background-650lErxpQZA',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Kitts_and_Nevis',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Saint Kitts and Nevis',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/house-near-trees-and-greenfield-xHJ5H3_AyeI',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Kitts_and_Nevis',
        },
      },
    ],
  },
  {
    name: 'Saint Lucia',
    code: 'LC',
    lat: 13.88333333,
    long: -60.96666666,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/st-lucia-caribbean-island-106119/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Lucia',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/st-lucia-caribbean-island-106120/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Lucia',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Saint Lucia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-mountains-pBnM1-mDR4A',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Lucia',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Saint Lucia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-cars-parked-in-front-of-a-body-of-water-5gfBxVCULiE',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Lucia',
        },
      },
    ],
  },
  {
    name: 'Saint Martin',
    code: 'MF',
    lat: 18.08333333,
    long: -63.95,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/airplane-maho-beach-saint-martin-2690200/',
          wikiLink: 'https://en.wikipedia.org/wiki/Maho_Beach',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Pinel_Island,_Saint_Martin.JPG',
          wikiLink:
            'https://en.wikipedia.org/wiki/List_of_Caribbean_islands#Collectivity_of_Saint_Martin',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Saint Martin',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-green-and-brown-mountains-beside-blue-sea-during-daytime-ofrgcQc1Plg',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Martin_(island)',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Saint Martin',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-beach-with-a-lot-of-people-on-it-948zYke541Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Martin_(island)',
        },
      },
    ],
  },
  {
    name: 'Saint Pierre and Miquelon',
    code: 'PM',
    lat: 46.83333333,
    long: -56.33333333,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/tibarama-caledonia-new-caledonia-6103043/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Pierre_and_Miquelon',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/danteaguiar/48350975052/in/photolist-2i1XMs-paMqWF-tNR3v-SybBmT-TJkcvy-TN16UB-SybCcF-SybByM-TJkcMA-SybyHg-Sybz72-SybCNa-TcXkyL-DsMekt-tNR3r-4foCKY-spg6vf-LaMFG7-EgW8gn-f6xb95-tNR3t-24sZttT-9jemf2-FWVGe8-GQyYH2-28eocaA-JDL2pK-G2W6Bk-GqvS83-GqxyPS-LaMHqY-JDL4Eg-28eoe8U-25xEth7-JDL5rM-LaMGPC-LaMz4h-28eo1Uf-LaMwUh-25xEtyu-LaMxQL-2gEBomU-28eoasN-FVckz2-LaML9d-JDL6RR-JDL1ak-2gqhEc7-2gqhX2q-dqSZQ4',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Pierre_and_Miquelon',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Saint Pierre and Miquelon',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/benandlarry/52974436753/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Pierre_and_Miquelon',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Saint Pierre and Miquelon',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/henryksadura/42922938230/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Pierre_and_Miquelon',
        },
      },
    ],
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: 'VC',
    lat: 13.25,
    long: -61.2,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/jetty-sea-boats-sunset-dock-6643909/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Saint_Vincent_and_the_Grenadines',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/shipwreck-sea-6643956/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Saint_Vincent_and_the_Grenadines',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Saint Vincent and the Grenadines',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-sandy-beach-with-palm-trees-on-a-sunny-day-6BpsiQs0dkk',
          wikiLink:
            'https://en.wikipedia.org/wiki/Saint_Vincent_and_the_Grenadines',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Saint Vincent and the Grenadines',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/black-and-white-whale-on-blue-water-fVn_l9Nfdd8',
          wikiLink:
            'https://en.wikipedia.org/wiki/Saint_Vincent_and_the_Grenadines',
        },
      },
    ],
  },
  {
    name: 'Samoa',
    code: 'WS',
    lat: -13.58333333,
    long: -172.3333333,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/palm-trees-beach-dream-beach-59/',
          wikiLink: 'https://en.wikipedia.org/wiki/Samoa',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/palm-trees-beach-dream-beach-52/',
          wikiLink: 'https://en.wikipedia.org/wiki/Samoa',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Samoa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-house-on-white-sand-under-white-clouds-during-daytime-BR1ixF95cGs',
          wikiLink: 'https://en.wikipedia.org/wiki/Samoa',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Samoa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/black-and-red-rooster-on-road-during-daytime-1K52ZwwDLD4',
          wikiLink: 'https://en.wikipedia.org/wiki/Samoa',
        },
      },
    ],
  },
  {
    name: 'San Marino',
    code: 'SM',
    lat: 43.76666666,
    long: 12.41666666,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/S1JVabNkXP0',
          wikiLink: 'https://en.wikipedia.org/wiki/Monte_Titano',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/san-marino-city-urban-buildings-1966566/',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Marino',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'San Marino',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-city-with-many-buildings-noMwZfUFghU',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Marino',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'San Marino',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-with-mountains-in-the-background-NQ30CZe_h_E',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Marino',
        },
      },
    ],
  },
  {
    name: 'Sao Tome and Principe',
    code: 'ST',
    lat: 1,
    long: 7,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Sao_Tome_Praia_Inhame_14_%2816247212021%29.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/S%C3%A3o_Tom%C3%A9_and_Pr%C3%ADncipe',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/43789968@N05/4056983982',
          wikiLink:
            'https://en.wikipedia.org/wiki/S%C3%A3o_Tom%C3%A9_and_Pr%C3%ADncipe',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Sao Tome and Principe',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/afdbprojects/49748624168/',
          wikiLink:
            'https://en.wikipedia.org/wiki/S%C3%A3o_Tom%C3%A9_and_Pr%C3%ADncipe',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Sao Tome and Principe',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/ximopons/49736970988/',
          wikiLink:
            'https://en.wikipedia.org/wiki/S%C3%A3o_Tom%C3%A9_and_Pr%C3%ADncipe',
        },
      },
    ],
  },
  {
    name: 'Saudi Arabia',
    code: 'SA',
    lat: 25,
    long: 45,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/rzzS0_pMsD0',
          wikiLink: 'https://en.wikipedia.org/wiki/Al-Masjid_an-Nabawi',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mosque-islam-saudi-arabia-islamic-2654552/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saudi_Arabia',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Saudi Arabia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/sand-dune-and-mountain-scenery-7vOpzRF33Zo',
          wikiLink: 'https://en.wikipedia.org/wiki/Saudi_Arabia',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Saudi Arabia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-row-of-palm-trees-with-mountains-in-the-background-ARpmY5qq7Lk',
          wikiLink: 'https://en.wikipedia.org/wiki/Saudi_Arabia',
        },
      },
    ],
  },
  {
    name: 'Senegal',
    code: 'SN',
    lat: 14,
    long: -14,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/-n1rIjE55Mw',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint-Louis,_Senegal',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/fisherman-mosque-dakar-senegal-246976/',
          wikiLink: 'https://en.wikipedia.org/wiki/Senegal',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Senegal',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/blue-and-white-boat-on-water-near-city-buildings-during-daytime--n1rIjE55Mw',
          wikiLink: 'https://en.wikipedia.org/wiki/Senegal',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Senegal',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-houses-near-green-trees-under-white-clouds-during-daytime-8nb36vQrjTc',
          wikiLink: 'https://en.wikipedia.org/wiki/Senegal',
        },
      },
    ],
  },
  {
    name: 'Serbia',
    code: 'RS',
    lat: 44,
    long: 21,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/xHnomVS0SiU',
          wikiLink: 'https://en.wikipedia.org/wiki/Belgrade',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/%c4%91erdap-serbia-castle-river-old-2129567/',
          wikiLink: 'https://en.wikipedia.org/wiki/Serbia',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Serbia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-body-of-water-surrounded-by-mountains-MyYE8w1Qgeo',
          wikiLink: 'https://en.wikipedia.org/wiki/Serbia',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Zlatibor',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-lake-surrounded-by-snow-covered-hills-and-trees-5dQdruCugWs',
          wikiLink: 'https://en.wikipedia.org/wiki/Zlatibor',
        },
      },
    ],
  },
  {
    name: 'Seychelles',
    code: 'SC',
    lat: -4.58333333,
    long: 55.66666666,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/HoNnEDKIar4',
          wikiLink: 'https://en.wikipedia.org/wiki/Seychelles',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/seychelles-landscape-sea-beach-4916045/',
          wikiLink: 'https://en.wikipedia.org/wiki/Seychelles',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'La Digue',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-beach-with-a-hut-on-it-Iqc0NV05YqQ',
          wikiLink: 'https://en.wikipedia.org/wiki/La_Digue',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Seychelles',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-on-beach-during-daytime-_ropNcPmpW8',
          wikiLink: 'https://en.wikipedia.org/wiki/Seychelles',
        },
      },
    ],
  },
  {
    name: 'Sierra Leone',
    code: 'SL',
    lat: 8.5,
    long: -11.5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/coast-sea-sierra-leone-island-6508040/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sierra_Leone',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sierra-leone-town-coast-sea-ocean-6508049/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sierra_Leone',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Sierra Leone',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-with-a-body-of-water-in-the-background-Vqd22Lb0rJc',
          wikiLink: 'https://en.wikipedia.org/wiki/Sierra_Leone',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Freetown',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-building-on-a-rocky-hill-7S028lQqTSs',
          wikiLink: 'https://en.wikipedia.org/wiki/Freetown',
        },
      },
    ],
  },
  {
    name: 'Singapore',
    code: 'SG',
    lat: 1.36666666,
    long: 103.8,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/McM5BW4jQHc',
          wikiLink: 'https://en.wikipedia.org/wiki/Gardens_by_the_Bay',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/buildings-skyline-city-lights-647400/',
          wikiLink: 'https://en.wikipedia.org/wiki/Singapore',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Singapore',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/multicolored-lights-on-posts-4HVCsDOg0qI',
          wikiLink: 'https://en.wikipedia.org/wiki/Singapore',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Singapore',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-at-the-market-C8gvt2LMX94',
          wikiLink: 'https://en.wikipedia.org/wiki/Singapore',
        },
      },
    ],
  },
  {
    name: 'Slovakia',
    code: 'SK',
    lat: 48.66666666,
    long: 19.5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/vgPgg8X2NyA',
          wikiLink: 'https://en.wikipedia.org/wiki/Bratislava',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/tatra-mountains-slovakia-lake-7231545/',
          wikiLink: 'https://en.wikipedia.org/wiki/Slovakia',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Slovakia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-covered-mountains-RErhr8r1q6s',
          wikiLink: 'https://en.wikipedia.org/wiki/Slovakia',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Slovakia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-house-on-snow-covered-ground-during-daytime-krp9O-SbTTA',
          wikiLink: 'https://en.wikipedia.org/wiki/Slovakia',
        },
      },
    ],
  },
  {
    name: 'Slovenia',
    code: 'SI',
    lat: 46.11666666,
    long: 14.81666666,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/W3FC_bCPw8E',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Bled',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/ljubljana-flow-slovenia-bridge-2149704/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ljubljana',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Slovenia',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/view-of-mountain-sQLri8iHb58',
          wikiLink: 'https://en.wikipedia.org/wiki/Slovenia',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Lake Bled',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/high-angle-photography-of-white-building-surrounded-by-body-of-water-at-daytime-34RmaD-jVbY',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Bled',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Slovenia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/island-surrounded-by-water-and-mountains-at-daytime-pOWBHdgy1Lo',
          wikiLink: 'https://en.wikipedia.org/wiki/Slovenia',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Slovenia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-and-mountains-under-blue-sky-and-white-clouds-during-daytime-vEArMceumaI',
          wikiLink: 'https://en.wikipedia.org/wiki/Slovenia',
        },
      },
    ],
  },
  {
    name: 'Solomon Islands',
    code: 'SB',
    lat: -8,
    long: 159,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/o7oV-rGy53Y',
          wikiLink: 'https://en.wikipedia.org/wiki/Solomon_Islands',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/the-solomon-islands-pacific-4573372/',
          wikiLink: 'https://en.wikipedia.org/wiki/Solomon_Islands',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Solomon Islands',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/adelaide_archivist/9526168535/',
          wikiLink: 'https://en.wikipedia.org/wiki/Solomon_Islands',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Solomon Islands',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/152791564@N03/40306538034/',
          wikiLink: 'https://en.wikipedia.org/wiki/Solomon_Islands',
        },
      },
    ],
  },
  {
    name: 'Somalia',
    code: 'SO',
    lat: 10,
    long: 49,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/I7c0n1_7dZs',
          wikiLink: 'https://en.wikipedia.org/wiki/Mogadishu',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/river-somalia-water-5049398/',
          wikiLink: 'https://en.wikipedia.org/wiki/Somalia',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Somalia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-rocky-beach-with-a-building-in-the-background-I7c0n1_7dZs',
          wikiLink: 'https://en.wikipedia.org/wiki/Somalia',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Somalia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-rock-formation-beside-body-of-water-during-daytime-dfoAUysuiBI',
          wikiLink: 'https://en.wikipedia.org/wiki/Somalia',
        },
      },
    ],
  },
  {
    name: 'South Africa',
    code: 'ZA',
    lat: -29,
    long: 24,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/1GgWbP74phY',
          wikiLink: 'https://en.wikipedia.org/wiki/Cape_Town',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/capetown-beach-sunset-aerial-view-2295679/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cape_Town',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'South Africa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/body-of-water-city-and-mountain-J-yDj62KSxE',
          wikiLink: 'https://en.wikipedia.org/wiki/South_Africa',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'South Africa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/african-penguins-on-seashore-beside-boulder-UjpEGHu8uNU',
          wikiLink: 'https://en.wikipedia.org/wiki/South_Africa',
        },
      },
    ],
  },
  {
    name: 'South Georgia',
    code: 'GS',
    lat: -54.5,
    long: -37,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Z3EwKKX513k',
          wikiLink:
            'https://en.wikipedia.org/wiki/South_Georgia_and_the_South_Sandwich_Islands',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/south-georgia-king-penguins-4709461/',
          wikiLink:
            'https://en.wikipedia.org/wiki/South_Georgia_and_the_South_Sandwich_Islands',
        },
      },
    ],
  },
  {
    name: 'South Korea',
    code: 'KR',
    lat: 37,
    long: 127.5,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/xRAEPmP9M8s',
          wikiLink: 'https://en.wikipedia.org/wiki/Haedong_Yonggungsa',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/business-bar-restaurant-shield-5475283/',
          wikiLink: 'https://en.wikipedia.org/wiki/Seoul',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'South Korea',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-body-of-water-surrounded-by-a-lush-green-hillside-EVtsYVN6r7U',
          wikiLink: 'https://en.wikipedia.org/wiki/South_Korea',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'South Korea',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-with-lots-of-tall-buildings-in-the-background-2SfRk-QaY40',
          wikiLink: 'https://en.wikipedia.org/wiki/South_Korea',
        },
      },
    ],
  },
  {
    name: 'South Sudan',
    code: 'SS',
    lat: 7,
    long: 30,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Juba#/media/File:John_Garang_Mausoleum_Square_in_Juba.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Juba',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://commons.wikimedia.org/wiki/File:JUBA_VIEW.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Juba',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'South Sudan',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/worldhumanitariansummit/27097432156/',
          wikiLink: 'https://en.wikipedia.org/wiki/South_Sudan',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'South Sudan',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/white-aircraft-7MXH1282qaM',
          wikiLink: 'https://en.wikipedia.org/wiki/South_Sudan',
        },
      },
    ],
  },
  {
    name: 'Spain',
    code: 'ES',
    lat: 40,
    long: -4,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/PWrNP4bQHB4',
          wikiLink:
            'https://en.wikipedia.org/wiki/Plaza_de_Espa%C3%B1a,_Seville',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/valencia-spain-calatrava-sunset-1049389/',
          wikiLink: 'https://en.wikipedia.org/wiki/Valencia',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Granada',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/group-of-people-sitting-on-grass-field-during-daytime-hTTtWbtQQNY',
          wikiLink: 'https://en.wikipedia.org/wiki/Granada',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Consuegra',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photo-of-a-row-of-windmills-k7I-Ci7Sq7c',
          wikiLink: 'https://en.wikipedia.org/wiki/Consuegra',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Casares',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-white-houses-ovgRb5WLWMQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Casares,_M%C3%A1laga',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Spain',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-on-gray-rock-mountain-beside-body-of-water-eWFdaPRFjwE',
          wikiLink: 'https://en.wikipedia.org/wiki/Spain',
        },
      },
    ],
  },
  {
    name: 'Sri Lanka',
    code: 'LK',
    lat: 7,
    long: 81,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/smUAKwMT8XA',
          wikiLink: 'https://en.wikipedia.org/wiki/Sigiriya',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/tower-building-road-lotus-tower-7314495/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sri_Lanka',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Sri Lanka',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-rock-island-6gv2F4-Hpds',
          wikiLink: 'https://en.wikipedia.org/wiki/Sri_Lanka',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Sri Lanka',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-near-mountain-during-daytime-PTw2xsseQxM',
          wikiLink: 'https://en.wikipedia.org/wiki/Sri_Lanka',
        },
      },
    ],
  },
  {
    name: 'Sudan',
    code: 'SD',
    lat: 15,
    long: 30,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/9IafO_YOVik',
          wikiLink: 'https://en.wikipedia.org/wiki/Nile',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/city-townscape-horizon-line-3229028/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sudan',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Atbara',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-brick-building-with-a-balcony-and-balcony-railing-xks4AnfgxJ8',
          wikiLink: 'https://en.wikipedia.org/wiki/Atbara',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Sudan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-buildings-during-sunset-9IafO_YOVik',
          wikiLink: 'https://en.wikipedia.org/wiki/Sudan',
        },
      },
    ],
  },
  {
    name: 'Suriname',
    code: 'SR',
    lat: 4,
    long: -56,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/amazon-suriname-south-america-265260/',
          wikiLink: 'https://en.wikipedia.org/wiki/Suriname',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/water-boat-travel-vacation-3338367/',
          wikiLink: 'https://en.wikipedia.org/wiki/Suriname',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Suriname',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/143274264@N07/25367857139/',
          wikiLink: 'https://en.wikipedia.org/wiki/Suriname',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Suriname',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/driftwood-on-shore-oKyF4ZYUo2k',
          wikiLink: 'https://en.wikipedia.org/wiki/Suriname',
        },
      },
    ],
  },
  {
    name: 'Svalbard and Jan Mayen',
    code: 'SJ',
    lat: 77.553604,
    long: 23.670272,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/NmXaYwkw560',
          wikiLink: 'https://en.wikipedia.org/wiki/Svalbard_and_Jan_Mayen',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/snow-mountain-winter-hiking-1185467/',
          wikiLink: 'https://en.wikipedia.org/wiki/Svalbard',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Svalbard and Jan Mayen',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-bunch-of-animals-that-are-laying-in-the-snow-B89HI-j1k6M',
          wikiLink: 'https://en.wikipedia.org/wiki/Svalbard_and_Jan_Mayen',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Svalbard and Jan Mayen',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/high-angle-photo-of-landscape-NqankxAMqxs',
          wikiLink: 'https://en.wikipedia.org/wiki/Svalbard_and_Jan_Mayen',
        },
      },
    ],
  },
  {
    name: 'Sweden',
    code: 'SE',
    lat: 62,
    long: 15,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/58X3XfxxevU',
          wikiLink: 'https://en.wikipedia.org/wiki/Abisko_National_Park',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sweden-clouds-travel-scenery-4104028/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sweden',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Sweden',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photography-of-shacks-near-body-of-water-L7pz0YUnKdg',
          wikiLink: 'https://en.wikipedia.org/wiki/Sweden',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Sweden',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/body-of-water-between-trees-under-cloudy-sky-rB7-LCa_diU',
          wikiLink: 'https://en.wikipedia.org/wiki/Sweden',
        },
      },
    ],
  },
  {
    name: 'Switzerland',
    code: 'CH',
    lat: 47,
    long: 8,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/BYPMtjYMEyk',
          wikiLink: 'https://en.wikipedia.org/wiki/Lauterbrunnen',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/buildings-city-architecture-bern-6262595/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bern',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Switzerland',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/black-painted-house-in-the-middle-of-the-trees-zlV1Ksvifvs',
          wikiLink: 'https://en.wikipedia.org/wiki/Switzerland',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Zermatt',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/houses-near-road-and-mountain-under-blue-sky-during-daytime-photography-SpEQIUw7_TQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Zermatt',
        },
      },
    ],
  },
  {
    name: 'Syria',
    code: 'SY',
    lat: 35,
    long: 38,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/syria-apamea-roman-ruins-heaven-4033538/',
          wikiLink: 'https://en.wikipedia.org/wiki/Apamea,_Syria',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/syria-aleppo-citadel-entrance-1886425/',
          wikiLink: 'https://en.wikipedia.org/wiki/Aleppo',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Kessab',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-the-ocean-from-the-top-of-a-hill-MVvs9JiR3W0',
          wikiLink: 'https://en.wikipedia.org/wiki/Kessab',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Syria',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-with-a-hill-in-the-background-Iq2nsH2xNEI',
          wikiLink: 'https://en.wikipedia.org/wiki/Syria',
        },
      },
    ],
  },
  {
    name: 'Taiwan',
    code: 'TW',
    lat: 23.5,
    long: 121,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/UDv1n0xIpU8',
          wikiLink: 'https://en.wikipedia.org/wiki/Jiufen',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cityscape-city-city-lights-evening-5543224/',
          wikiLink: 'https://en.wikipedia.org/wiki/Taiwan',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Taiwan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-and-red-pagoda-temple-ZAwMMAoabrU',
          wikiLink: 'https://en.wikipedia.org/wiki/Taiwan',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Taiwan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-near-mountain-under-white-sky-during-daytime-L6xHmv2R3G4',
          wikiLink: 'https://en.wikipedia.org/wiki/Taiwan',
        },
      },
    ],
  },
  {
    name: 'Tajikistan',
    code: 'TJ',
    lat: 39,
    long: 71,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/zot26dCO_4I',
          wikiLink: 'https://en.wikipedia.org/wiki/Pamir_Mountains',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/tajikistan-iskanderkul-alex-andersee-4584690/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tajikistan',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Hisar ',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-castle-through-two-brick-walls-4MLxMrsSEaA',
          wikiLink: 'https://en.wikipedia.org/wiki/Hisar_(Tajikistan)',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Tajikistan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-white-mountain-under-blue-sky-during-daytime-lywaqpgB9sg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tajikistan',
        },
      },
    ],
  },
  {
    name: 'Tanzania',
    code: 'TZ',
    lat: -6,
    long: 35,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/B8QoatPKAJg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Ngorongoro_Conservation_Area',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mount-kilimanjaro-mountain-mount-7287226/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tanzania',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Dar es Salaam',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-at-night-from-a-window-07MPxe8sEEU',
          wikiLink: 'https://en.wikipedia.org/wiki/Dar_es_Salaam',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Tanzania',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/photo-of-mountain-with-brown-soil-in-vicinity-UNOMwllEXfg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tanzania',
        },
      },
    ],
  },
  {
    name: 'Thailand',
    code: 'TH',
    lat: 15,
    long: 100,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/BU6lABNbTpA',
          wikiLink: 'https://en.wikipedia.org/wiki/Phi_Phi_Islands',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/grand-palace-bangkok-temple-night-1822487/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bangkok',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Ao Nang',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/photo-of-brown-bench-near-swimming-pool-Koei_7yYtIo',
          wikiLink: 'https://en.wikipedia.org/wiki/Ao_Nang',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Phi Phi Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-body-of-water-surrounded-with-mountains-BU6lABNbTpA',
          wikiLink: 'https://en.wikipedia.org/wiki/Phi_Phi_Islands',
        },
      },
    ],
  },
  {
    name: 'Timor-Leste',
    code: 'TL',
    lat: -8.83333333,
    long: 125.9166667,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mangrove-timor-leste-atauro-tree-7507812/',
          wikiLink: 'https://en.wikipedia.org/wiki/East_Timor',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Timor-Leste_Coastline.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/East_Timor',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Timor-Leste',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-beach-with-a-clock-tower-pxJkmLhBhCc',
          wikiLink: 'https://en.wikipedia.org/wiki/East_Timor',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Liquiçá',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-beach-and-ocean-52CdMdfFhxE',
          wikiLink: 'https://en.wikipedia.org/wiki/Liqui%C3%A7%C3%A1',
        },
      },
    ],
  },
  {
    name: 'Togo',
    code: 'TG',
    lat: 8,
    long: 1.16666666,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/africa-togo-village-6170643/',
          wikiLink: 'https://en.wikipedia.org/wiki/Togo',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/africa-togo-village-6170633/',
          wikiLink: 'https://en.wikipedia.org/wiki/Togo',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Togo',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/153562252@N05/49570659711/',
          wikiLink: 'https://en.wikipedia.org/wiki/Togo',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Togo',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/faceofclimate/8735553697/',
          wikiLink: 'https://en.wikipedia.org/wiki/Togo',
        },
      },
    ],
  },
  {
    name: 'Tokelau',
    code: 'TK',
    lat: -9,
    long: -172,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Tokelau_barge.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Tokelau',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Aerial-view-tokelau-2012-photo-new-zealand-ministry-of-foreign-affairs-and-trade_12780239164_o.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tokelau',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Tokelau',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/72923065@N00/2930850430/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tokelau',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Tokelau',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/raobhask/3409238336/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tokelau',
        },
      },
    ],
  },
  {
    name: 'Tonga',
    code: 'TO',
    lat: -20,
    long: -175,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://www.britannica.com/place/Tonga/Economy',
          wikiLink: 'https://en.wikipedia.org/wiki/Tonga',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/shipwreck-ocean-sea-rusty-3821992/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tonga',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Tonga',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/10869636@N08/14699199006/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tonga',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Tonga',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/liz_detheo/4074850751/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tonga',
        },
      },
    ],
  },
  {
    name: 'Trinidad and Tobago',
    code: 'TT',
    lat: 11,
    long: -61,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/DEMrRuvJvqo',
          wikiLink: 'https://en.wikipedia.org/wiki/Port_of_Spain',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/water-nature-landscape-panoramic-3274999/',
          wikiLink: 'https://en.wikipedia.org/wiki/Trinidad_and_Tobago',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Trinidad and Tobago',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-city-with-a-mountain-in-the-background-5eYQc8J2WGc',
          wikiLink: 'https://en.wikipedia.org/wiki/Trinidad_and_Tobago',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Trinidad and Tobago',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/house-on-shore-during-day-UCKqi8xqIOc',
          wikiLink: 'https://en.wikipedia.org/wiki/Trinidad_and_Tobago',
        },
      },
    ],
  },
  {
    name: 'Tunisia',
    code: 'TN',
    lat: 34,
    long: 9,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/5NqUXa6pZDM',
          wikiLink: 'https://en.wikipedia.org/wiki/Sidi_Bou_Said',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/tunis-tunisia-cityscape-city-106951/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tunis',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Tunisia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-camel-on-desert-during-daytime-KZrumAkJWnQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Tunisia',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Tunis',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-white-and-brown-concrete-building-during-daytime-SIvfVmejLyY',
          wikiLink: 'https://en.wikipedia.org/wiki/Tunis',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Sidi Bou Said',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-near-white-and-blue-seaside-resort-viewing-blue-sea-under-blue-and-white-sky-RRHmfYRIXXo',
          wikiLink: 'https://en.wikipedia.org/wiki/Sidi_Bou_Said',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Tunisia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-with-domed-roofs-with-kairouan-in-the-background-CHkhYa4aI-A',
          wikiLink: 'https://en.wikipedia.org/wiki/Tunisia',
        },
      },
    ],
  },
  {
    name: 'Turkey',
    code: 'TR',
    lat: 39,
    long: 35,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/bBF9e2UUh88',
          wikiLink: 'https://en.wikipedia.org/wiki/Cappadocia',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/river-sunset-maiden-s-tower-views-4873198/',
          wikiLink: 'https://en.wikipedia.org/wiki/Istanbul',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Cappadocia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/hot-air-balloon-contest-t7YycgAoVSw',
          wikiLink: 'https://en.wikipedia.org/wiki/Cappadocia',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'İstanbul',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-white-concrete-building-under-blue-sky-during-daytime-T3jvNXWWpy0',
          wikiLink: 'https://en.wikipedia.org/wiki/Istanbul',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Halfeti',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/photography-of-tower-and-buildings-tR05vRFVa-4',
          wikiLink: 'https://en.wikipedia.org/wiki/Halfeti',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Turkey',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-mountains-near-body-of-water-during-daytime-8nVmfffGh5k',
          wikiLink: 'https://en.wikipedia.org/wiki/Turkey',
        },
      },
    ],
  },
  {
    name: 'Turkmenistan',
    code: 'TM',
    lat: 40,
    long: 60,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Turkmenistan-Ashgabat.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ashgabat',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://commons.wikimedia.org/wiki/File:Turkmenistan.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Turkmenistan',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Turkmenistan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-deserted-place-with-hole-in-middle-VOinFS9FDkQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Turkmenistan',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Turkmenistan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/mountain-covered-with-snow-view-WdKBrVAKN4U',
          wikiLink: 'https://en.wikipedia.org/wiki/Turkmenistan',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Ashkhabad',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-stone-walkway-with-a-pergolated-arbor-in-the-middle-of-it-PcETEa8v6MI',
          wikiLink: 'https://en.wikipedia.org/wiki/Ashgabat',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Awaza',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-bridge-over-the-sea-during-daytime-ew2LSkOXuqc',
          wikiLink:
            'https://unsplash.com/photos/white-bridge-over-the-sea-during-daytime-ew2LSkOXuqc',
        },
      },
    ],
  },
  {
    name: 'Turks and Caicos Islands',
    code: 'TC',
    lat: 21.75,
    long: -71.58333333,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/turks-caicos-island-teal-water-2307310/',
          wikiLink: 'https://en.wikipedia.org/wiki/Turks_and_Caicos_Islands',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/turks-and-caicos-beach-caicos-ocean-1220019/',
          wikiLink: 'https://en.wikipedia.org/wiki/Turks_and_Caicos_Islands',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Turks and Caicos Islands',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/coconut-trees-mW5oo5llyoY',
          wikiLink: 'https://en.wikipedia.org/wiki/Turks_and_Caicos_Islands',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Turks and Caicos Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/birds-eye-view-of-dock-and-house-gXGSp7-KUQQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Turks_and_Caicos_Islands',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Turks and Caicos Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-near-blue-sea-under-blue-and-white-cloudy-sky-during-daytime-erbh0VzctyE',
          wikiLink: 'https://en.wikipedia.org/wiki/Turks_and_Caicos_Islands',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Turks and Caicos Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-in-swimming-pool-during-daytime-ynHcCuvABCg',
          wikiLink: 'https://en.wikipedia.org/wiki/Turks_and_Caicos_Islands',
        },
      },
    ],
  },
  {
    name: 'Tuvalu',
    code: 'TV',
    lat: -8,
    long: 178,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sea-ocean-water-beach-nature-384055/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tuvalu',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/dfataustralianaid/12779525995',
          wikiLink: 'https://en.wikipedia.org/wiki/Tuvalu',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Tuvalu',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/asiandevelopmentbank/32895329278',
          wikiLink: 'https://en.wikipedia.org/wiki/Tuvalu',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Tuvalu',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/mrlins/304276123',
          wikiLink: 'https://en.wikipedia.org/wiki/Tuvalu',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Tuvalu',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/michael_from_berlin/52829984615/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tuvalu',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Tuvalu',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/gosha_krzystkiewicz/4519730272/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tuvalu',
        },
      },
    ],
  },
  {
    name: 'Uganda',
    code: 'UG',
    lat: 1,
    long: 32,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/murchison-national-park-uganda-2111157/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Murchison_Falls_National_Park',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/lake-victoria-beach-africa-uganda-2108871/',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Victoria',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Kampala',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/silhouette-of-people-riding-boat-on-sea-during-daytime-j9kIRsK8jqU',
          wikiLink: 'https://en.wikipedia.org/wiki/Kampala',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Fort Portal',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-on-mountain-during-daytime-8PF8fl6e6yE',
          wikiLink: 'https://en.wikipedia.org/wiki/Fort_Portal',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Uganda',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/high-angle-photography-of-lake-at-the-forest-wrM9TOVDSrs',
          wikiLink: 'https://en.wikipedia.org/wiki/Uganda',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Lake Bunyonyi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-hills-and-trees-KQ5djKAN35s',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Bunyonyi',
        },
      },
    ],
  },
  {
    name: 'Ukraine',
    code: 'UA',
    lat: 49,
    long: 32,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/a6jaMBfDeoo',
          wikiLink: 'https://en.wikipedia.org/wiki/Kyiv_Pechersk_Lavra',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/river-buildings-street-kiev-6824576/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kyiv',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Ukraine',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/hays-on-field--YGqltMewrI',
          wikiLink: 'https://en.wikipedia.org/wiki/Ukraine',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Ukraine',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/fCOgPYP_bHU',
          wikiLink: 'https://en.wikipedia.org/wiki/Ukraine',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Odesa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-white-concrete-building-jTboXfpKqUA',
          wikiLink: 'https://en.wikipedia.org/wiki/Odesa',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Kyiv',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-truck-is-parked-in-front-of-a-windmill-bUgP0IWJRPU',
          wikiLink: 'https://en.wikipedia.org/wiki/Kyiv',
        },
      },
    ],
  },
  {
    name: 'United Arab Emirates',
    code: 'AE',
    lat: 24,
    long: 54,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Fr6zexbmjmc',
          wikiLink: 'https://en.wikipedia.org/wiki/Burj_Khalifa',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/downtown-dubai-uae-tourism-city-4045035/',
          wikiLink: 'https://en.wikipedia.org/wiki/Dubai',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Dubai',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/city-during-day-VbDjv8-8ibc',
          wikiLink: 'https://en.wikipedia.org/wiki/Dubai',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'United Arab Emirates',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/vehicles-on-desert-during-daytime-nEENSEbLIsY',
          wikiLink: 'https://en.wikipedia.org/wiki/United_Arab_Emirates',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'United Arab Emirates',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-sitting-on-top-of-a-beach-next-to-a-body-of-water-tKWrQTywzJ8',
          wikiLink: 'https://en.wikipedia.org/wiki/United_Arab_Emirates',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'United Arab Emirates',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-blue-airplane-on-brown-field-during-daytime-5hz4B8WBLxc',
          wikiLink: 'https://en.wikipedia.org/wiki/United_Arab_Emirates',
        },
      },
    ],
  },
  {
    name: 'United Kingdom',
    code: 'GB',
    lat: 54,
    long: -2,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/PhBF73HeEVM',
          wikiLink: 'https://en.wikipedia.org/wiki/Lairg',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/castle-building-architecture-7728772/',
          wikiLink: 'https://en.wikipedia.org/wiki/Edinburgh',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Glenfinnan Viaduct',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/train-on-bridge-surrounded-with-trees-at-daytime-XVoyX7l9ocY',
          wikiLink: 'https://en.wikipedia.org/wiki/Glenfinnan_Viaduct',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Eilean Donan Castle',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-castle-on-shore-ZHF033ykCx8',
          wikiLink: 'https://en.wikipedia.org/wiki/Eilean_Donan',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Belfast',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/two-person-on-street-near-trees-9lrWn9uo68A',
          wikiLink: 'https://en.wikipedia.org/wiki/Belfast',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Edinburgh',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-parked-near-multi-color-buildings-Ipln10Lk_WY',
          wikiLink: 'https://en.wikipedia.org/wiki/Edinburgh',
        },
      },
    ],
  },
  {
    name: 'United States',
    code: 'US',
    lat: 38,
    long: -97,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/-rNlq4IN_1k',
          wikiLink: 'https://en.wikipedia.org/wiki/United_states',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/big-bend-texas-landscape-scenic-113099/',
          wikiLink: 'https://en.wikipedia.org/wiki/Big_Bend_(Texas)',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Golden Gate Bridge',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/golden-gate-bridge-california-o4mP43oPGHk',
          wikiLink: 'https://en.wikipedia.org/wiki/Golden_Gate_Bridge',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Granite Falls',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-pine-trees-near-body-of-water-during-daytime-yf0v_jHm9Ds',
          wikiLink: 'https://en.wikipedia.org/wiki/Granite_Falls,_Washington',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Montana',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-under-white-clouds-during-daytime-KQSCcRim-s8',
          wikiLink: 'https://en.wikipedia.org/wiki/Montana',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'New York',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-building-with-graffiti-on-the-side-of-it-XFXz574267k',
          wikiLink: 'https://en.wikipedia.org/wiki/New_York_City',
        },
      },
    ],
  },
  {
    name: 'United States Virgin Islands',
    code: 'VI',
    lat: 18.35,
    long: -64.933333,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Magens_Bay,_St._Thomas,_USVI.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Magens_Bay',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Cruz_Bay,_Saint_John;_United_States_Virgin_Islands.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Cruz_Bay,_U.S._Virgin_Islands',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'United States Virgin Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/grayscale-photo-of-beach-shore-99eHoxAvneI',
          wikiLink:
            'https://en.wikipedia.org/wiki/United_States_Virgin_Islands',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'St. Thomas',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-bird-flying-over-water-aFmpIn4OJ_s',
          wikiLink:
            'https://en.wikipedia.org/wiki/Saint_Thomas,_U.S._Virgin_Islands',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'United States Virgin Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-boat-on-sea-during-daytime-iBjow4JiQpU',
          wikiLink:
            'https://en.wikipedia.org/wiki/United_States_Virgin_Islands',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'United States Virgin Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-gray-rock-formation-on-sea-shore-during-daytime-k4dds3yHxm4',
          wikiLink:
            'https://en.wikipedia.org/wiki/United_States_Virgin_Islands',
        },
      },
    ],
  },
  {
    name: 'Uruguay',
    code: 'UY',
    lat: -33,
    long: -56,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Y_J9VezlMPc',
          wikiLink: 'https://en.wikipedia.org/wiki/La_Mano_de_Punta_del_Este',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/palacio-salvo-montevideo-uruguay-2830233/',
          wikiLink: 'https://en.wikipedia.org/wiki/Uruguay',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Uruguay',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-lighthouse-on-gray-sand-during-sunset-8xWgYDhAGrQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Uruguay',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Uruguay',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-under-blue-sky-during-daytime-YqUW7fT5NwM',
          wikiLink: 'https://en.wikipedia.org/wiki/Uruguay',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Uruguay',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-bunch-of-boats-that-are-in-the-water-LjEuThIORwU',
          wikiLink: 'https://en.wikipedia.org/wiki/Uruguay',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Uruguay',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-brown-and-white-horse-standing-on-top-of-a-lush-green-field-cc58bUVWxWI',
          wikiLink: 'https://en.wikipedia.org/wiki/Uruguay',
        },
      },
    ],
  },
  {
    name: 'Uzbekistan',
    code: 'UZ',
    lat: 41,
    long: 64,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/x-xwFxX2wVU',
          wikiLink: 'https://en.wikipedia.org/wiki/Samarkand',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bibi-xanom-mosque-samarkand-196944/',
          wikiLink: 'https://en.wikipedia.org/wiki/Uzbekistan',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Uzbekistan',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/brown-house-2O8sAwInqT8',
          wikiLink: 'https://en.wikipedia.org/wiki/Uzbekistan',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Uzbekistan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-field-under-white-sky-during-daytime-6HJ0-lKtpsc',
          wikiLink: 'https://en.wikipedia.org/wiki/Uzbekistan',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Uzbekistan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-dirt-road-surrounded-by-trees-under-a-cloudy-sky-q7A2qWWKAUw',
          wikiLink: 'https://en.wikipedia.org/wiki/Uzbekistan',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Uzbekistan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-under-blue-sky-during-daytime-VYBSxYpCUOc',
          wikiLink: 'https://en.wikipedia.org/wiki/Uzbekistan',
        },
      },
    ],
  },
  {
    name: 'Vanuatu',
    code: 'VU',
    lat: -16,
    long: 167,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/JxL6t8iVri4',
          wikiLink: 'https://en.wikipedia.org/wiki/Vanuatu',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/vanuatu-harbor-bay-water-shoreline-80771/',
          wikiLink: 'https://en.wikipedia.org/wiki/Vanuatu',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Vanuatu',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-green-trees-and-body-of-water-during-daytime-JxL6t8iVri4',
          wikiLink: 'https://en.wikipedia.org/wiki/Vanuatu',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Vanuatu',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-and-trees-S_r6KV1xihE',
          wikiLink: 'https://en.wikipedia.org/wiki/Vanuatu',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Vanuatu',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/body-of-water-YkMC1mqegTw',
          wikiLink: 'https://en.wikipedia.org/wiki/Vanuatu',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Vanuatu',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-concrete-building-near-green-trees-during-daytime--SYNBazCTds',
          wikiLink: 'https://en.wikipedia.org/wiki/Vanuatu',
        },
      },
    ],
  },
  {
    name: 'Vatican City',
    code: 'VA',
    lat: 41.9,
    long: 12.45,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/mJDuO7CzO74',
          wikiLink: 'https://en.wikipedia.org/wiki/St._Peter%27s_Basilica',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/vatican-rome-st-peters-basilica-594612/',
          wikiLink: 'https://en.wikipedia.org/wiki/Vatican_City',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Vatican City',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-concrete-building-under-white-clouds-during-daytime-tG8CuF9KG5I',
          wikiLink: 'https://en.wikipedia.org/wiki/Vatican_City',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Vatican City',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-city-0Bs3et8FYyg',
          wikiLink: 'https://en.wikipedia.org/wiki/Vatican_City',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Vatican City',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/low-angle-photography-of-buildings-during-daytime-UAdVpeCpdAg',
          wikiLink: 'https://en.wikipedia.org/wiki/Vatican_City',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Vatican City',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-street-with-a-domed-building-in-the-background-tyyvHmF68rc',
          wikiLink: 'https://en.wikipedia.org/wiki/Vatican_City',
        },
      },
    ],
  },
  {
    name: 'Venezuela',
    code: 'VE',
    lat: 8,
    long: -66,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/ZZeuF8ZLi3o',
          wikiLink: 'https://en.wikipedia.org/wiki/Venezuela',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/caracas-venezuela-sunset-clouds-4539664/',
          wikiLink: 'https://en.wikipedia.org/wiki/Caracas',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Las Mercedes',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photograph-of-city-near-mountain-F4LHE4PBWEA',
          wikiLink: 'https://en.wikipedia.org/wiki/Las_Mercedes,_Caracas',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'El Consejo',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/coconut-trees-bn873zF3ekc',
          wikiLink: 'https://en.wikipedia.org/wiki/El_Consejo',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Venezuela',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/venezuela-cross-port-dairy-991906/',
          wikiLink: 'https://en.wikipedia.org/wiki/Venezuela',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Venezuela',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-boat-in-the-water-with-palm-trees-in-the-background-8wEun5h7ycc',
          wikiLink: 'https://en.wikipedia.org/wiki/Venezuela',
        },
      },
    ],
  },
  {
    name: 'Vietnam',
    code: 'VN',
    lat: 16.16666666,
    long: 107.8333333,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/vcu-OZBxxRk',
          wikiLink: 'https://en.wikipedia.org/wiki/H%E1%BA%A1_Long_Bay',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/view-landscape-nature-vietnam-2843338/',
          wikiLink: 'https://en.wikipedia.org/wiki/Vietnam',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Hạ Long',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/high-angle-photography-of-boats-on-water-near-hill-during-daytime-vcu-OZBxxRk',
          wikiLink: 'https://en.wikipedia.org/wiki/H%E1%BA%A1_Long',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Ninh Bình',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-house-between-two-cliffs-8MhejqEghLk',
          wikiLink: 'https://en.wikipedia.org/wiki/Ninh_B%C3%ACnh',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Ninh Bình',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-photography-of-brown-pagoda-temple-during-daytime-JF4o7WErDn8',
          wikiLink: 'https://en.wikipedia.org/wiki/Ninh_B%C3%ACnh',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Nguyễn Huệ Boulevard',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/high-rise-building-near-body-of-water-during-daytime-soYzQJvctKI',
          wikiLink:
            'https://en.wikipedia.org/wiki/Nguy%E1%BB%85n_Hu%E1%BB%87_Boulevard',
        },
      },
    ],
  },
  {
    name: 'Wallis and Futuna',
    code: 'WF',
    lat: -13.3,
    long: -176.2,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Mata-Utu_seen_from_a_drone_%28Wallis_and_Futuna%29.png',
          wikiLink: 'https://en.wikipedia.org/wiki/Mata_Utu',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://pxhere.com/en/photo/1642780',
          wikiLink: 'https://en.wikipedia.org/wiki/Wallis_and_Futuna',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Wallis and Futuna',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/frf123/27192910604',
          wikiLink: 'https://en.wikipedia.org/wiki/Wallis_and_Futuna',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Wallis and Futuna',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/frf123/27704836162/in/pool-wallis_and_futuna/',
          wikiLink: 'https://en.wikipedia.org/wiki/Wallis_and_Futuna',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Wallis and Futuna',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/neumuller/34055199544/',
          wikiLink: 'https://en.wikipedia.org/wiki/Wallis_and_Futuna',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Wallis and Futuna',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/klm_pics/8233178829/',
          wikiLink: 'https://en.wikipedia.org/wiki/Wallis_and_Futuna',
        },
      },
    ],
  },
  {
    name: 'Western Sahara',
    code: 'EH',
    lat: 24.5,
    long: -13,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Western_sahara_landscape.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Western_Sahara',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://www.jns.org/report-israel-us-consider-deal-to-recognize-moroccan-sovereignty-over-western-sahara/',
          wikiLink: 'https://en.wikipedia.org/wiki/Western_Sahara',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Western Sahara',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/un_photo/4796660796',
          wikiLink: 'https://en.wikipedia.org/wiki/Western_Sahara',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Western Sahara',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/un_photo/5309455601',
          wikiLink: 'https://en.wikipedia.org/wiki/Western_Sahara',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Western Sahara',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/motorcycle-in-the-middle-of-desert-sberykLLUmw',
          wikiLink: 'https://en.wikipedia.org/wiki/Western_Sahara',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Western Sahara',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/top-view-of-white-boat-docked-on-shore-Cn-EpLcknlY',
          wikiLink: 'https://en.wikipedia.org/wiki/Western_Sahara',
        },
      },
    ],
  },
  {
    name: 'Yemen',
    code: 'YE',
    lat: 15,
    long: 48,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/REwZEYzw19g',
          wikiLink: 'https://en.wikipedia.org/wiki/Dracaena_cinnabari',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/yemen-middle-east-neighborhood-6037293/',
          wikiLink: 'https://en.wikipedia.org/wiki/Yemen',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Yemen',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-building-under-blue-sky-during-daytime-YY9_yyBD-UU',
          wikiLink: 'https://en.wikipedia.org/wiki/Yemen',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Socotra',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-trees-in-the-middle-of-a-forest-nQvFebPtqbw',
          wikiLink: 'https://en.wikipedia.org/wiki/Socotra',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Yemen',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/the-sun-is-setting-over-the-sand-dunes-f9rQRvPkWds',
          wikiLink: 'https://en.wikipedia.org/wiki/Yemen',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Yemen',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-canyon-with-a-few-buildings-NSEluxZW_k88',
          wikiLink: 'https://en.wikipedia.org/wiki/Yemen',
        },
      },
    ],
  },
  {
    name: 'Zambia',
    code: 'ZM',
    lat: -15,
    long: 30,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/zambia-sky-clouds-mountains-lake-94526/',
          wikiLink: 'https://en.wikipedia.org/wiki/Zambia',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/rainbow-waterfall-water-landscape-3320571/',
          wikiLink: 'https://en.wikipedia.org/wiki/Victoria_Falls',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Zambia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/silhouette-of-trees-near-body-of-water-62CtkV44DuY',
          wikiLink: 'https://en.wikipedia.org/wiki/Zambia',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Zambia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-walking-on-a-train-track-3RsU_reOgO0',
          wikiLink: 'https://en.wikipedia.org/wiki/Zambia',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Zambia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-riding-on-motorcycle-between-trees-CvOLyQRE5vE',
          wikiLink: 'https://en.wikipedia.org/wiki/Zambia',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Zambia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-body-of-water-with-trees-around-it-and-a-sunset-4N15WgbmA9I',
          wikiLink: 'https://en.wikipedia.org/wiki/Zambia',
        },
      },
    ],
  },
  {
    name: 'Zimbabwe',
    code: 'ZW',
    lat: -20,
    long: 30,
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/JCFpOA5gnRA',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Kariba',
        },
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/pond-field-mountains-trees-meadow-6075837/',
          wikiLink: 'https://en.wikipedia.org/wiki/Zimbabwe',
        },
      },
      {
        number: 3,
        mainImage: {
          name: 'Victoria Falls',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/water-falls-on-brown-rocky-mountain-during-daytime-xsypL99HP3Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Victoria_Falls',
        },
      },
      {
        number: 4,
        mainImage: {
          name: 'Eastern Highlands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-scenery-hLXnoE4TLSI',
          wikiLink: 'https://en.wikipedia.org/wiki/Eastern_Highlands',
        },
      },
      {
        number: 5,
        mainImage: {
          name: 'Victoria Falls',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-body-of-water-surrounded-by-trees-and-clouds-hSdX08KTwk8',
          wikiLink: 'https://en.wikipedia.org/wiki/Victoria_Falls',
        },
      },
      {
        number: 6,
        mainImage: {
          name: 'Zimbabwe',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-grassy-field-with-a-mountain-in-the-background-LozNVrK-dJU',
          wikiLink: 'https://en.wikipedia.org/wiki/Zimbabwe',
        },
      },
    ],
  },
];