import { Component, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import { BehaviorSubject } from 'rxjs';
import { countries } from 'src/app/data/countries';
import { randomPerms } from 'src/app/data/perms';
import { Country } from 'src/app/interfaces/interfaces';
import { DialogsService } from 'src/app/services/dialogs.service';

dayjs.extend(customParseFormat);

interface Game {
  date: string;
  state: string;
  code: string;
  gameNumber: number;
  stateGame: number;
  photoCode: number;
  mainImage: string;
  past: boolean;
  mainImageLink: string;
  mainWikiLink: string;
}

@Component({
  selector: 'app-admin-area',
  templateUrl: './admin-area.component.html',
  styleUrls: ['./admin-area.component.scss'],
})
export class AdminAreaComponent implements OnInit {
  pages = [true, false, false, false];
  games: Game[] = [];
  perms = randomPerms;
  states = countries;
  showUpcoming: boolean = true;
  page$: BehaviorSubject<string> = new BehaviorSubject('upcoming');
  gameByState$: BehaviorSubject<Country> = new BehaviorSubject(countries[0]);
  stateCode: string = 'ak';
  statePlusCode: string = '';
  capital: string = '';

  errors: string[] = [];
  mainCheck: boolean = false;
  dateCheck: boolean = false;

  constructor(private dialog: DialogsService) {}

  enlargeImage(val: string) {
    this.dialog.updateImage(val);
    this.dialog.openEnlargeImage();
  }

  togglePage(page: number) {
    for (let i = 0; i < this.pages.length; i++) {
      this.pages[i] = false;
    }
    this.pages[page] = true;

    switch (page) {
      case 0: {
        this.populateGames(true);
        break;
      }
      case 1: {
        this.populateGames(false);
        break;
      }
      case 4: {
        this.games = [];
        this.runChecks();
      }
    }
  }

  runChecks() {
    const states = countries;

    // Check if each game in countries.ts has a main image with a name, imageLink and wikiLink
    this.mainCheck = true;
    states.forEach((state) => {
      let found = false;
      this.perms.forEach((perm) => {
        if (perm.country === state.name) {
          found = true;
        }
      });

      state.game.forEach((game, i) => {
        let found = false;
        this.perms.forEach((perm) => {
          if (perm.photoCode === game.number && perm.country === state.name) {
            found = true;
          }
        });

        if (found) {
          if (game.mainImage.imageLink === '') {
            this.errors.push(
              `Missing mainImage image link for ${state.name} (Game ${i + 1})`
            );
            this.mainCheck = false;
          }
          if (game.mainImage.wikiLink === '') {
            this.errors.push(
              `Missing mainImage wikipedia link for ${state.name} (Game ${
                i + 1
              })`
            );
            this.mainCheck = false;
          }
        }
      });
    });

    // run date check
    this.dateCheck = true;
    this.perms.forEach((perm, index) => {
      if (index === 0) return;
      // check that this perms date is one day after the perm before it
      const lastPermDate = dayjs(this.perms[index - 1].date, 'D/M/YYYY');
      const lastPermDatePlusOne = lastPermDate.add(1, 'day').format('D/M/YYYY');

      if (perm.date !== lastPermDatePlusOne) {
        this.dateCheck = false;
        this.errors.push(
          `Perm ${perm.date} is not one day after the perm before it`
        );
      }

      return;
    });
  }

  hasDuplicates(array: string[]) {
    return new Set(array).size !== array.length;
  }

  ngOnInit(): void {
    this.populateGames(true);
  }

  populateGames(upcoming: boolean) {
    this.games = [];

    const date = new Date();
    const dateString = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;
    let start = false;
    if (!upcoming) {
      start = true;
    }
    let count = 0;
    this.perms.forEach((perm) => {
      const photoCode = perm.photoCode;
      if (start) {
        const state = this.states.find((v) => v.name === perm.country);
        if (state) {
          this.games.push({
            date: perm.date,
            gameNumber: perm.number,
            stateGame: 0,
            photoCode: photoCode,
            code: state.code.toLowerCase(),
            state: state.name,
            mainImage: `./assets/countries/${state.code.toLowerCase()}/main${photoCode}.webp`,
            past: false,
            mainImageLink: state.game[photoCode - 1].mainImage.imageLink,
            mainWikiLink: state.game[photoCode - 1].mainImage.wikiLink,
          });
        }
        count++;
      }
      if (perm.date === dateString) {
        start = true;
      }
      if (!upcoming && perm.date === dateString) {
        start = false;
      }
    });
    if (!upcoming) {
      this.games = this.games.reverse();
    }
  }

  copyToClipboard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value =
      document.head.baseURI.substring(0, document.head.baseURI.length - 1) +
      val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
