import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { ImageComponent } from './components/image/image.component';
import { GuessesComponent } from './components/guesses/guesses.component';
import { GuessComponent } from './components/guesses/guess/guess.component';
import { InputComponent } from './components/input/input.component';
import { CountUpModule } from 'ngx-countup';
import { AlertComponent } from './components/alert/alert.component';
import { GameComponent } from './pages/game/game.component';
import { TextLimitPipe } from './text-limit.pipe';
import { FooterComponent } from './components/footer/footer.component';
import { ShareButtonsComponent } from './components/share-buttons/share-buttons.component';

import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { StatsComponent } from './pages/stats/stats.component';
import { ProgressComponent } from './components/progress/progress.component';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { MapComponent } from './pages/map/map.component';
import { SvgMapComponent } from './components/svg-map/svg-map.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SettingsComponent } from './pages/settings/settings.component';
import { RulesComponent } from './pages/rules/rules.component';
import { LandmarksComponent } from './pages/landmarks/landmarks.component';
import { CityComponent } from './pages/city/city.component';
import { CityGuessesComponent } from './components/city-guesses/city-guesses.component';
import { LandmarkImageComponent } from './dialogs/landmark-image/landmark-image.component';
import { EndComponent } from './dialogs/end/end.component';
import { ChangeStatsComponent } from './pages/change-stats/change-stats.component';
import { ChangeComponent } from './pages/change-stats/change/change.component';
import { LandmarkGuessComponent } from './components/landmark-guess/landmark-guess.component';
import { TopBannerAdComponent } from './components/top-banner-ad/top-banner-ad.component';
import { EndMobileAdComponent } from './components/end-mobile-ad/end-mobile-ad.component';
import { WelcomeComponent } from './dialogs/welcome/welcome.component';
import { AdminComponent } from './pages/admin/admin.component';
import { AdminAreaComponent } from './pages/admin-area/admin-area.component';
import { EnlargeImageComponent } from './dialogs/enlarge-image/enlarge-image.component';
import { CapitalCityGuessesComponent } from './components/capital-city-guesses/capital-city-guesses.component';
import { CityGuessComponent } from './components/capital-city-guesses/city-guess/city-guess.component';
import { CityInputComponent } from './components/city-input/city-input.component';
import { AboutComponent } from './pages/about/about.component';
import { WeLoggedYouOutComponent } from './dialogs/we-logged-you-out/we-logged-you-out.component';
import { CookieService } from './services/cookies.service';
import { ErrorComponent } from './dialogs/error/error.component';
import { WheretakenNewComponent } from './dialogs/wheretaken-new/wheretaken-new.component';
import { AccountComponent } from './pages/account/account.component';
import { ArchiveComponent } from './pages/archive/archive.component';
import { BecomePremiumMemberComponent } from './components/become-premium-member/become-premium-member.component';
import { RemoveAdsComponent } from './pages/remove-ads/remove-ads.component';
import { ReplayComponent } from './pages/replay/replay.component';
import { WorldClassicComponent } from './components/world-classic/world-classic.component';
import { TeuteufGamesComponent } from './pages/teuteuf-games/teuteuf-games.component';
import { IFrameWarningComponent } from './dialogs/iframe-warning/iframe-warning.component';
import { HolidayBannerComponent } from './components/holiday-banner/holiday-banner.component';
import { CDNUpdateComponent } from './dialogs/cdn-update/cdn-update.component';

@NgModule({
  declarations: [
    AppComponent,
    WorldClassicComponent,
    HeaderComponent,
    ImageComponent,
    GuessesComponent,
    GuessComponent,
    InputComponent,
    AlertComponent,
    GameComponent,
    TextLimitPipe,
    FooterComponent,
    StatsComponent,
    ShareButtonsComponent,
    ProgressComponent,
    MapComponent,
    SvgMapComponent,
    SafeHtmlPipe,
    SettingsComponent,
    RulesComponent,
    LandmarksComponent,
    CityComponent,
    CityGuessesComponent,
    LandmarkImageComponent,
    EndComponent,
    ChangeStatsComponent,
    ChangeComponent,
    LandmarkGuessComponent,
    TopBannerAdComponent,
    EndMobileAdComponent,
    WelcomeComponent,
    AdminComponent,
    AdminAreaComponent,
    EnlargeImageComponent,
    CapitalCityGuessesComponent,
    CityGuessComponent,
    CityInputComponent,
    AboutComponent,
    WeLoggedYouOutComponent,
    ErrorComponent,
    WheretakenNewComponent,
    AccountComponent,
    ArchiveComponent,
    BecomePremiumMemberComponent,
    RemoveAdsComponent,
    ReplayComponent,
    TeuteufGamesComponent,
    IFrameWarningComponent,
    HolidayBannerComponent,
    CDNUpdateComponent,
  ],
  imports: [
    CountUpModule,
    MatDialogModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    NgOptimizedImage,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
  ],
  providers: [CookieService],
  bootstrap: [AppComponent],
})
export class AppModule {}
