import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'cdn-update-dialog',
  templateUrl: './cdn-update.component.html',
  styleUrls: ['./cdn-update.component.scss'],
})
export class CDNUpdateComponent {
  constructor(
    public dialogRef: MatDialogRef<CDNUpdateComponent>,
    private data: DataService
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }

  refreshPage() {
    this.data.updateToLatestCDNVersion();
  }
}
