import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import * as seedrandom from 'seedrandom';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  constructor() {}

  isSameUtcDay(refreshTimeString: string): boolean {
    // Parse the refreshTime as a Date object
    const refreshDate = new Date(refreshTimeString);

    // Get the current date in UTC
    const currentDate = new Date();

    // Compare the year, month, and day
    return (
      refreshDate.getUTCFullYear() === currentDate.getUTCFullYear() &&
      refreshDate.getUTCMonth() === currentDate.getUTCMonth() &&
      refreshDate.getUTCDate() === currentDate.getUTCDate()
    );
  }

  // Function that takes in a photoURL and checks whether it is a valid photo
  checkPhotoValidity(photoURL: string): Promise<boolean> {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = photoURL;
      image.onload = () => resolve(true);
      image.onerror = () => resolve(false);
    });
  }

  isSameDay(dateString1: string, dateString2: string): boolean {
    const date1 = new Date(dateString1);
    const date2 = new Date(dateString2);

    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  getFirstWhereTakenGameDate(): string {
    return '2023-02-27';
  }

  getGameNumber(date?: string): number {
    return Number(
      dayjs(date).diff(dayjs(this.getFirstWhereTakenGameDate()), 'day') + 1
    );
  }

  shuffleWithSeed<T>(array: T[], seed: string) {
    const rng = seedrandom(seed);
    const shuffled = [];
    const keys = Array(array.length)
      .fill(null)
      .map((_, key) => key);

    for (let i = 0; i < array.length; i++) {
      const key = Math.floor(rng() * keys.length);

      shuffled.push(array[keys[key]]);
      keys.splice(key, 1);
    }

    return shuffled;
  }

  /**
   * Loads image from `url` and returns true if successful
   */
  testImageLoad = (url: string): Promise<boolean> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  };
}
