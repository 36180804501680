import { Injectable } from '@angular/core';

import { Observable, take } from 'rxjs';
import { UserDetails } from '../interfaces/UserDetails';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { ArchiveGame } from '../interfaces/ArchiveGame';
import { ImageService } from './image.service';
import { UtilitiesService } from './utilities.service';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class ArchiveService {
  user$: Observable<UserDetails>;

  constructor(
    private router: Router,
    private user: UserService,
    private image: ImageService,
    private utilities: UtilitiesService
  ) {
    this.user$ = this.user.getUserDetails();
  }

  getArchiveGames() {
    let archiveGames: ArchiveGame[] = [];

    let date = dayjs(this.utilities.getFirstWhereTakenGameDate());

    const dates: string[] = [];
    while (date.isBefore(dayjs(), 'day')) {
      dates.push(date.format('YYYY-MM-DD'));
      date = date.add(1, 'day');
    }

    dates.forEach((date) => {
      archiveGames.push({
        number: this.utilities.getGameNumber(date),
        date: date,
      });
    });
    archiveGames.reverse();
    return archiveGames;
  }

  replayTodaysGame() {
    this.user$.pipe(take(1)).subscribe((user: UserDetails) => {
      if (user?.subscription?.isPremium) {
        // This wasn't working previously, can be fixed at a later time
        this.router.navigate(['/'], { queryParams: { game: 'replay' } });
      } else {
        this.router.navigate(['/replay']);
      }
    });
  }
}
