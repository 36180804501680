<div *ngIf="user$ | async as user" class="mt-3 text-black dark:!text-white relative flex flex-col gap-3">
  <a routerLink="/" class="text-blue-500 hover:text-blue-400">&#8592; Back</a>

  <h2 class="text-xl font-bold">Archive</h2>
  <div *ngIf="!user?.subscription?.isPremium">
    <p class="mb-3">Access to the game archive is an exclusive feature for Premium Members. Register now through the link below and unlock the ability to play all the games that have ever existed!</p>
    <app-become-premium-member></app-become-premium-member>
  </div>
    <p class="mb-3" *ngIf="user?.subscription?.isPremium">Here are all the games since 26th February 2023. Click on a game
      to play that day's game.</p>
    <div (click)="playRandomGame()"
      class="text-center border border-black p-2 rounded-md shadow-sm hover:bg-slate-200 cursor-pointer mb-2"
      [ngClass]="{'cursor-not-allowed': !user?.subscription?.isPremium}"
      >Play a random game</div>
    <div class="grid grid-cols-2 gap-2">
      <div (click)="goToArchiveGame(game.date)"
        *ngFor="let game of archiveGames"
        class="text-center border border-black rounded-md shadow-sm hover:bg-slate-200 cursor-pointer"
        [ngClass]="{'cursor-not-allowed': !user?.subscription?.isPremium}"
        >
        <div class="py-1">
          <a class="font-bold">Game {{ game.number }}</a>
        </div>
      </div>
    </div>

</div>
