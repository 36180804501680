<mat-dialog-content>
  <div class="text-black dark:!text-white">
    <div class="absolute right-4 top-4">
      <button (click)="closeDialog()" mat-button class="font-bold rounded">
        &#x2715;
      </button>
    </div>
    <p class="font-bold text-lg">Update available</p>
    <p class="my-3 text-sm">A new content update is available!</p>
  </div>
  <div class="w-full flex justify-center items-center mt-2">
    <button (click)="refreshPage()" mat-button
      class="bg-blue-600 hover:bg-blue-400 text-white font-bold  py-2 px-4 rounded">
      Refresh
    </button>
  </div>
</mat-dialog-content>