<div class="relative">
  <div class="mt-3 text-black dark:!text-white">
    <a routerLink="/admin" class="text-blue-500 hover:text-blue-400">&#8592; Back</a>
    <h1 class="mt-3 text-2xl font-bold">Admin portal</h1>
    <div class="flex gap-2 mt-2">
      <a (click)="togglePage(0)" class="text-xs text-blue-600 dark:text-blue-300 cursor-pointer">Upcoming games</a>
      <span class="text-xs">|</span>
      <a (click)="togglePage(1)" class="text-xs text-blue-600 dark:text-blue-300 cursor-pointer">Past games</a>
      <span class="text-xs">|</span>
      <a (click)="togglePage(4)" class="text-xs text-blue-600 dark:text-blue-300 cursor-pointer">Checks</a>
    </div>
    <div>
      <div *ngIf="pages[0]">
        <h2 class="text-xl font-bold mt-4 mb-2">Upcoming games</h2>
        <p class="mb-3 text-xs">Click on images to enlarge.</p>
      </div>
      <div *ngIf="pages[1]">
        <h2 class="text-xl font-bold mt-4 mb-2">Past games</h2>
        <p class="mb-3 text-xs">Click on images to enlarge.</p>
      </div>
      <div *ngIf="!pages[3]" class="flex flex-col justify-start">
        <div *ngIf="pages[2]">
          <h2 class="text-xl font-bold mt-4 mb-2">{{ (gameByState$ | async)?.name }}</h2>
        </div>
        <div *ngFor="let game of games" class="w-full mb-12 bg-slate-200 dark:bg-slate-500 p-3 rounded-md shadow-md"
          [ngClass]="{'bg-red-200' : game.past}">
          <h3 *ngIf="game.past" class="text-sm mb-2">This game has already been played</h3>
          <h3 *ngIf="showUpcoming" class="font-bold text-lg">Game {{ game.gameNumber }}: {{game.state}} ({{
            game.code.toUpperCase() }}) - {{ game.date }} - {{ game.photoCode }}</h3>
          <h3 *ngIf="!showUpcoming" class="font-bold text-lg mb-2">{{ game.stateGame }}: {{ game.state }} - {{ game.date
            }} - Game: {{game.gameNumber}}</h3>
          <div class="grid grid-cols-2 gap-2">
            <div>
              <h4 class="font-bold text-xs mb-1">Main round</h4>
              <img class="cursor-pointer" (click)="enlargeImage(game.mainImage)" [ngSrc]="game.mainImage" width="992"
                height="720">
            </div>
          </div>
          <p class="font-bold text-sm mt-3 mb-1">Links</p>
          <div class="flex flex-wrap gap-1 text-blue-600">
            <a class="text-sm" [href]="game.mainImageLink" target="_blank">Main image |</a>
            <a class="text-sm" [href]="game.mainWikiLink" target="_blank">Main wikipedia</a>
            </div>
        </div>
      </div>
    </div>
    <div *ngIf="pages[4]">
      <h2 class="text-xl font-bold mt-4 mb-2">Data Checks</h2>
      <p class="mb-3 text-xs">Running checks on the data to make sure everything is there.</p>

      <h4 class="text-lg font-bold mt-4 mb-2">Test 1</h4>
      <p class="mb-3 text-xs">Check if each state has a main name, image link and wikipedia link</p>
      <p class="mb-3 text-xs">Status <span *ngIf="mainCheck">✅</span><span *ngIf="!mainCheck">❌</span></p>

      <h4 class="text-lg font-bold mt-4 mb-2">Test 3</h4>
      <p class="mb-3 text-xs">Checking perm dates. Checking if all perms dates are in consectutive order with no missing dates
      </p>
      <p class="mb-3 text-xs">Status <span *ngIf="dateCheck">✅</span><span *ngIf="!dateCheck">❌</span></p>

      <h4 class="text-lg font-bold mt-4 mb-2">Errors</h4>
      <div *ngFor="let error of errors">
        <p class="mb-3 text-xs">{{error}}</p>
      </div>

    </div>
  </div>
</div>
